import React, { Fragment, useEffect, useState } from "react";
import { Divider, Menu, MenuItem } from "@material-ui/core";
import { ThemeProvider } from "@cr/material-ui-theme-provider";

interface MenuOption {
  title: string;
  dividerTop?: boolean;
  dividerBottom?: boolean;
  action: () => any;
  hide?: boolean;
}

const MuiMenu = ({ options, getAnchor }: { options: MenuOption[]; getAnchor: () => HTMLElement }) => {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const open = Boolean(anchor);

  useEffect(() => {
    const onAnchorClick = () => setAnchor(getAnchor());

    getAnchor().addEventListener("click", onAnchorClick);

    return () => {
      getAnchor().removeEventListener("click", onAnchorClick);
    };
  }, [getAnchor]);

  const handleClose = () => setAnchor(null);

  const withClose = action => () => {
    try {
      action();
    } catch (e) {}

    handleClose();
  };

  return (
    <ThemeProvider skipGlobalStyles>
      <Menu anchorEl={anchor} open={open} onClose={handleClose} disablePortal={true} keepMounted={false} disableScrollLock={true}>
        {options
          .filter(option => !option.hide)
          .map((option, index) => (
            <div key={index}>
              {option.dividerTop && <Divider />}
              <MenuItem onClick={withClose(option.action)}>{option.title}</MenuItem>
              {option.dividerBottom && <Divider />}
            </div>
          ))}
      </Menu>
    </ThemeProvider>
  );
};

export const knockoutName = "cr-mui-menu";

export default MuiMenu;
