import { BroadcastChannel, createLeaderElection, LeaderElector } from "broadcast-channel";
import { UserManager } from "oidc-client";
import { Logger } from "@cr/core/logger";

const log = Logger.create("RefreshTokenService");
const stepMS = 250;
const timeoutRemoteMS = 2000;
const tabId = new Date().getTime();

const withExponentialBackoff = <T>(fn: () => Promise<T>, maxRetries: number, delayMs: number) =>
  new Promise<T>((resolve, reject) => {
    log.debug("withExponentialBackoff called.");

    const tryOnce = () => {
      fn()
        .then(resolve)
        .catch(error => {
          if (--maxRetries > 0) {
            log.debug(`withExponentialBackoff attempts left ${maxRetries}: pausing ${delayMs}ms...`);
            setTimeout(tryOnce, delayMs);
            delayMs *= 2;
          } else {
            reject(error);
          }
        });
    };

    tryOnce();
  });

const channelName = "cr-refresh-token-service";

interface RenewRequest {
  type: "renew-request";
  requestId: string;
}

interface RenewResponse {
  type: "renew-response";
  requestId: string;
  success: boolean;
}

interface UserReloadRequest {
  type: "user-reload-request";
}

interface RefreshTokenServiceOptions {
  numRetries: number;
  pingInterval: number;
  expirationThreshold: number;
  userManager: UserManager;
  initialCheck: boolean;
}

class RefreshTokenService {
  private _userManager: UserManager = null;
  private _numRetries: number = 0;
  private _pingInterval: number = 0;
  private _expirationThreshold: number = 0;
  private _channel: BroadcastChannel = null;
  private _elector: LeaderElector = null;
  private _started: boolean = false;
  private _intervalId: number = 0;
  private _renewUserPromise: Promise<void> | null = null;

  constructor({ userManager, numRetries, pingInterval, expirationThreshold, initialCheck }: RefreshTokenServiceOptions) {
    this._userManager = userManager;
    this._numRetries = numRetries;
    this._pingInterval = +window.localStorage.getItem("RefreshTokenService.PingInterval") || pingInterval;
    this._expirationThreshold = +window.localStorage.getItem("RefreshTokenService.ExpirationThreshold") || expirationThreshold;

    // handle sleeping tabs - refresh when the tab becomes active again
    document.addEventListener("visibilitychange", async () => {
      if (this._started && !document.hidden) {
        log.debug("Processing renew request from visibility change.");
        await this.renewUserIfExpired();
      }
    });

    // listen for other tabs requesting a new access token
    const onRenewRequest = async (data: RenewRequest) => {
      if (this.isPrimaryTab && data.type === "renew-request") {
        log.debug("Processing renew request from remote tab.");
        let success = false;

        try {
          await this.renewUserIfExpired();
          success = true;
        } catch (e) {}

        const response: RenewResponse = { type: "renew-response", requestId: data.requestId, success };

        this._channel.postMessage(response);
      }
    };

    // listen for the primary tab successfully renewing the token - we won't get the user loaded event automatically
    const onUserReloadRequest = async (data: UserReloadRequest) => {
      if (!this.isPrimaryTab && data.type === "user-reload-request") {
        log.debug("Reloading user on request from remote tab.");
        const user = await this._userManager.getUser();
        await this._userManager.events.load(user);
      }
    };

    // add all relevant listeners for channel and elector
    const createChannelAndElector = () => {
      this._channel = new BroadcastChannel(channelName, {
        idb: {
          onclose: () => {
            // see https://github.com/pubkey/broadcast-channel#readme
            // the onclose event is just the IndexedDB closing.
            // you should also close the channel before creating
            // a new one.
            this._channel.close();
            createChannelAndElector();
          }
        }
      });

      this._channel.addEventListener("message", onRenewRequest);
      this._channel.addEventListener("message", onUserReloadRequest);
      this._elector = createLeaderElection(this._channel);

      // election takes a second or so - if we're the leader then immediately check, otherwise we'd have to wait for the timer to elapse
      this._elector.awaitLeadership().then(async () => {
        if (this._started && initialCheck) {
          log.debug("Processing initial renewal request as tab leader.");
          await this.renewUserIfExpired();
        }
      });
    };

    createChannelAndElector();
  }

  // handle renewal in this tab - only allow one at a time
  async renewUserLocal() {
    if (this._renewUserPromise) {
      return this._renewUserPromise;
    }

    try {
      await (this._renewUserPromise = new Promise<void>(async (resolve, reject) => {
        log.debug("renewUserLocal called.");

        let success = false;

        try {
          await this._userManager.signinSilent();
          success = true;
          log.debug("renewUserLocal succeeded with refresh token.");
        } catch (e) {
          log.debug(`renewUserLocal failed with refresh token: ${e}`);
          // if we had a refresh token and renewal failed, clear the user and try with an iframe
          if (/invalid.grant/i.test(`${e}`)) {
            try {
              await this._userManager.removeUser();
              await this._userManager.signinSilent();
              success = true;
              log.debug("renewUserLocal succeeded with iframe.");
            } catch (e) {
              log.debug(`renewUserLocal failed with iframe: ${e}`);
            }
          }
        }

        if (success) {
          resolve();
        } else {
          reject();
        }
      }));
    } finally {
      this._renewUserPromise = null;
    }
  }

  // ask another tab to renew for us
  renewUserRemote() {
    return new Promise<void>((resolve, reject) => {
      log.debug("renewUserRemote called.");
      const requestId = `${tabId}-${new Date().getTime()}`;
      let timeoutId = 0;

      const onRenewResponse = (data: RenewResponse) => {
        if (data.type === "renew-response" && data.requestId === requestId) {
          window.clearTimeout(timeoutId);
          this._channel.removeEventListener("message", onRenewResponse);

          if (data.success) {
            resolve();
            log.debug("renewUserRemote succeeded.");
          } else {
            reject();
            log.debug("renewUserRemote failed.");
          }
        }
      };

      timeoutId = window.setTimeout(() => {
        this._channel.removeEventListener("message", onRenewResponse);
        reject(new Error("renewUserRemote timeout"));
      }, timeoutRemoteMS);

      this._channel.addEventListener("message", onRenewResponse);

      const request: RenewRequest = { type: "renew-request", requestId };

      this._channel.postMessage(request);
    });
  }

  // renewal method agnostic of if we're the tab leader or not
  async renewUserInternal() {
    log.debug("renewUserInternal called.");

    const isLeader = this.isPrimaryTab;
    let remoteTimeout = false;

    if (!isLeader) {
      try {
        await this.renewUserRemote();
      } catch (e) {
        // throw error if this failed for something other than a timeout
        if (!/timeout/i.test(`${e}`)) {
          throw e;
        }

        remoteTimeout = true;
      }
    }

    if (remoteTimeout) {
      log.debug("renewUserRemote timed out, trying renewUserLocal.");
    }

    if (isLeader || remoteTimeout) {
      await this.renewUserLocal();

      // let other tabs know they should reload the user from local storage
      log.debug("renewUser informing other tabs to reload user info.");
      const request: UserReloadRequest = { type: "user-reload-request" };
      this._channel.postMessage(request);
    }
  }

  get isPrimaryTab() {
    return this._elector.isLeader;
  }

  // renewal method wrapped with retry logic
  renewUser() {
    log.debug("renewUser called.");
    return withExponentialBackoff(() => this.renewUserInternal(), this._numRetries, stepMS);
  }

  // check expiration and renew if needed
  async renewUserIfExpired() {
    log.debug("renewUserIfExpired called.");

    const user = await this._userManager.getUser();

    const difference = user ? Math.max(user.expires_in * 1000, 0) : 0;

    if (!user) {
      log.debug("renewUserIfExpired - missing user, attempting renewal anyway");
    } else {
      log.debug(`renewUserIfExpired checking expiration... ${(difference / 1000 / 60).toFixed(2)} minutes(s) left until expiration.`);
    }

    const isExpired = difference <= this._expirationThreshold;

    log.debug(
      `renewUserIfExpired determined token is ${!isExpired ? "not " : ""}expired based on threshold of ${(
        this._expirationThreshold /
        1000 /
        60
      ).toFixed(2)} minute(s).`
    );

    if (isExpired) {
      await this.renewUser();
      return true;
    }

    return false;
  }

  // start checking expiration on elapsed timer, leader election, and visbility change
  start() {
    if (this._started) {
      return;
    }

    log.debug(`refreshTokenService started, checking token expiration every ${Math.floor(this._pingInterval) / 1000} second(s).`);

    this._started = true;
    this._intervalId = window.setInterval(() => this.renewUserIfExpired(), this._pingInterval);
  }

  // stop checking expiration in all events
  stop() {
    if (!this._started) {
      return;
    }

    log.debug("refreshTokenService stopped.");

    this._started = false;
    window.clearInterval(this._intervalId);
    this._intervalId = 0;
  }
}

export default RefreshTokenService;
