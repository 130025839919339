import crHistory from "@cr/core/history";
import { upgradeIfRequired } from "app/util/autoUpgrade";
import domUtil from "app/util/domUtil";
import messageBus from "app/util/messageBus";
import util from "app/util/miscUtil";
import config from "app/util/routerConfig";
import transport from "app/util/transport";
import user from "app/util/user";

const koReactSyncDelay = 1;

function moduleLoading(newValue) {
  const useNewMenu = !user.isUserClient;

  if (newValue === true) {
    toastr.remove();
    toastr.options.positionClass = "toast-top-full-width " + (useNewMenu ? "toast-module-loading-new" : "toast-module-loading");
    toastr.options.progressBar = true;
    toastr.options.closeButton = false;
    toastr.success("");
  } else {
    toastr.clear();
  }
}

function submoduleLoading() {} //nothing for now

let __screenPaneRef;

let currentModule = null,
  currentlyLoadingModule = null,
  body = $("body"),
  router = {
    get currentSubmoduleBusConnection() {
      return (this.currentSubmodule || {})._busConnection;
    },
    get currentModuleChannelId() {
      return (currentModule || {})._channelId;
    },
    //the current submodule's busConnection, or the module, if no submodule
    get currentlyExecutingModuleBusConnection() {
      return (this.currentSubmodule && this.currentSubmodule._busConnection) || (currentModule || {})._busConnection;
    },
    //ibid, but for module's (AMD) id
    get currentlyExecutingModuleId() {
      return (this.currentSubmodule && this.currentSubmodule.requireId) || (currentModule || {}).requireId;
    }
  },
  suppressHashChange; // see setHashWithoutRouting

function routeToMenuItem(menuItem) {
  // pretty simple for now
  window.location.hash = menuItem;
}

function hashChanged() {
  let hash = location.hash,
    go = suppressHashChange !== hash; // strict !== important
  suppressHashChange = undefined;
  if (go && hash.length > 1 /* ensure there's a hash to go to */) {
    loadModuleFromHash(hash);
  }
}

function loadModuleFromHash(hash) {
  let pieces = hash.split("/");
  let module = pieces[0].substr(1, pieces[0].length);

  router.loadModule(module);
}

function loadModuleHtml(channelId, callback) {
  if (config.modules[channelId]) {
    // react routers are not part of this module list
    Promise.all([config.modules[channelId].view(), config.modules[channelId].css()])
      .then(([{ default: html }]) => {
        currentlyLoadingModule = null;

        // We may have lazily-loaded the HTML and the server may have responded with
        // an application upgrade notification in that reply.  If so, go ahead and upgrade now.
        upgradeIfRequired();

        callback(html);
      })
      .catch(ex => {
        currentlyLoadingModule = null;

        // if the application has been deployed in the background, trying to lazy-load the modules
        // will fail and we'll end up here, in which case go ahead and upgrade the application
        upgradeIfRequired();

        console.error(ex);
      });
  }
}

function loadSubmoduleHtml(module, submodule, callback) {
  submodule = submodule.toLowerCase();
  Promise.resolve(config.modules[module].subModules[submodule].view())
    .then(({ default: result }) => {
      // We may have lazily-loaded the HTML and the server may have responded with
      // an application upgrade notification in that reply.  If so, go ahead and upgrade now.
      upgradeIfRequired();

      callback.call(this, result);
    })
    .catch(ex => {
      // if the application has been deployed in the background, trying to lazy-load the modules
      // will fail and we'll end up here, in which case go ahead and upgrade the application
      upgradeIfRequired();

      console.error(ex);
    });
}

function loadModule(channelId) {
  const isPublicPage = crHistory.isPublicPage;
  let canAccessModule = false;

  // make channelId lowercase, should always be but just in case
  channelId = channelId.toLowerCase();

  if (channelId === "auth" || channelId === "logout" || channelId === "account") {
    canAccessModule = true;
  }
  if (channelId === "contacts") {
    const hashManager = new cr.hashUtil.hashManager();
    const newHashState = hashManager.parseHashTag(window.location.hash);
    const parts = location.hash.split("/");
    if (parts[1] === "details" && newHashState.getParameters().id === user.id.toString()) {
      canAccessModule = true;
    }
  }
  
  // special exempt for backwards compatibility, we'll change any
  // request for the 'account' module to use the 'contacts' channel
  if (channelId === "account") {
    channelId = "contacts";
  }

  if (!isPublicPage) {
    // get all channels that the user has access to
    // if we're not viewing a public page
    let userChannels = user.getModulePermissions();

    for (let x = 0; x < userChannels.length; x++) {
      if (userChannels[x] == channelId) {
        canAccessModule = true;
        break;
      }
    }

    if (!canAccessModule) {
      // Do not show this message for session notes module. Session Notes module is a react-router module and it's access is handled by the <ProtectedRoute/> comoponent.
      // This fixes part of the CRCH-4233 bug.
      if (channelId !== "session-notes") {
        cr.domUtil.displayMessage("You are not authorized to access this module", { isError: true });
      }
      return;
    }
  }

  if (smartSwitch(channelId)) {
    return;
  } //done!

  domUtil.activateModule(channelId);

  if (currentlyLoadingModule !== channelId) {
    currentlyLoadingModule = channelId;
    moduleLoading(true);
    setTimeout(clearAndLoadModule, koReactSyncDelay);
  }

  function clearAndLoadModule() {
    if (currentModule != null) {
      clearModule(currentModule);
      uninitializeModule(currentModule);
      currentModule.currentSubModule = null;
      currentModule = null;
    }

    loadModuleHtml(channelId, moduleHtmlLoaded);
  }

  function moduleHtmlLoaded(html) {
    let requireId = channelId + "/" + channelId;
    Promise.resolve(config.modules[channelId].module()).then(({ default: ModFunction }) => {
      if (typeof ModFunction === "object" && typeof ModFunction.default === "function") {
        ModFunction = ModFunction.default;
      }
      $.each(cr.moduleAddons, function (i, func) {
        func(ModFunction);
      });

      let module = new ModFunction(),
        privateData = {};

      module.channelId = channelId;
      privateData.channelId = channelId;

      module.requireId = requireId;
      privateData.requireId = requireId;

      module.manuallyDeactivateCurrentSubmodule = function () {
        if (!this.currentSubModule || !this.currentSubModule.inst) {
          return;
        }
        this.currentSubModule.inst.deactivate();
        this.currentSubModule = undefined;
        this.currentSubModuleName = undefined;
      };

      privateData.busConnection = messageBus.getConnection(requireId);
      router.currentModuleBusConnection = module._busConnection = privateData.busConnection;
      privateData.hashManager = new cr.hashUtil.hashManager();
      privateData.transmitRequest = transport.transmitRequest.bind(transport, channelId);
      privateData.successRequest = transport.successRequest.bind(transport, channelId);
      router.currentModuleStorage = privateData.storage = new util.ModuleStorage(channelId);

      //when the module/submodule path is react-router, the __screenPaneRef component doesn't exist
      if (__screenPaneRef) {
        ko.cleanNode(__screenPaneRef.current);

        // removing any side menus that were there before
        body.removeClass("pml-open").removeClass("pml-open-right");

        // add a dummy css class representing the module
        // to the body class, we can use this to add custom module styles
        body.addClass(channelId);
        // inject the html into the getScreenPane()
        __screenPaneRef.current.innerHTML = html;

        // html is loaded so we can go ahead and fire up the responsive layout manager
        privateData.responsiveManager = new cr.responsiveManager();
        module.responsiveManager = privateData.responsiveManager;

        registerModule(module, privateData);

        // send message the that module has loaded
        // we can then pick up this message, as an example, in the header
        // to ensure that we've closed mobile-friendly navigation and re-enabled the getScreenPane()
        messageBus.sendMessage("router", "module-loaded");
      }
    });
  }
}

function registerModule(module, privateData) {
  currentModule = module;
  domUtil.clearModuleHelpers();
  module.initialize(privateData);
  moduleLoading(false);
}

function switchToSubModule(currentModuleInstance, subModuleName, message) {
  subModuleName = subModuleName.toLowerCase();

  // Initialize the cache of loaded submodules if the module doesn't do it itself
  if (!currentModuleInstance.loadedSubModules) {
    currentModuleInstance.loadedSubModules = {};
  }

  if (currentModuleInstance.currentSubModuleName === subModuleName) {
    currentModuleInstance.currentSubModule.inst.activate(message, true);
    return;
  }

  let channelId = currentModuleInstance.channelId;
  let subFuncName = channelId + "/" + subModuleName + "/" + subModuleName;
  let subModulePath = currentModuleInstance.channelId + "/" + subModuleName + "/";
  let newSubmodule = currentModuleInstance.loadedSubModules[subModuleName];

  submoduleLoading(true);

  // send message the that module has loaded
  // we can then pick up this message, as an example, in the header
  // to ensure that we've closed mobile-friendly navigation and re-enabled the getScreenPane()
  messageBus.sendMessage("router", "module-loaded");

  if (newSubmodule) {
    switchToExistingSubModule(newSubmodule, currentModuleInstance, message);
  } else {
    newSubmodule = { name: subModuleName, path: subModulePath };
    switchToNewSubModule(newSubmodule, subFuncName, currentModuleInstance, message, channelId, subModuleName);
  }
}

function switchToExistingSubModule(newSubModule, currentModuleInstance, message) {
  deactivateSubModule(currentModuleInstance.currentSubModule);
  currentModuleInstance.currentSubModule = newSubModule;
  currentModuleInstance.currentSubModuleName = newSubModule.name;
  router.currentSubmodule = newSubModule.inst;
  newSubModule.inst.activate(message, false);
  submoduleLoading(false);
}

function switchToNewSubModule(newSubmodule, moduleFunctionName, currentModuleInstance, message, module, submodule) {
  deactivateSubModule(currentModuleInstance.currentSubModule);

  submodule = submodule.toLowerCase();

  if (config.modules[module].subModules[submodule]) {
    // react routers are not part of this submodule list
    Promise.resolve(config.modules[module].subModules[submodule].module()).then(({ default: subModuleFunction }) => {
      $.extend(subModuleFunction.prototype, cr.defaultSubmodulePrototype(currentModuleInstance, newSubmodule));
      cr.subModuleAddons.forEach(function (addon) {
        addon(subModuleFunction);
      });

      newSubmodule.inst = new subModuleFunction(subModuleFunction.prototype);
      newSubmodule.inst.requireId = moduleFunctionName;
      newSubmodule.inst.channelId = newSubmodule.name;
      router.currentSubmodule = newSubmodule.inst;
      newSubmodule.inst.initialize(currentModuleInstance, message);

      currentModuleInstance.currentSubModule = newSubmodule;
      currentModuleInstance.currentSubModuleName = newSubmodule.name;
      currentModuleInstance.loadedSubModules[newSubmodule.name] = newSubmodule;

      submoduleLoading(false);
    });
  }
}

function deactivateSubModule(sub) {
  if (sub && sub.inst && typeof sub.inst.deactivate === "function") {
    try {
      sub.inst.deactivate();
    } catch (e) {}
  }
}

function clearModule(moduleToClear) {
  moduleLoading(true); //we'll be loading a new module in a moment - might as well throw up the big spinner while we clear this one

  let name, subModule, modulePath, cssURL;
  router.currentSubmodule = null;

  if (moduleToClear.__registeredAdvSearchOptions) {
    cr.viewModelFactory.plugins.advancedSearch.unRegisterAdvancedSearchOptions(moduleToClear.__registeredAdvSearchOptions);

    let mainSearchOptions = cr.viewModelFactory.plugins.advancedSearch.advancedSearchResultsMap,
      reversions = moduleToClear.__registeredAdvSearchOptionsReversions || {};

    Object.assign(mainSearchOptions, reversions);
  }

  if (moduleToClear._busConnection) {
    moduleToClear._busConnection.sendMessage("__uninitialize", {});
    moduleToClear._busConnection.uninitialize();
    moduleToClear._busConnection = null;
  }
  if (moduleToClear) {
    // Remove its CSS
    modulePath = cr.clientRoot + moduleToClear.channelId + "/";
    cssURL = modulePath + moduleToClear.channelId + ".css";
    unloadCss(cssURL);

    // remove the body class
    body.removeClass(moduleToClear.channelId);

    // Unload its submodules
    if (moduleToClear.loadedSubModules) {
      for (name in moduleToClear.loadedSubModules) {
        subModule = moduleToClear.loadedSubModules[name];
        if (subModule) {
          if (subModule.inst && subModule.inst._busConnection) {
            subModule.inst._busConnection.uninitialize();
            subModule.inst._busConnection = null;
          }

          uninitializeModule(subModule.inst);
        }
      }
    }
  }
}

function unloadCss(path) {
  path = path.split("?v=")[0];
  $("head link[rel=stylesheet]").each(function () {
    let $this = $(this),
      href = $this.attr("href").split("?v=")[0];
    if (href === path) {
      $this.remove();
    }
  });
}

function smartSwitch(channelId) {
  // send message the that module has loaded
  // we can then pick up this message, as an example, in the header
  // to ensure that we've closed mobile-friendly navigation and re-enabled the getScreenPane()
  messageBus.sendMessage("router", "module-loaded");

  if (!currentModule || typeof currentModule.smartSwitch !== "function") return false;

  domUtil.clearModuleHelpers();

  return currentModule.smartSwitch(channelId) !== false; // `smartSwitch` doesn't have to return a value, but if it returns `false`, it failed
}

/**
 * @method setHashWithoutRouting
 *
 * Sets/replaces the hash, *without* triggering a routing operation.
 * NOTE: If you think you need to use this, get a second opinion from someone else first.
 * Normally, you want to allow the hash change to be routed as usual and have your model handle
 * detecting if it's being asked to do/show something it's already doing/showing.
 *
 * @param   hash        The hash to set
 * @param   replace     (Optional) truthy = use location.replace, falsey = set location.hash
 */
function setHashWithoutRouting(hash, replace) {
  suppressHashChange = hash;
  // we need to change format to the one known to React Router
  const url = "/" + hash.substr(1);
  if (replace) {
    crHistory.replace(url);
  } else {
    crHistory.push(url);
  }
}

/**
 * Runs module or submodule `uninitialize` method.
 * In case of error it just displays a warning in the console without throwing an error.
 * @param {*} instance
 */
function uninitializeModule(instance) {
  if (typeof instance.uninitialize === "function") {
    try {
      instance.uninitialize();
    } catch (e) {
      console.warn(
        "There was an error during uninitialize of " + (instance.channelId || instance.requireId || instance.name || "unknown instance"),
        e
      );
    }
  }
}

/**
 * THIS IS A HACK!
 * This should only be used when CR Router and React-Router are conflicting.
 * clearCurrentModule clears the current module. This was created to prevent smartSwitch issues that would occur when
 * navigating to and from components controlled by react-router and CR router.
 */
function clearCurrentModule() {
  if (currentModule) {
    clearModule(currentModule);

    uninitializeModule(currentModule);

    moduleLoading(false);
  }

  __screenPaneRef = null;
  currentModule = null;
  domUtil.clearModuleHelpers();
}

function initialize(screenPaneRef) {
  __screenPaneRef = screenPaneRef;

  // if this is a popup
  if (crHistory.isPopUp) {
    // read the module from the querystring parameter (not the hash)
    router.loadModule(crHistory.queryParameters.module);
    return;
  }

  const hashManager = new cr.hashUtil.hashManager();

  let currentHashState = hashManager.parseHashTag(window.location.hash);

  router.loadModule(currentHashState.module);

  function syncHashState() {
    // don't do anything if we're in the middle of unloading/reloading the whole page
    if (window.__appIsDead__) {
      return;
    }

    /*
     * Get the new hash object, which looks like this:
     *
     * {
     *   edit: "history",
     *   enddate: "2019-03-07",
     *   id: "189272",
     *   mode: "profile",
     *   module: "contacts",
     *   parameters: [
     *     {name: "id", value: "189272"},
     *     {name: "mode", value: "profile"},
     *     {name: "edit", value: "history"},
     *     {name: "startdate", value: "2019-03-07"},
     *     {name: "enddate", value: "2019-03-07"},
     *   ],
     *   startdate: "2019-03-07",
     *   submodule: "details",
     * }
     */
    const newHashState = hashManager.parseHashTag(window.location.hash);

    // Get the serialized hash state (AKA the URL), which looks like this:
    // "contacts/details/?id=189272&mode=profile&edit=history&startdate=2019-03-07&enddate=2019-03-07"
    const currentHash = cr.globalHashManager.createHashTag(newHashState);

    // Announce that we are about to change the hash
    // NOTE: the `hashChangingParams` object is NOT immutable -- quite the opposite,
    //       subscribers leverage this event to actually _change_ the hash before it's applied
    const hashChangingParams = { previousHashObj: currentHashState, hashObj: newHashState, oldParameters: currentHashState.getParameters() };
    Object.defineProperty(hashChangingParams, "newParameters", {
      get() {
        return newHashState.getParameters();
      }
    });

    // Re-evaluate what the hash tag is after all of the subscribers have had their chance to modify
    messageBus.sendMessage("gu-framework", "hashChanging", hashChangingParams);

    // update the persistent state
    currentHashState = newHashState;

    // get the hash value from the new state (that may have been modified)
    const newHash = cr.globalHashManager.createHashTag(newHashState);

    // if the subscribers have modified the state, replace the hash (and start the process all over again)
    if (currentHash !== newHash) {
      cr.globalHashManager.replaceHash(newHashState);
    } else {
      // otherwise this is for real, so process it and then let everyone know
      router.onHashChanged();
      messageBus.sendMessage("gu-framework", "hashChange", { hashObj: newHashState, parameters: newHashState.getParameters() });
    }
  }

  // expose this so it can be called on hash changes
  router.syncHashState = syncHashState;

  // also apply it immediately to sync up the hash with the router and the application state
  syncHashState();
}

export default Object.assign(router, {
  onHashChanged: hashChanged,
  loadModule: loadModule,
  routeToMenuItem: routeToMenuItem,
  switchToSubModule: switchToSubModule,
  loadSubmoduleHtml: loadSubmoduleHtml,
  setHashWithoutRouting: setHashWithoutRouting,
  clearCurrentModule,
  koReactSyncDelay,
  initialize
});
