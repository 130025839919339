import ApiTransport from "app/util/apiTransport";

const expireSeconds = (app_session_lock_interval / 1000) * 2 + 1;

class SessionLockService {
  private api = new ApiTransport();

  public async lock(identifier: string, userId: string, host: string, force: boolean) {
    const { locked, shouldLogout } = await this.api.post<{ locked: boolean; shouldLogout: boolean }>(
      "session-lock/ping",
      {},
      { host, userId, identifier, expireSeconds, force }
    );

    return { locked, shouldLogout };
  }

  public async release(identifier: string, userId: string, host: string) {
    try {
      await this.api.delete("session-lock/release", {}, { host, userId, identifier, expireSeconds });
    } catch (e) {}
  }

  public async generateIdentifier() {
    const { identifier } = await this.api.get<{ identifier: string }>("session-lock/identifier");
    return identifier;
  }
}

export default new SessionLockService();
