import crHistory from "@cr/core/history";
import { Logger } from "@cr/core/logger";
import { StoreStatus } from "@cr/mobx-stores";
import authenticationService from "app/security/authenticationService";
import sessionLockStore from "app/sessionLock/store";
import user, { getSSOUserFromCookie, isAutomatedClient } from "app/util/user";
import { action, autorun, computed, observable } from "mobx";
import { User as OidcUser, UserManager, WebStorageStateStore } from "oidc-client";
import { dispatchExternalLogin, inactivityDetection } from "./actions";
import {  UserSessionStatus } from "./models";
import RefreshTokenService from "app/util/refreshTokenService";
import { CRSessionMonitor } from "app/util/CRSessionMonitor";
import { UserSessionMessage, SendUserSessionMessage } from "./broadcastChannel";

const log = Logger.create("UserSessionStore");
const RETURN_URL_STORAGE_KEY = "oidc.returnUrl";

interface OpenIdConnectOptions {
  appBaseUrl: string;
  authority: string;
  clientId: string;
}

export const MILLISECONDS_FOR_IDLE_WARNING = 30000; //last milliseconds of inactivity before an externalLogout to display the warning
const CRSD_UPDATE_FREQUENCY_MILLISECONDS = 60000; //update frequency after user interaction
const REFRESH_TOKEN_RETRIES = 3;
const ACCESS_TOKEN_EXPIRING_NOTIFICATION_MILLISECONDS = 5 * 60 * 1000;
const REFRESH_TOKEN_PING_MILLISECONDS = 60 * 1000;

export class UserSessionStore {
  private _manager: UserManager;
  private _refreshTokenService: RefreshTokenService;
  private _deferInactivitySubscription: boolean = false;

  @observable _error: string = null;
  @observable private _status: StoreStatus = StoreStatus.Unintialized;
  @observable private _isLoggingOut: boolean = false;

  @observable externalLogout: boolean = false; //user logged out from another browser tab
  @observable sessionTimedOut: boolean = false;
  @observable inactivityWarning: boolean = false; //user inactivity warning

  // expose this as a computed property instead of comparing `state`, which may cause re-renders
  @computed
  get isLoggingOut() {
    return this._isLoggingOut;
  }

  // #region Getters
  @computed
  get currentSSOUser() {
    return user.ssoUser;
  }

  /**
   * the user's authentication state
   */
  @computed
  get state(): UserSessionStatus {
    if (this._isLoggingOut) {
      return "LoggingOut";
    }
    // if the Enterprise user's been authenticated
    // then the whole process is done and the user
    // is fully authorized to access the application
    if (user.isAuthenticated) {
      return "Authorized";
    }

    // otherwise, if the current user is valid that means the SSO user
    // has been authenticated but not (yet) authorized
    if (this.currentSSOUser) {
      // if we're loading then we don't know yet whether the user is authorized
      // so just report that we're authenticated, implying that we're waiting for
      // authorization
      if (this._status === StoreStatus.Loading) {
        return "Authenticated";
      }

      // otherwise, we're done trying to authorize and it failed
      return "Unauthorized";
    }

    // the current user hasn't even authenticated themselves with SSO yet
    return "Unauthenticated";
  }

  @computed
  get status() {
    if (this.error) {
      return StoreStatus.Error;
    }

    return this._status;
  }

  @computed
  get error() {
    return this._error;
  }

  @computed
  get redirectPath() {
    // Sorry admins - no easy way to handle redirects for now so you always get sent to your homepage
    if (user.isAdmin()) {
      return "/manage";
    }

    const latestSessionUrl = this.getReturnUrl();

    // Redirect to the originally requested location if there was one;
    // this could be in a "redir" URL parameter, or the link itself (especially if redirect link clicked while logged in)
    const redirect = authenticationService.getCurrentRedirectPath(false);

    // the user's cached/configured homepage setting
    const homepage = localStorage.getItem("framework/header::homepage");

    const defaultHomepage = "account";

    return `/${latestSessionUrl || redirect || homepage || defaultHomepage}`;
  }
  // #endregion

  constructor({ appBaseUrl, authority, clientId }: OpenIdConnectOptions, deferInactivitySubscription = false) {
    this._deferInactivitySubscription = deferInactivitySubscription;

    this._manager = new UserManager({
      authority,
      client_id: clientId,
      redirect_uri: `${appBaseUrl}/#auth/callback`,
      post_logout_redirect_uri: `${appBaseUrl}/#auth/logout`,
      silent_redirect_uri: `${appBaseUrl}/#auth/silent`,
      automaticSilentRenew: false, // see init method below
      includeIdTokenInSilentRenew: false,
      monitorSession: true,
      query_status_response_type: "code",
      response_type: "code",
      scope: "offline_access openid", // offline_access required for silent refresh
      loadUserInfo: true,
      prompt: undefined,
      revokeAccessTokenOnSignout: true,
      userStore: new WebStorageStateStore({ store: window.localStorage })
    });

    this._refreshTokenService = new RefreshTokenService({
      userManager: this._manager,
      numRetries: REFRESH_TOKEN_RETRIES,
      pingInterval: REFRESH_TOKEN_PING_MILLISECONDS,
      expirationThreshold: ACCESS_TOKEN_EXPIRING_NOTIFICATION_MILLISECONDS,
      initialCheck: true
    });

    // tslint:disable-next-line: no-string-literal
    window["CRUserSessionStore"] = this;

    if (this._deferInactivitySubscription) {
      autorun(() => {
        const { fullyLoaded, crSessionMonitorLoaded} = user;
        const { isPublicPage } = crHistory;

        // we should only subscribe to inactivity once we know we're logged in and all user data is loaded
        if (fullyLoaded && !isPublicPage && crSessionMonitorLoaded) {
          this.subscribeUserInactivityDetection();
        }
      });
    }
  }

  // This is here only to support unit tests
  private delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  // #region UserManager callback's

  @action
  private onUserLoaded = async (ssoUser: OidcUser) => {
    log.debug("onUserLoaded: ", ssoUser);
    await this.initializeUser(ssoUser);
  };

  @action
  private onUserSignedOut = async () => {
    //This event fires when you logout of the identity website
    this._refreshTokenService.stop();

    try {
      await this._manager.removeUser();
      await this._manager.clearStaleState();

      await sessionLockStore.release();
      await authenticationService.removeCookies();
    } catch (ex) {
      log.error("onUserSignedOut: Error removing user data", ex);
    }

    this.setExternalLogout(true);
  };
  // #endregion

  // #region Private actions
  @action
  private subscribeUserInactivityDetection = () => {
    var sessionMonitor = window["cr_session_monitor"] as CRSessionMonitor;
    if(sessionMonitor && !sessionMonitor.is_running()) {  
      sessionMonitor.clear_event_listeners();

      sessionMonitor.add_timeout_warning_event_listener(async () => {
        await inactivityDetection(UserSessionMessage.InactivityWarning);
      });

      sessionMonitor.add_timeout_event_listener(async () => {
        await inactivityDetection(UserSessionMessage.Logout);
      });

      sessionMonitor.add_user_activity_event_listener(async () => {
        await inactivityDetection(UserSessionMessage.UserInteraction);
      });

      sessionMonitor.add_keep_alive_event_listener(async () => {
        await inactivityDetection(UserSessionMessage.RefreshCRSDCookie);
        await SendUserSessionMessage({ message: UserSessionMessage.SessionRefreshed }); 
      });
  
      sessionMonitor.start({
        warningSeconds: MILLISECONDS_FOR_IDLE_WARNING / 1000,
        timeoutSeconds: user.maximumMillisecondsNoActivity / 1000,
        keepAliveSeconds: CRSD_UPDATE_FREQUENCY_MILLISECONDS / 1000,
        userInteractionEventSeconds: 1,
        baseUrl: window.app_ssoAuthority
      });
    } else {
      log.error("CRSessionMonitor not found");
    }
  };

  @action
  private unsubscribeUserInactivityDetection = () => {
    var sessionMonitor = window["cr_session_monitor"] as CRSessionMonitor;
    if(sessionMonitor) {  
      sessionMonitor.stop();
    }
  };

  @action
  private invalidateUser = () => {
    user.invalidate();
    this.unsubscribeUserInactivityDetection();
  };

  @action
  private initializeUser = async (ssoUser: OidcUser) => {
    await user.initialize(ssoUser);

    if (!this._deferInactivitySubscription && ssoUser && !crHistory.isPublicPage) {
      this.subscribeUserInactivityDetection();
    }
  };

  @action
  private loadUser = async (existingUser?: OidcUser) => {
    log.debug("loadUser");

    this._status = StoreStatus.Loading;

    try {
      const ssoUser = existingUser || (await this._manager.getUser());
      await this.initializeUser(ssoUser);

      this._status = StoreStatus.Loaded;
    } catch (ex) {
      this.setError(ex);
      log.error("Failed to load user", ex);
    }
  };

  private clearReturnUrl = () => window.sessionStorage.removeItem(RETURN_URL_STORAGE_KEY);

  private saveReturnUrl = (url: string = authenticationService.getCurrentRedirectPath(true)) => {
    if (url) {
      window.sessionStorage.setItem(RETURN_URL_STORAGE_KEY, url);
    }
  };

  private getReturnUrl = () => window.sessionStorage.getItem(RETURN_URL_STORAGE_KEY);

  // #endregion

  // #region User session handlers

  /**
   * Initializes the user session and handles special cases like the OIDC signin silent callback.
   *
   * @returns may return 'break' in case the requested url has already been handled and no UI is required/desired
   */
  @action
  init = async (): Promise<void | "break"> => {
    log.debug("init");

    const { pathname } = crHistory.location;
    const path = pathname.toLocaleLowerCase();

    // Before logging in with a new user, we want to remove an uncleaned user from the store
    if (path === "/auth" || path === "/auth/callback") {
      await this._manager.removeUser();
      await this._manager.clearStaleState();
    }

    if (path === "/auth/silent") {
      await this.signinSilentCallback();
      // This is going to happen inside an hidden iFrame, we don't want to load the UI here
      return "break";
    }

    const existingUser = getSSOUserFromCookie();
    const enableMonitors = !isAutomatedClient(existingUser?.profile);

    if (enableMonitors) {
      this._manager.events.addUserLoaded(this.onUserLoaded);
      this._manager.events.addUserSignedOut(this.onUserSignedOut);
      this._refreshTokenService.start();
    }

    await this.loadUser(existingUser);
  };

  @action
  login = async () => {
    log.debug("login");

    try {
      await authenticationService.removeCookies();
      await this._manager.removeUser();
      await this._manager.clearStaleState();

      log.info("Redirecting to IDP...");

      this.saveReturnUrl();
      return this._manager.signinRedirect();
    } catch (ex) {
      this.setError(ex);
      log.error("Error logging in", ex);
    }
  };

  @action
  signinSilentCallback = async (url?: string) => {
    await this._manager.signinSilentCallback();
  };

  @action
  signoutCallback = async (url: string) => {
    log.info("signoutCallback", url);
    if (url) {
      await this._manager.signoutCallback(url);
    } else {
      await this._manager.signinRedirect();
    }
  };

  private authorizeTokenWithRetry = async (token: string, retries = 0, pauseTime = 1000, attempt = 1) => {
    const authorized = await authenticationService.authorizeToken({ token });

    if (authorized || attempt > retries) {
      return authorized;
    }

    if (pauseTime) {
      await this.delay(pauseTime);
    }

    return this.authorizeTokenWithRetry(token, retries, pauseTime, attempt + 1);
  };

  /**
   * Authorizes an authenticated SSO user against the Enterprise API
   * to ensure that they are able to access the Enterprise application
   */
  @action
  private authorize = async (ssoUser: OidcUser): Promise<boolean> => {
    log.debug("authorize");

    const tokenRetries = 2;
    const tokenRetryPauseTime = 2000;
    const access_token = ssoUser && ssoUser.access_token;

    if (!access_token) {
      log.info("No SSO user or access token found");
      return false;
    }

    this._refreshTokenService.start();

    log.debug("Authorizing user with access token", access_token);

    try {
      if (await this.authorizeTokenWithRetry(access_token, tokenRetries, tokenRetryPauseTime)) {
        await this.initializeUser(ssoUser);
      }
    } catch (ex) {
      log.debug("Failed to authorize user", ex);
      return false;
    }

    // SSO user is authenticated, but Enterprise authentication failed,
    // probably because the user doesn't have an Enterprise login
    if (!user.isAuthenticated) {
      log.warn("SSO user has no Enterprise login for access token %s", access_token);
      return false;
    }

    log.info("Authorized user", user);
    return true;
  };

  @action
  signinRedirectCallback = async (url: string) => {
    log.info("signinRedirectCallback", url);
    this._status = StoreStatus.Loading;

    try {
      const ssoUser = await this._manager.signinRedirectCallback(url);
      const authorized = await this.authorize(ssoUser);

      if (authorized) {
        // send notification to other tabs
        await dispatchExternalLogin();

        this._status = StoreStatus.Loaded;

        // Redirect to latest page or home/admin
        crHistory.push(this.redirectPath);

        // clear return url
        this.clearReturnUrl();
      } else {
        // Could not authenticate against enterprise
        window.location.href = `${window.app_ssoAuthority}/applications`;
      }
    } catch (ex) {
      this._status = StoreStatus.Error;
      let error = ex.error || ex.message;

      if (error == "No matching state found in storage") {
        log.warn("No matching state - attempting to re-login...");
        await this.login();
        return;
      }

      if (error == "login_required") {
        log.info("Login required - redirecting...");
        await this.login();
        return;
      }

      if (error && error.includes("state")) {
        crHistory.push("/auth");
      }

      log.error("Error processing signin callback", ex);

      //if a user does not have there clock synced w/ the server, they will get an invalid time error
      if (/is in the (future|past)/.test(error)) {
        error = "Device clock is out of sync with the network. Please log out, correct your time and log back in.";
      }

      //this should never happen but it is one of the errors returned from oidc-client - mapping it just in case
      if (/(iat|exp) was not provided/.test(error)) {
        error = "The token is not valid. Please log out and log back in.";
      }

      this._error = error || "Unknown error";
    }
  };

  @action
  logout = async (preLogoutAction?: () => Promise<void>) => {
    log.debug("logout");

    if (this._isLoggingOut) {
      return;
    }

    this._isLoggingOut = true;

    const id_token = this.currentSSOUser && this.currentSSOUser.id_token;

    try {
      this.saveReturnUrl();

      await this._manager.removeUser();
      await this._manager.clearStaleState();

      await sessionLockStore.release();
      await authenticationService.removeCookies();

      this.invalidateUser();

      if (preLogoutAction) {
        await preLogoutAction();
      }

      log.info("Redirecting to IDP logout");
      await this._manager.signoutRedirect({ id_token_hint: id_token });
    } catch (ex) {
      this.setError(ex);
      log.error("Error logging out", ex);
    }
  };
  //#endregion

  @action
  setExternalLogout = (externalLogout: boolean) => {
    this.externalLogout = externalLogout;

    if (externalLogout && user.isAuthenticated) {
      this.invalidateUser();
    }
  };

  @action
  setInactivityWarning = (inactivityWarning: boolean) => {
    this.inactivityWarning = inactivityWarning;
    var sessionMonitor = window["cr_session_monitor"] as CRSessionMonitor;
    if(sessionMonitor) {  
      if(inactivityWarning) {
        sessionMonitor.disable_interaction_updates();
      } else {
        sessionMonitor.enable_interaction_updates();
      }
    }
  };

  @action
  setSessionTimedOut = (sessionTimedOut: boolean) => {
    this.sessionTimedOut = sessionTimedOut;
  };

  /**
   * Provides a valid access token
   * @returns the token or null if the user is not logged in
   * @throws Error in case it wasn't possible to retrieve a valid token and the current one is expired
   */
  getAccessTokenAsync = async () => {
    const ssoUser = this.currentSSOUser;

    // We're not logged in, exit
    if (!ssoUser || !user.isAuthenticated) {
      return null;
    }

    // Current token is valid, go with it
    if (!ssoUser.expired) {
      return ssoUser.access_token;
    }

    await this._refreshTokenService.renewUserIfExpired();
    const renewedUser = await this._manager.getUser();

    return renewedUser?.access_token;
  };

  @action
  private setError = err => {
    if (typeof err == "string") {
      this._error = err;
    } else if ("message" in err) {
      this._error = err.message;
    } else if ("error" in err) {
      this._error = err.error;
    } else {
      this._error = "Unknown error (see logs)";
    }
  };
}

export const userSessionStore = new UserSessionStore(
  {
    appBaseUrl: document.location.origin,
    authority: window.app_ssoAuthority,
    clientId: window.app_ssoClientId
  },
  true
);
