import React from "react";
import { Typography, TypographyProps } from "@material-ui/core";
import { ThemeProvider } from "@cr/material-ui-theme-provider";
import GlobalStyle from "../globalStyle";

const MuiTypography = ({ children, ...rest }: TypographyProps) => (
  <>
    <GlobalStyle styles=".cr-mui-wrapper-typography > * { display: inline-block; }" />
    <span className="cr-mui-wrapper-typography">
      <ThemeProvider skipGlobalStyles>
        <Typography {...rest}>{children || null}</Typography>
      </ThemeProvider>
    </span>
  </>
);

export const knockoutName = "cr-mui-typography";

export default MuiTypography;
