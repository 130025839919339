import hashChangeSwallowed from "./hashChangeSwallowed";

export class SerializedHash {
  module: string;
  submodule: string;
  subsubmodule: string;
  parameters: { [key: string]: string };
  __rawHash: string;

  constructor(module?: string, submodule?: string, subsubmodule?: string) {
    this.module = module || "";
    this.submodule = submodule || "";
    this.subsubmodule = subsubmodule || "";
    this.parameters = Object.create(null);
  }

  setValue(key, value) {
    this.parameters[key] = value;
  }

  removeValue(key) {
    delete this.parameters[key];
  }

  getValue(key) {
    return this.parameters[key];
  }
}

export interface SetHashOptions {
  overwrite?: boolean;
}

export class HashUtility {
  parseHashTag(hash: string) {
    if (!hash) {
      return new SerializedHash();
    }

    const rawHash = hash;
    let parsedHash = hash;
    if (parsedHash.indexOf("#") > -1) {
      parsedHash = parsedHash.split("#")[1];
    }

    if (parsedHash.charAt(parsedHash.length - 1) === "/") {
      parsedHash = parsedHash.substr(0, parsedHash.length - 1);
    }

    const modSubmodSection = parsedHash.indexOf("?") > -1 ? parsedHash.split("?")[0] : parsedHash;
    const queryStringSection = parsedHash.indexOf("?") > -1 ? parsedHash.split("?")[1] : null;
    const modSections = modSubmodSection.split("/");

    const result = new SerializedHash(modSections[0], modSections[1], modSections[2]);
    result.__rawHash = rawHash;

    if (queryStringSection) {
      const pairs = queryStringSection.split("&");
      for (let i = 0, max = pairs.length; i < max; i++) {
        const keyValuePair = pairs[i].split("=");
        const key = keyValuePair[0];
        const value = keyValuePair.length > 1 ? decodeURIComponent(keyValuePair[1]) : undefined;

        result.setValue(key, value);
      }
    }

    return result;
  }

  createHashTag(hashObject: SerializedHash) {
    let result = "";

    if (hashObject.module) {
      result += hashObject.module;
    }
    if (hashObject.submodule) {
      result += "/" + hashObject.submodule;
    }
    if (hashObject.subsubmodule) {
      result += "/" + hashObject.subsubmodule;
    }

    const allPairs = [];
    if (hashObject.parameters) {
      Object.keys(hashObject.parameters).forEach(k => {
        if (hashObject.parameters[k] !== "" && hashObject.parameters[k] !== null && hashObject.parameters[k] !== undefined) {
          allPairs.push(`${k}=${encodeURIComponent(hashObject.parameters[k]).replace(/%2C/g, ",")}`);
        }
      });
    }

    if (allPairs.length) {
      result += "/?" + allPairs.join("&");
    }
    return result;
  }

  removeFromHash(...keys) {
    const hashInfo = this.parseHashTag(window.location.hash);
    keys.forEach(key => hashInfo.removeValue(key));
    this.setHash(hashInfo);
  }

  setParameter(key, value) {
    const hashInfo = this.parseHashTag(window.location.hash);
    hashInfo.setValue(key, value);
    this.setHash(hashInfo);
  }

  setHash(hashInfo: SerializedHash, options: SetHashOptions = {}) {
    const newHash = this.createHashTag(hashInfo),
      oldHash = window.location.hash.replace(/^#/, "").replace(/\/$/, "");

    if (newHash.replace(/^#/, "").replace(/\/$/, "") === oldHash) {
      hashChangeSwallowed.hashChangeSwallowed();
      return;
    }

    if (options.overwrite) {
      this.overwriteToNewHash(newHash);
    } else {
      window.location.hash = newHash;
    }
  }

  mergeParameters(properties, options?: SetHashOptions) {
    const hashInfo = this.parseHashTag(window.location.hash);
    Object.keys(properties).forEach(key => hashInfo.setValue(key, properties[key]));
    this.setHash(hashInfo, options);
  }

  getParameterValue(name) {
    return this.getCurrentHashInfo().getValue(name);
  }

  getCurrentHashInfo() {
    return this.parseHashTag(window.location.hash);
  }

  overwriteToNewHash(newHash) {
    location.replace(location.href.replace(/#.*/, !/^#/.test(newHash) ? "#" + newHash : newHash));
  }
  //for rare, internal use only, for when you're updating the hash.  If very fast (likely only programmatic, and therefore
  //irrelevant) hash changes are made it's possible that a previous change will not be propagated through to currentHash.parameters
  //since the hashChange event runs asynchronously.  As a result you might overwrite a previously applied value.  This method will
  //protect you from that.  Specifically, this is mainly only for mappedArray values on the hash, where there can be multiple values;
  //they aggregate. Selecting array value 1, then 2, must result in 1-2, not 2.  Again, this is likely impossible to ever be an issue
  //in real life, but I'm adding it here because it makes me feel better.

  // tslint:disable-next-line:function-name
  __getCurrentParametersOnHash() {
    return this.getCurrentHashInfo().parameters;
  }
}

export default new HashUtility();
