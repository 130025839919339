import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { ThemeProvider } from "@cr/material-ui-theme-provider";

interface DialogProps {
  onMount: (r: any) => void;
  acceptButtonText: string;
  declineButtonText: string;
}

const MuiModal = ({ onMount, acceptButtonText = "Continue", declineButtonText = "Cancel" }: DialogProps) => {
  const [isOpen, setIsOpen] = useState(false),
    [title, setTitle] = useState("Are you sure?"),
    [body, setBody] = useState("Would you like to continue?"),
    [userSelection, setUserSelection] = useState<(result: boolean) => void>(),
    onClose = () => setIsOpen(false),
    openNewDialog = (title: string, body: string, onUserSelection: () => void) => {
      setTitle(title);
      setBody(body);
      setUserSelection(() => onUserSelection);
      setIsOpen(true);
    },
    accept = () => {
      userSelection?.(true);
      setIsOpen(false);
    },
    decline = () => {
      userSelection?.(false);
      setIsOpen(false);
    };

  useEffect(() => onMount(openNewDialog), []);
  return (
    <ThemeProvider skipGlobalStyles>
      <Dialog fullWidth open={isOpen} onClose={onClose} keepMounted>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{body}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={decline} color="primary">
            {declineButtonText}
          </Button>
          <Button variant="contained" onClick={accept} color="primary" autoFocus>
            {acceptButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export const knockoutName = "cr-mui-modal";

export default MuiModal;
