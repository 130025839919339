import React from "react";
import { makeStyles, IconButton, Snackbar, SnackbarProps } from "@material-ui/core";
import { ThemeProvider } from "@cr/material-ui-theme-provider";
import { CloseIcon } from "@cr/icons/Close";
import { Alert, Color } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    MuiSnackbar: {
        "& .MuiSnackbarContent-root": {
            flexWrap: "nowrap"
        }
    },
    MuiSnackbarAlert: {
        color: "#fff",
        backgroundColor: "transparent",
        padding: 0
    }
}));

const MuiSnackbar = ({ closeAlert, severity, message, ...rest }: SnackbarProps & { closeAlert: () => void, severity?: Color }) =>  {
    const classes = useStyles();
    return (
        <ThemeProvider skipGlobalStyles>
            <Snackbar
                className={classes.MuiSnackbar}
                {...rest}
                message={severity ? <Alert severity={severity} className={classes.MuiSnackbarAlert}>{message}</Alert> : message}
                onClose={closeAlert}
                action={
                    <IconButton color="inherit" onClick={closeAlert}>
                        <CloseIcon />
                    </IconButton>
                }
            />
        </ThemeProvider>
    );
}

export const knockoutName = "cr-mui-snackbar";

export default MuiSnackbar;
