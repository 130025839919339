export enum UserSessionMessage {
  Logout,
  Login,
  InactivityWarning,
  UserInteraction,
  RefreshCRSDCookie,
  RefreshSession,
  SessionRefreshed
}

export interface UserSessionBroadcastChannel {
  message: UserSessionMessage;
  newValue?: any;
  userId?: number;
}
