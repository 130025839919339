import { action, computed, observable } from "mobx";
import { BroadcastChannel, createLeaderElection, LeaderElector } from "broadcast-channel";
import user, { UserType } from "app/util/user";
import crHistory from "@cr/core/history";
import { isAuthRoute } from "app/util/historyUtils";
import sessionLockService from "./service";

const channelName = "cr-user-session-lock";

class SessionLockStore {
  channel: BroadcastChannel = null;
  elector: LeaderElector = null;

  private _localStorageKey = "cr-device-id";
  private _sessionStorageKey = "cr-force-logged-out";

  @observable private _releasedLock = false;
  @observable private _forceLoggedOut = false;

  constructor() {
    const createChannel = () => {
      this.channel = new BroadcastChannel(channelName, {
        idb: {
          onclose: () => {
            // see https://github.com/pubkey/broadcast-channel#readme
            // the onclose event is just the IndexedDB closing.
            // you should also close the channel before creating
            // a new one.
            this.channel.close();
            createChannel();
          }
        }
      });
    };

    createChannel();

    this.elector = createLeaderElection(this.channel);
    this.elector.awaitLeadership();
  }

  private async getIdentifier() {
    const existingIdentifier = window.localStorage.getItem(this._localStorageKey);

    if (existingIdentifier) {
      return existingIdentifier;
    }

    const identifier = await sessionLockService.generateIdentifier();
    window.localStorage.setItem(this._localStorageKey, identifier);

    return identifier;
  }

  private getUserId() {
    return user && user.ssoUser ? user.ssoUser.userid : null;
  }

  private getHost() {
    return window.location.host;
  }

  @computed
  get sessionMonitoringEnabled() {
    const optedOut = user.hasFeature("contacts", "allow_multiple_logins");
    const { fullyLoaded, isImpersonating, type, hasValidOrganizationId } = user;
    const shouldMonitorSession =
      fullyLoaded && !isImpersonating && !optedOut && hasValidOrganizationId && (type === UserType.Employee || type === UserType.Generic);

    return shouldMonitorSession;
  }

  @computed
  get sessionMonitoringActive() {
    const { sessionMonitoringEnabled, _forceLoggedOut: forceLoggedOut, _releasedLock: releasedLock } = this;
    const {
      isPublicPage,
      location: { pathname }
    } = crHistory;

    return sessionMonitoringEnabled && !forceLoggedOut && !releasedLock && !isPublicPage && !isAuthRoute(pathname);
  }

  @action.bound
  isPrimaryTab() {
    return this.elector.isLeader;
  }

  @action.bound
  async lock(force = false) {
    const identifier = await this.getIdentifier();
    const userId = this.getUserId();
    const host = this.getHost();

    return sessionLockService.lock(identifier, userId, host, force);
  }

  @action.bound
  async release() {
    if (!this.sessionMonitoringEnabled) {
      return;
    }

    this._releasedLock = true;

    const identifier = await this.getIdentifier();
    const userId = this.getUserId();
    const host = this.getHost();

    return sessionLockService.release(identifier, userId, host);
  }

  @action.bound
  setForceLoggedOut() {
    this._forceLoggedOut = true;
    window.sessionStorage.setItem(this._sessionStorageKey, "1");
  }
}

export default new SessionLockStore();
