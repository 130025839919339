import { User } from "app/util/user";
import { inject } from "mobx-react";

/**
 * Use this to extend a props interface of a component decorated
 * with the `@injectUser` decorator
 */
export interface UserProp {
  /** The current user */
  user?: User;
}

/**
 * Injects the current user as the `user` prop
 */
export const injectUser = (clazz?) => {
  const injector = inject("user");
  return clazz == null ? injector : injector(clazz);
};
