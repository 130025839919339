import transport from "app/util/transport";
import { GeocodeService } from "@cr/geocoding";
import { rewriteUrlToEksApi } from "app/util/eks";

const noImageKey = "noImage";

const imgUrlDirect = (imageHash, useOrgImage) =>
  !imageHash
    ? `${app_profileImagePrefix}/shared/images/profiles/${noImageKey}.jpg`
    : `${app_profileImagePrefix}/shared/images/${useOrgImage ? "organizations" : "profiles"}/${imageHash}.jpg`;

const imgProfileUrlDirect = imageHash => imgUrlDirect(imageHash, false);

const imgOrgUrlDirect = imageHash => imgUrlDirect(imageHash, true);

const contactNoImage = imgUrlDirect(null, false);

const imgUrl = (imageHash, useOrgImage) =>
  !imageHash || imageHash === noImageKey
    ? contactNoImage
    : rewriteUrlToEksApi(`${app_apiurl}/contacts/profile-picture?imageHash=${encodeURIComponent(imageHash)}&isOrganization=${useOrgImage ? 1 : 0}`);

const imgProfileUrl = imageHash => imgUrl(imageHash, false);

const imgOrgUrl = imageHash => imgUrl(imageHash, true);

// ===== ExpiringCache

// ----- Utility methods

// Removes an entry from the given cache using its true key
function removeEntry(cache, cachekey) {
  delete cache.cache[cachekey];
}

// ----- Public methods

/**
 * Create an ExpiringCache.
 *
 * Params:
 *      cacheTime        The number of milliseconds to cache entries.
 *      refresh          true to refresh "cached" timestamp on entries when retrieved, false not to (default false)
 */
function ExpiringCache(cacheTime, refresh) {
  this.cache = {};
  this.cacheTime = cacheTime || 60000; // One minute default
  this.refresh = !!refresh;
}

/**
 * ExpiringCache#get: Get a cached value.
 *
 * Params:
 *      key         The value's key.
 *      markPending (Default false) true to mark the entry as "pending" if no
 *                  entry exists; subsequent calls to #get will see
 *                  `this.PENDING` as the value.
 * Returns:
 *      The value, or undefined if we don't have one stored or it expired.
 */
ExpiringCache.prototype.get = ExpiringCache_get;
function ExpiringCache_get(key, markPending) {
  var cachekey, entry, now;

  cachekey = "xx" + key;
  entry = this.cache[cachekey];
  if (entry) {
    now = Date.now();
    if (now - entry.tstamp < this.cacheTime) {
      if (this.refresh) {
        entry.tstamp = now;
      }
      return entry.value;
    }
    removeEntry(this, cachekey);
  }
  if (markPending) {
    this.put(key, this.PENDING);
  }
  return undefined;
}

/**
 * ExpiringCache#put: Put a value in the cache.
 *
 * Params:
 *      key     The value's key.
 *      value   The value
 * Returns:
 *      This cache instance (for chaining).
 */
ExpiringCache.prototype.put = ExpiringCache_put;
function ExpiringCache_put(key, value) {
  this.cache["xx" + key] = {
    tstamp: Date.now(),
    value: value
  };
  return this;
}

/**
 * ExpiringCache#remove: Remove the given cache entry (whether pending or
 * real).
 *
 * Params:
 *      key      The value's key.
 * Returns:
 *      This cache instance (for chaining).
 */
ExpiringCache.prototype.remove = ExpiringCache_remove;
function ExpiringCache_remove(key) {
  removeEntry(this, "xx" + key);
  return this;
}

/**
 * ExpiringCache#clear: Clears all entries out of the cache.
 *
 * Returns:
 *      This cache instance (for chaining).
 */
ExpiringCache.prototype.clear = ExpiringCache_clear;
function ExpiringCache_clear() {
  this.cache = {};
  return this;
}

/**
 * ExpiringCache#clearExpired: Clears all expired entries out of the cache.
 *
 * Returns:
 *      This cache instance (for chaining).
 */
ExpiringCache.prototype.clearExpired = ExpiringCache_clearExpired;
function ExpiringCache_clearExpired() {
  var cachekey,
    now = Date.now();
  for (cachekey in this.cache) {
    if (now - this.cache[cachekey].tstamp >= this.cacheTime) {
      removeEntry(this, cachekey);
    }
  }
  return this;
}

/**
 * A flag value that we return from #get when there's a pending entry.
 */
ExpiringCache.prototype.PENDING = {};

// ===== CachingTransport

/**
 * Constructs an object that uses the given `transmitRequest` function to send requests to the
 * server, caching results for a period of time and reusing the cached results if re-requested.
 *
 * @params  channelId       The channelId to use for requests
 * @param   cacheTime       (Default 30000) The # of milliseconds to keep the cached
 *                          responses before considering them stale.
 */
function CachingTransport(channelId, cacheTime) {
  this.channelId = channelId;
  this.cache = new ExpiringCache(cacheTime);
  this.pending = {};
}

/**
 * Issue a request, get the response:
 *
 * If we don't have a cached copy of a response for this request:
 *      1. Starts the startStopObservables in `during`, calls `starting`
 *      2. Issues the request
 *      When it completes (asynchronously):
 *      3. Stops the startStopObservables in `during`, calls `stopping`
 *      4. Caches the result
 *      5. Calls `done`
 *
 * If we have a fresh cached copy of the response:
 *      1. Calls `done` asynchronously after a very, very short timeout
 *
 * If we have an outstanding request
 *      1. Starts the startStopObservables in `during`, calls `starting`
 *      When the request completes (asynchronously):
 *      2. Stops the startStopObservables in `during`, calls `stopping`
 *      3. Calls `done`
 *
 * @param   options     An options object with:
 *                          owner:      The value to use as `this` in callbacks
 *                          action:     The action string for the request
 *                          params:     The params for the request
 *                          done:       The callback for when the request is done,
 *                                      receives the response object.
 *                          during:     An array of `startStopObservable`s to call
 *                                      receives the response object.
 *                                      `start` on when starting and `stop` on when
 *                                      stopping
 *                          starting:   A callback called when starting the request,
 *                                      receives a copy of the params that will be
 *                                      used (must not modify them)
 *                          stopping:   A callback called when starting the request,
 *                                      receives a copy of the params that were used
 *                      All are optional except `action`
 * @return  true if we issued the request, false if it was a duplicate of an
 *          outstanding request
 */
var defaultOptions = {
  owner: null,
  params: {},
  during: [],
  starting: $.noop,
  stopping: $.noop,
  done: $.noop
};
CachingTransport.prototype.request = function CachingTransport$request(options) {
  var cache, pending, response, cacheKey, cacheEntry, args;

  cache = this.cache;
  pending = this.pending;

  options = $.extend(true, {}, defaultOptions, options);
  cacheKey = options.action + "::" + getOrderedJSON(options.params);
  cacheEntry = cache.get(cacheKey, true);
  if (cacheEntry && cacheEntry !== cache.PENDING) {
    // Fresh cache entry
    setTimeout(options.done.bind(options.owner, $.extend(true, {}, cacheEntry.response)), 0);
  } else {
    // There will be a delay, start spinnin'
    options.during.forEach(function (sso) {
      sso.start();
    });
    options.starting.call(options.owner, options.params);

    if (cacheEntry) {
      // Pending duplicate call is outstanding, add us to the completion list
      pending[cacheKey].push(options);
    } else {
      // Do the request
      pending[cacheKey] = [options];
      transport.transmitRequest(this.channelId, options.action, options.params, function (response) {
        cache.put(cacheKey, {
          response: response
        });
        pending[cacheKey].forEach(function (opts, index) {
          opts.during.forEach(function (sso) {
            sso.stop();
          });
          opts.stopping.call(opts.owner, opts.params);
          opts.done.call(opts.owner, $.extend(true, {}, response));
        });
        delete pending[cacheKey];
      });
    }
  }
};

/**
 * Clear this transport's cache.
 */
CachingTransport.prototype.clearCache = function CachingTransport$clearCache() {
  this.cache.clear();
};

// ===== Standalone functions

/**
 * Returns an "hours/minutes" string for the given number of minutes.
 *
 * @param   val         The number of minutes
 * @return  The string
 */
function prettyTime(val) {
  var hours = Math.floor(+val / 60);
  var minutes = +val % 60;

  return (hours > 0 ? hours + (hours > 1 ? " hours" : " hour") : "") + (hours && minutes ? " " : "") + (minutes > 0 ? minutes + " minutes" : "");
}

/**
 * Builds a JSON string from the given value, but making sure that object properties appear in
 * the string in alphabetic order. Use cases:
 *      Cache keys
 *      Comparing keep object graphs
 *
 * @param   value       The value to stringify
 * @return  The result as a string, unless `value` is just `undefined` or a function reference,
 *          in which case the result is `undefined`
 */
var toString = Object.prototype.toString;
function getOrderedJSON(value) {
  var rv;

  if (value === null) {
    rv = "null";
  } else {
    switch (typeof value) {
      case "undefined":
      case "function":
        rv = undefined;
        break;
      case "object":
        switch (toString.call(value)) {
          case "[object Array]":
            // In JSON, array entries with functions or `undefined` => `null`
            rv =
              "[" +
              value
                .map(function (entry) {
                  var result = getOrderedJSON(entry);
                  return result === undefined ? "null" : result;
                })
                .join(",") +
              "]";
            break;
          case "[object Object]":
            // In JSON, properties with functions or `undefined` are left out
            rv =
              "{" +
              Object.keys(value)
                .sort()
                .reduce(function (acc, key) {
                  var result = getOrderedJSON(value[key]);
                  if (result !== undefined) {
                    acc.push(JSON.stringify(key) + ":" + result);
                  }
                  return acc;
                }, [])
                .join(",") +
              "}";
            break;
          default:
            rv = JSON.stringify(value);
            break;
        }
        break;
      default:
        rv = JSON.stringify(value);
        break;
    }
  }
  return rv;
}

/**
 * Given a function, `debounce` returns a new function that "debounces" calls to the original:
 * When the new function is called, it remembers the args and sets a timeout to call the original
 * after the delay you give. If the new function is called again before the timer expires, it
 * cancels the original call and sets up a new one.
 *
 * Only suitable for functions where earlier calls should be completely ignored in favor of later
 * ones, and where the return value isn't used (since we don't have a return value until later).
 *
 * Example 1: A non-method function, `theFunction`, that should be globally debounced by 50ms
 * whenever called:
 *
 *      var f = util.debounce(50, theFunction);
 *
 * Example 2: An instance-specific method, `theMethod`, that should be debounced by 50ms whenever
 * called; debouncing is per-instance, not global:
 *
 *      TheConstructor.prototype.theMethod = util.debounce(50, "theMethodBounce", function() {
 *          // ...
 *      });
 *
 * @param   delay       The delay before calling it (in milliseconds)
 * @param   bounceProp  (Optional) If the function is a method of an object, this is the property
 *                      name to use (on that object) to remember the data we use to debounce it
 *                      (see examples).
 *                      If not given, the assumption is that the function being called is NOT a
 *                      method of an object, but a single function, and we use our own internal
 *                      timer handle.
 * @param   f           The underyling function to call
 * @returns The new function to use instead, to get debouncing
 */
function debounce(delay, bounceProp, f) {
  var data;

  // If no bounceProp, shuffle things around
  if (typeof bounceProp === "function") {
    f = bounceProp;
    bounceProp = undefined;
  }

  // Return the debouncing function
  return function () {
    var d = bounceProp ? this[bounceProp] : data;

    // Clear previous if any
    if (d && d.timer) {
      clearTimeout(d.timer);
      d.timer = 0;
    }

    // Create data object
    if (bounceProp) {
      d = this[bounceProp] = {};
    } else {
      d = data = {};
    }

    // Remember the arguments
    d.args = arguments;

    // Set up the callback after the delay
    d.timer = setTimeout(debounce_exec.bind(this), delay);
  };
  function debounce_exec() {
    var d;

    // Get the data for this call, clear it out
    if (bounceProp) {
      d = this[bounceProp];
      this[bounceProp] = undefined;
    } else {
      d = data;
      data = undefined;
    }

    // Do the call
    f.apply(this, d.args);
  }
}

// ==== JSON Utilities

// Used by crJSONReviver
var jsonDateMatcher = /^\/Date\((\d+)\)\/$/;

// The reviver function used by cr$util$parseJSON to handle dates and such.
function crJSONReviver(key, value) {
  var m;

  if (typeof value === "string" && (m = jsonDateMatcher.exec(value)) != null) {
    return new Date(+m[1]);
  }
  return value;
}

// Parse JSON doing Date reviving
/**
 * cr$util$parseJSON
 *
 * Parses the given JSON string, handling converting strings in the format
 * "/Date(123456789)/" into `Date` instances.
 *
 * @param   json    The JSON to parse
 * @return  The parsed data
 */
function cr$util$parseJSON(json) {
  return JSON.parse(json, crJSONReviver);
}

// The replacer function used by cr$util$stringifyJSON to handle dates.
function crJSONReplacer(key, value) {
  var realValue = this[key];
  if (CrIsDateValid(realValue)) {
    return "/Date(" + realValue.getTime() + ")/";
  }
  return value;
}

/**
 * cr$util$stringifyJSON
 *
 * Stringify to JSON storing `Date` instances as strings in the format "/Date(123456789)/",
 * where the number is the milliseconds-since-the-Epoch value.
 *
 * @param   data    The data to stringify
 * @return  The stringified data
 */
function cr$util$stringifyJSON(data) {
  return JSON.stringify(data, crJSONReplacer);
}

// ==== ModuleStorage

/**
 * A localStorage-like storage area that does three things:
 *
 * 1) Works relative to a prefix given in the constructor, not the origin (simply
 *    by prefixing keys).
 * 2) Accepts and returns any kind of value except `undefined`, not just strings
 * 3) Handles serializing and deserializing Date instances, on their own or as
 *    properties on an object or in an array.
 *
 * IMPORTANT: You cannot use raw properties with ModuleStorage as you can with
 * localStorage/sessionStorage. You MUST use `setItem` and `getItem` instead.
 *
 * Other than that, ModuleStorage has *slight* semantic differences from the W3C Storage
 * interface, so be sure to check the function docs; it also doesn't support the `length`
 * property or the `key` methods (not worth the complexity), though it does have a `keys`
 * method to get an array of the keys for this ModuleStorage.
 *
 * The router makes a ModuleStorage instance for the module available on the
 * `privateData` object as `storage`. So for storing module-specific settings, you can use
 * `privateData.storage.getItem(...)` and `privateData.storage.setItem(...)` (assuming
 * `privateData` is the name you gave the first argument of your module's `initialize` method).
 *
 * ModuleStorage will "work" even on a device that doesn't have `localStorage`, but the
 * values will only be remembered for the lifecycle of the current window. This is just so
 * older mobiles and such don't actually break.
 */

/**
 * Creates the ModuleStorage for the given page.
 *
 * @param   channelId   The module's standard channelId, e.g. "scheduling"
 * @param   subId       (Optional) The submodule ID, e.g. "manage" for "scheduling/manage"
 *                      If not given, settings are module-wide.
 */
var ModuleStorage = function ModuleStorage(channelId, subId) {
  subId = subId ? "/" + subId : "";
  this.keyPrefix = channelId + subId + "::";
};

ModuleStorage.isReal = (function () {
  var testKey = "____cr_storage_test____",
    real = false;

  // Some browsers (notably Apple's Safari in Private Browsing Mode) have a localStorage
  // object, but then throw an error when you try to use it. So find out whether we have
  // a real localStorage and remember that.
  try {
    if (typeof localStorage !== "undefined") {
      localStorage.setItem(testKey, "1");
      real = localStorage.getItem(testKey) === "1";
      localStorage.removeItem(testKey);
    }
  } catch (e) {}
  return real;
})();
(function (p) {
  var real, ls, hasOwn;

  // If this browser doesn't have localStorage, create just enough of a pseudo one for us
  if (ModuleStorage.isReal) {
    ls = localStorage;
  } else {
    // Note: We know that the key won't be "setItem", "getItem", or "removeItem" because the
    // key will have a prefix, and the prefixes always have "::" at the end. So we don't
    // have to worry about the conflict between the methods and the data.
    hasOwn = Object.prototype.hasOwnProperty;
    ls = {
      setItem: function (key, item) {
        this[key] = item;
      },
      getItem: function (key) {
        if (hasOwn.call(this, key)) {
          return this[key];
        }
        return null;
      },
      removeItem: function (key) {
        delete this[key];
      }
    };
  }

  /**
   * true if we're really using local storage, false if we're emulating it
   */
  p.isReal = ModuleStorage.isReal;

  /**
   * Sets an item in module storage.
   *
   * @param   key     The key to store it under
   * @param   data    The data to store
   */
  (p.setItem = function (key, data) {
    if (typeof data === "undefined") {
      throw new Error("Can't store `undefined` in module storage");
    }
    ls.setItem(this.keyPrefix + key, cr$util$stringifyJSON(data));
  }),
    /**
     * Gets an item from module storage.
     *
     * @param   key     The key of the data to retrieve
     * @return  data    The data, or undefined if none (or if you stored null previously)
     */
    (p.getItem = function (key) {
      var str = ls.getItem(this.keyPrefix + key);
      if (str === null) {
        return undefined;
      }

      try {
        return cr$util$parseJSON(str);
      } catch (e) {
        if (typeof console !== "undefined") {
          console.error(
            "ModuleStorage#getItem: Cannot parse value for key '" +
              key +
              "' (prefix '" +
              this.keyPrefix +
              "'), returning blank object. Value: <<" +
              str +
              ">>"
          );
        }
        return {};
      }
    }),
    /**
     * Removes an item from module storage.
     *
     * @param   key     The key of the data to remove
     */
    (p.removeItem = function (key) {
      ls.removeItem(this.keyPrefix + key);
    }),
    /**
     * Get a list of the keys for this module's storage.
     *
     * @return  the list as an array
     */
    (p.keys = function () {
      var n,
        keyPrefix = this.keyPrefix,
        prefixLength = this.keyPrefix.length,
        key,
        keys;
      if (real) {
        // We're really using localStorage
        keys = [];
        for (n = 0; n < localStorage.length; ++n) {
          key = localStorage.key(n);
          if (key.substring(0, prefixLength) === keyPrefix) {
            keys.push(key.substring(prefixLength));
          }
        }
      } else {
        // We're not really using localStorage
        keys = Object.keys(ls)
          .filter(function (key) {
            return key.substring(0, prefixLength) === keyPrefix;
          })
          .map(function (key) {
            return key.substring(prefixLength);
          });
      }
      return keys;
    }),
    /**
     * Clear all data related to this page.
     */
    (p.clear = function () {
      this.keys().forEach(function (key) {
        this.removeItem(key);
      }, this);
    });
})(ModuleStorage.prototype);

const asyncForEachSequential = (arr, func) => arr.reduce((p, val, i) => Promise.resolve(p).then(() => func(val, i)), Promise.resolve());
const asyncForEachParallel = (arr, func) => Promise.all(arr.map((val, i) => Promise.resolve(func(val, i))));

function phone(num) {
  if (num.length < 10) return num;

  let retval = `(${num.substring(0, 3)}) ${num.substring(3, 6)}-${num.substring(6, 10)}`;

  if (num.length > 10) retval += ` Ext. ${num.substring(10)}`;

  return retval;
}

/**
 * Compares the top-level property values between the two given objects.
 */
export function shallowCompare(obj1, obj2) {
  if (!obj1 || !obj2) {
    return obj1 == obj2;
  } // should both be null if one is null or undefined

  let keys1 = Object.keys(obj1),
    keys2 = Object.keys(obj2);

  return keys1.length === keys2.length && keys1.every(k => obj2.hasOwnProperty(k) && obj1[k] === obj2[k]);
}

/**
 * Splits/expands the string filter expression to an object with operator and values.
 *
 * @param filter - Standard Members filter expression.
 */
export function splitRangeFilter(filter) {
  let values = (filter || "").split("|");
  let operator = ["eq", "eg", "el", "gt", "lt", "bt"].indexOf(values[0]) + 1;
  if (!operator) return;
  return {
    operator,
    minValue: values[1],
    maxValue: values[2]
  };
}

export function splitRangeFilterDate(filter) {
  let split = splitRangeFilter(filter);
  if (!split) return split;
  if (split.minValue) {
    split.minValue = moment(split.minValue).format("YYYY-MM-DD");
  }
  if (split.maxValue) {
    split.maxValue = moment(split.maxValue).format("YYYY-MM-DD");
  }
  return split;
}

function ensureFunction(possibleFunction, message, fnDefault) {
  return $.isFunction(possibleFunction)
    ? possibleFunction
    : $.isFunction(fnDefault)
    ? fnDefault
    : function () {
        // Or maybe use a backup function that serializes all arguments to console as JSON if not circular.
        window.debugLog && message && debugLog(message);
      };
}

const chunkArray = (list, chunkSize) => {
  const chunks = [];

  for (let i = 0; i < list.length; i += chunkSize) {
    chunks.push(list.slice(i, i + chunkSize));
  }

  return chunks;
};

// =====

export default {
  asyncForEachSequential,
  asyncForEachParallel,
  ExpiringCache,
  ModuleStorage,
  CachingTransport,
  prettyTime,
  debounce,
  getOrderedJSON,
  ensureFunction,
  imgProfileUrl,
  imgOrgUrl,
  imgProfileUrlDirect,
  imgOrgUrlDirect,
  chunkArray,
  phone,
  contactNoImage,
  states: [
    "|",
    "AK|Alaska",
    "AL|Alabama",
    "AR|Arkansas",
    "AZ|Arizona",
    "CA|California",
    "CO|Colorado",
    "CT|Connecticut",
    "DC|District of Columbia",
    "DE|Delaware",
    "FL|Florida",
    "GA|Georgia",
    "HI|Hawaii",
    "IA|Iowa",
    "ID|Idaho",
    "IL|Illinois",
    "IN|Indiana",
    "KS|Kansas",
    "KY|Kentucky",
    "LA|Louisiana",
    "MA|Massachusetts",
    "MD|Maryland",
    "ME|Maine",
    "MI|Michigan",
    "MN|Minnesota",
    "MO|Missouri",
    "MS|Mississippi",
    "MT|Montana",
    "NC|North Carolina",
    "ND|North Dakota",
    "NE|Nebraska",
    "NH|New Hampshire",
    "NJ|New Jersey",
    "NM|New Mexico",
    "NV|Nevada",
    "NY|New York",
    "OH|Ohio",
    "OK|Oklahoma",
    "OR|Oregon",
    "PA|Pennsylvania",
    "RI|Rhode Island",
    "SC|South Carolina",
    "SD|South Dakota",
    "TN|Tennessee",
    "TX|Texas",
    "UT|Utah",
    "VA|Virginia",
    "VI|Virgin Islands",
    "VT|Vermont",
    "WA|Washington",
    "WI|Wisconsin",
    "WV|West Virginia",
    "WY|Wyoming"
  ],
  countries: [
    "",
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Anguilla",
    "Antarctica",
    "Antigua And Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Colombia",
    "Comoros",
    "Congo",
    "Congo, the Democratic Republic of the",
    "Cook Islands",
    "Costa Rica",
    "Cote dIvoire",
    "Croatia",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "East Timor",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "England",
    "Equatorial Guinea",
    "Eritrea",
    "Espana",
    "Estonia",
    "Ethiopia",
    "Falkland Islands",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Great Britain",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard and Mc Donald Islands",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea (South)",
    "Korea, Republic of",
    "Kuwait",
    "Kyrgyzstan",
    "Lao Peoples Democratic Republic",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macau",
    "Macedonia",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia, Federatd States of",
    "Moldova, Republic of",
    "Monaco",
    "Mongolia",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "Netherlands Antilles",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Ireland",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Reunion",
    "Romania",
    "Russia",
    "Russian Federation",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa (Independent)",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Scotland",
    "Senegal",
    "Serbia and Montenegro",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Korea",
    "Spain",
    "Sri Lanka",
    "St. Helena",
    "St. Pierre and Miquelon",
    "Suriname",
    "Svalbard and Jan Mayen Islands",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "United States Minor Outlying Islands",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City State (Holy See)",
    "Venezuela",
    "Viet Nam",
    "Virgin Islands (British)",
    "Virgin Islands (U.S.)",
    "Wales",
    "Wallis and Futuna Islands",
    "Western Sahara",
    "Yemen",
    "Yugoslavia",
    "Zambia",
    "Zimbabwe"
  ],
  countriesWithAbbr: [
    "|",
    "AF|Afghanistan",
    "AX|Åland Islands",
    "AL|Albania",
    "DZ|Algeria",
    "AS|American Samoa",
    "AD|Andorra",
    "AO|Angola",
    "AI|Anguilla",
    "AQ|Antarctica",
    "AG|Antigua and Barbuda",
    "AR|Argentina",
    "AM|Armenia",
    "AW|Aruba",
    "AU|Australia",
    "AT|Austria",
    "AZ|Azerbaijan",
    "BS|Bahamas",
    "BH|Bahrain",
    "BD|Bangladesh",
    "BB|Barbados",
    "BY|Belarus",
    "BE|Belgium",
    "BZ|Belize",
    "BJ|Benin",
    "BM|Bermuda",
    "BT|Bhutan",
    "BO|Bolivia, Plurinational State of",
    "BQ|Bonaire, Sint Eustatius and Saba",
    "BA|Bosnia and Herzegovina",
    "BW|Botswana",
    "BV|Bouvet Island",
    "BR|Brazil",
    "IO|British Indian Ocean Territory",
    "BN|Brunei Darussalam",
    "BG|Bulgaria",
    "BF|Burkina Faso",
    "BI|Burundi",
    "KH|Cambodia",
    "CM|Cameroon",
    "CA|Canada",
    "CV|Cape Verde",
    "KY|Cayman Islands",
    "CF|Central African Republic",
    "TD|Chad",
    "CL|Chile",
    "CN|China",
    "CX|Christmas Island",
    "CC|Cocos (Keeling) Islands",
    "CO|Colombia",
    "KM|Comoros",
    "CG|Congo",
    "CD|Congo, the Democratic Republic of the",
    "CK|Cook Islands",
    "CR|Costa Rica",
    "CI|Côte d'Ivoire",
    "HR|Croatia",
    "CU|Cuba",
    "CW|Curaçao",
    "CY|Cyprus",
    "CZ|Czech Republic",
    "DK|Denmark",
    "DJ|Djibouti",
    "DM|Dominica",
    "DO|Dominican Republic",
    "EC|Ecuador",
    "EG|Egypt",
    "SV|El Salvador",
    "GQ|Equatorial Guinea",
    "ER|Eritrea",
    "EE|Estonia",
    "ET|Ethiopia",
    "FK|Falkland Islands (Malvinas)",
    "FO|Faroe Islands",
    "FJ|Fiji",
    "FI|Finland",
    "FR|France",
    "GF|French Guiana",
    "PF|French Polynesia",
    "TF|French Southern Territories",
    "GA|Gabon",
    "GM|Gambia",
    "GE|Georgia",
    "DE|Germany",
    "GH|Ghana",
    "GI|Gibraltar",
    "GR|Greece",
    "GL|Greenland",
    "GD|Grenada",
    "GP|Guadeloupe",
    "GU|Guam",
    "GT|Guatemala",
    "GG|Guernsey",
    "GN|Guinea",
    "GW|Guinea-Bissau",
    "GY|Guyana",
    "HT|Haiti",
    "HM|Heard Island and McDonald Islands",
    "VA|Holy See (Vatican City State)",
    "HN|Honduras",
    "HK|Hong Kong",
    "HU|Hungary",
    "IS|Iceland",
    "IN|India",
    "ID|Indonesia",
    "IR|Iran, Islamic Republic of",
    "IQ|Iraq",
    "IE|Ireland",
    "IM|Isle of Man",
    "IL|Israel",
    "IT|Italy",
    "JM|Jamaica",
    "JP|Japan",
    "JE|Jersey",
    "JO|Jordan",
    "KZ|Kazakhstan",
    "KE|Kenya",
    "KI|Kiribati",
    "KP|Korea, Democratic People's Republic of",
    "KR|Korea, Republic of",
    "KW|Kuwait",
    "KG|Kyrgyzstan",
    "LA|Lao People's Democratic Republic",
    "LV|Latvia",
    "LB|Lebanon",
    "LS|Lesotho",
    "LR|Liberia",
    "LY|Libya",
    "LI|Liechtenstein",
    "LT|Lithuania",
    "LU|Luxembourg",
    "MO|Macao",
    "MK|Macedonia, the Former Yugoslav Republic of",
    "MG|Madagascar",
    "MW|Malawi",
    "MY|Malaysia",
    "MV|Maldives",
    "ML|Mali",
    "MT|Malta",
    "MH|Marshall Islands",
    "MQ|Martinique",
    "MR|Mauritania",
    "MU|Mauritius",
    "YT|Mayotte",
    "MX|Mexico",
    "FM|Micronesia, Federated States of",
    "MD|Moldova, Republic of",
    "MC|Monaco",
    "MN|Mongolia",
    "ME|Montenegro",
    "MS|Montserrat",
    "MA|Morocco",
    "MZ|Mozambique",
    "MM|Myanmar",
    "NA|Namibia",
    "NR|Nauru",
    "NP|Nepal",
    "NL|Netherlands",
    "NC|New Caledonia",
    "NZ|New Zealand",
    "NI|Nicaragua",
    "NE|Niger",
    "NG|Nigeria",
    "NU|Niue",
    "NF|Norfolk Island",
    "MP|Northern Mariana Islands",
    "NO|Norway",
    "OM|Oman",
    "PK|Pakistan",
    "PW|Palau",
    "PS|Palestine, State of",
    "PA|Panama",
    "PG|Papua New Guinea",
    "PY|Paraguay",
    "PE|Peru",
    "PH|Philippines",
    "PN|Pitcairn",
    "PL|Poland",
    "PT|Portugal",
    "PR|Puerto Rico",
    "QA|Qatar",
    "RE|RÃ©union",
    "RO|Romania",
    "RU|Russian Federation",
    "RW|Rwanda",
    "BL|Saint BarthÃ©lemy",
    "SH|Saint Helena, Ascension and Tristan da Cunha",
    "KN|Saint Kitts and Nevis",
    "LC|Saint Lucia",
    "MF|Saint Martin (French part)",
    "PM|Saint Pierre and Miquelon",
    "VC|Saint Vincent and the Grenadines",
    "WS|Samoa",
    "SM|San Marino",
    "ST|Sao Tome and Principe",
    "SA|Saudi Arabia",
    "SN|Senegal",
    "RS|Serbia",
    "SC|Seychelles",
    "SL|Sierra Leone",
    "SG|Singapore",
    "SX|Sint Maarten (Dutch part)",
    "SK|Slovakia",
    "SI|Slovenia",
    "SB|Solomon Islands",
    "SO|Somalia",
    "ZA|South Africa",
    "GS|South Georgia and the South Sandwich Islands",
    "SS|South Sudan",
    "ES|Spain",
    "LK|Sri Lanka",
    "SD|Sudan",
    "SR|Suriname",
    "SJ|Svalbard and Jan Mayen",
    "SZ|Swaziland",
    "SE|Sweden",
    "CH|Switzerland",
    "SY|Syrian Arab Republic",
    "TW|Taiwan, Province of China",
    "TJ|Tajikistan",
    "TZ|Tanzania, United Republic of",
    "TH|Thailand",
    "TL|Timor-Leste",
    "TG|Togo",
    "TK|Tokelau",
    "TO|Tonga",
    "TT|Trinidad and Tobago",
    "TN|Tunisia",
    "TR|Turkey",
    "TM|Turkmenistan",
    "TC|Turks and Caicos Islands",
    "TV|Tuvalu",
    "UG|Uganda",
    "UA|Ukraine",
    "AE|United Arab Emirates",
    "GB|United Kingdom",
    "US|United States",
    "UM|United States Minor Outlying Islands",
    "UY|Uruguay",
    "UZ|Uzbekistan",
    "VU|Vanuatu",
    "VE|Venezuela, Bolivarian Republic of",
    "VN|Viet Nam",
    "VG|Virgin Islands, British",
    "VI|Virgin Islands, U.S.",
    "WF|Wallis and Futuna",
    "EH|Western Sahara",
    "YE|Yemen",
    "ZM|Zambia",
    "ZW|Zimbabwe"
  ],
  creditCardTypes: ["|Select...", "27|Amex", "28|Discover", "26|Mastercard", "25|Visa"],
  months: [
    "|",
    "January|1",
    "February|2",
    "March|3",
    "April|4",
    "May|5",
    "June|6",
    "July|7",
    "August|8",
    "September|9",
    "October|10",
    "November|11",
    "December|12"
  ]
};

export { asyncForEachSequential };
export { asyncForEachParallel };
export { ExpiringCache };
export { ModuleStorage };
export { CachingTransport };
export { prettyTime };
export { debounce };
export { getOrderedJSON };
export { imgProfileUrl };
export { imgOrgUrl };
export { imgProfileUrlDirect };
export { imgOrgUrlDirect };
export { ensureFunction };
export const states = [
  "|",
  "AK|Alaska",
  "AL|Alabama",
  "AR|Arkansas",
  "AZ|Arizona",
  "CA|California",
  "CO|Colorado",
  "CT|Connecticut",
  "DC|District of Columbia",
  "DE|Delaware",
  "FL|Florida",
  "GA|Georgia",
  "HI|Hawaii",
  "IA|Iowa",
  "ID|Idaho",
  "IL|Illinois",
  "IN|Indiana",
  "KS|Kansas",
  "KY|Kentucky",
  "LA|Louisiana",
  "MA|Massachusetts",
  "MD|Maryland",
  "ME|Maine",
  "MI|Michigan",
  "MN|Minnesota",
  "MO|Missouri",
  "MS|Mississippi",
  "MT|Montana",
  "NC|North Carolina",
  "ND|North Dakota",
  "NE|Nebraska",
  "NH|New Hampshire",
  "NJ|New Jersey",
  "NM|New Mexico",
  "NV|Nevada",
  "NY|New York",
  "OH|Ohio",
  "OK|Oklahoma",
  "OR|Oregon",
  "PA|Pennsylvania",
  "RI|Rhode Island",
  "SC|South Carolina",
  "SD|South Dakota",
  "TN|Tennessee",
  "TX|Texas",
  "UT|Utah",
  "VA|Virginia",
  "VI|Virgin Islands",
  "VT|Vermont",
  "WA|Washington",
  "WI|Wisconsin",
  "WV|West Virginia",
  "WY|Wyoming"
];
export const countries = [
  "",
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Anguilla",
  "Antarctica",
  "Antigua And Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, the Democratic Republic of the",
  "Cook Islands",
  "Costa Rica",
  "Cote dIvoire",
  "Croatia",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "England",
  "Equatorial Guinea",
  "Eritrea",
  "Espana",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Great Britain",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard and Mc Donald Islands",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (South)",
  "Korea, Republic of",
  "Kuwait",
  "Kyrgyzstan",
  "Lao Peoples Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federatd States of",
  "Moldova, Republic of",
  "Monaco",
  "Mongolia",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Ireland",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russia",
  "Russian Federation",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa (Independent)",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Scotland",
  "Senegal",
  "Serbia and Montenegro",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "Spain",
  "Sri Lanka",
  "St. Helena",
  "St. Pierre and Miquelon",
  "Suriname",
  "Svalbard and Jan Mayen Islands",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City State (Holy See)",
  "Venezuela",
  "Viet Nam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wales",
  "Wallis and Futuna Islands",
  "Western Sahara",
  "Yemen",
  "Yugoslavia",
  "Zambia",
  "Zimbabwe"
];
export const creditCardTypes = ["|Select...", "27|Amex", "28|Discover", "26|Mastercard", "25|Visa"];
export const months = [
  "|",
  "January|1",
  "February|2",
  "March|3",
  "April|4",
  "May|5",
  "June|6",
  "July|7",
  "August|8",
  "September|9",
  "October|10",
  "November|11",
  "December|12"
];

export const isUSAddress = country => (!!country ? ["", "USA", "US", "UNITED STATES"].includes(country.toUpperCase()) : false);

export const statesParsed = states.map(value => {
  const parts = value.split("|");
  return new Option(parts[1], parts[0]);
});

export const geocodeService = () =>
  new GeocodeService({
    basePath: app_geoServicesUrl
  });

export const truncateInput = (input, length) => (input ? input.substr(0, length) : input);
