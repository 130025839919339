import authenticationService from "app/security/authenticationService";
import { SendUserSessionMessage, getUserSessionChannel, UserSessionMessage } from "./broadcastChannel";
import { userSessionStore } from "./store";
import { CRSessionMonitor } from "app/util/CRSessionMonitor";
import user from "app/util/user";

/**
 * Normal logout process in which it is communicated to other browser tabs that the user session ended
 *
 * @returns Promise<void>
 */
export const logout = async () => {
  // SSO logout proccess and invalidate user
  await userSessionStore.logout(async () => {
    // send notification to others tabs
    await dispatchExternalLogout();
    // close channel
    getUserSessionChannel().close();
  });
};

export const dispatchExternalLogout = async () => SendUserSessionMessage({ message: UserSessionMessage.Logout });

export const dispatchExternalLogin = async () => {
  await SendUserSessionMessage({ message: UserSessionMessage.Login, userId: user?.id });
  await SendUserSessionMessage({ message: UserSessionMessage.RefreshSession, newValue: false });
}

export const dispatchUserInteraction = async () => SendUserSessionMessage({ message: UserSessionMessage.UserInteraction });

export const staySignIn = async () => {
  await SendUserSessionMessage({ message: UserSessionMessage.InactivityWarning, newValue: false });
  await dispatchUserInteraction();
  userSessionStore.setInactivityWarning(false);
  var sessionMonitor = window["cr_session_monitor"] as CRSessionMonitor;

  if(sessionMonitor) {
    sessionMonitor.refresh_session();
  }

};

export const refreshSession = async () => {
  await userSessionStore.login();
  if (!user.isAuthenticated)  {
    SendUserSessionMessage({ message: UserSessionMessage.RefreshSession, newValue: false });
  }
};

/**
 * Inactivity of a user in the session in which other browser tabs are notified of this inactivity
 *
 * @param message UserSessionMessage
 */
export const inactivityDetection = async (message: UserSessionMessage) => {
  switch (message) {
    case UserSessionMessage.Logout:
      //the maximum time without user activity was met
      const userDomain = userSessionStore?.currentSSOUser?.email?.split("@")[1] ?? "";
      const shouldTimeOut = (app_domains_to_timeout === ".*") || RegExp(app_domains_to_timeout, "i").test(userDomain);
      
      if(shouldTimeOut) {
        userSessionStore.setSessionTimedOut(true);
      } else {
        await logout();
      }
      break;

    case UserSessionMessage.InactivityWarning:
      //a few seconds remain for the maximum time without user activity to be reached.
      await SendUserSessionMessage({ message, newValue: true });
      userSessionStore.setInactivityWarning(true);
      break;

    case UserSessionMessage.RefreshCRSDCookie:
      try {
        await authenticationService.refreshCRSDCookie();
      } catch (e) {
        // if the refresh fails it means that we were already logged out during document.visibilityState !== "visible" or browser freeze.
        await logout();
      }

      break;

    default:
      await dispatchUserInteraction();
      break;
  }
};
