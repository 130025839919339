import domUtil from "app/util/domUtil";
import GeoService from "app/util/geolocation";
import ResponsiveUtil from "app/util/responsiveUtil";
import ApiTransport, { ClientSource, ClientSourceHeader, CSRFTokenHeader, getCSRFToken } from "./apiTransport";

var debug = {
  callText: app_isDebug && deserializeLogCallText(getFromLocalStorage("logCallText")),
  serverExceptions: app_isDebug && getFromLocalStorage("logServerExceptions") === "true",
  loadAppNotifications: app_isDebug && getFromLocalStorage("logLoadAppNotifications") === "true"
};

// Get from local storage, suppressing errors
function getFromLocalStorage(key) {
  try {
    if (typeof localStorage !== "undefined") {
      return localStorage[key];
    }
  } catch (e) {}
  return undefined;
}
function serializeLogCallText() {
  var str;

  switch (typeof debug.callText) {
    case "string":
      str = "string:" + debug.callText;
      break;
    case "boolean":
      str = String(debug.callText);
      break;
    default:
      if (debug.callText instanceof RegExp) {
        str = "RegExp:" + debug.callText.toString();
      } else {
        str = String(debug.callText);
      }
      break;
  }
  return str;
}
function deserializeLogCallText(str) {
  var rv, parts;

  if (str === "true") {
    rv = true;
  } else if (str === "false" || !str) {
    rv = false;
  } else if (str.substring(0, 7) === "string:") {
    rv = str.substring(7);
  } else if ((parts = str.match(/^RegExp:\/(.*)\/([gmi]*)$/)) !== null) {
    rv = new RegExp(parts[1], parts[2] || "");
  } else {
    rv = str;
  }
  return rv;
}

// We only really expect to have one instance, so we use the module pattern and contain
// everything within the constructor function.
function CRTransport() {
  var $ = jQuery, // TEMPORARY: In this module, $ = jQuery
    inst = this, // Our public instance
    data = {
      // Our private data
      transportURL: cr_appConfig.transportURL,
      requests: []
    };

  inst.allRequestsSuccess = true;
  $(document).ajaxComplete(function (event, request, settings) {
    // If we have had a failed request then keep it that way
    if (!inst.allRequestsSuccess) return;

    // Check for non 200 success code
    if (request.status != 200) {
      inst.allRequestsSuccess = false;
      return;
    }

    // Check for success is false
    if (request.responseText.indexOf('"success":false') > 0) {
      inst.allRequestsSuccess = false;
      return;
    }
  });

  inst.requestsPending = CRTransport_RequestsPending;
  function CRTransport_RequestsPending() {
    return jQuery.active !== 0;
  }

  // Public: Get our transport URL
  inst.getTransportURL = CRTransport_getTransportURL;
  function CRTransport_getTransportURL() {
    return data.transportURL;
  }

  inst.track = CRTransport_track;
  /**
   * Will append the given data to the request object to be processed by our feature tracking system.
   * @param {*} request Current request object.
   * @param {string} channel Name of channel/module to track against.
   * @param {string} action Name of action for channel/module to track against.
   * @param {number} metric Optional measure to log.
   */
  function CRTransport_track(request, channel, action, metric) {
    // Not entirely obvious.. but the idea here is to call this with your request object prior to sending any request to mid-tier API.
    // As you will note, this does NOT send a tracking request on its own.
    // It will pass the tracking on to the main API, which logs thru Kinesis and (as of writing) ends up in Redshift.

    var source = new ResponsiveUtil().deviceType === "desktop" ? "Desktop" : "Mobile";

    // to do: future implementation will get lat/long for when
    // the user accepts the browsers location info
    const position = GeoService.get();
    const { latitude = 0, longitude = 0 } = position ? position.coords : {};

    // possible addition could be to also track users browser
    // and other device information using browser agent string

    Object.assign(request, {
      _track: {
        channel: channel,
        action: action,
        source: source,
        latitude: latitude,
        longitude: longitude,
        metric: metric || 0
      }
    });
  }

  // Public: Transmit a request
  inst.successRequest = CRTransport_successRequest;
  function CRTransport_successRequest(channelId, action, objMessage, element, successCallback, failedCallback) {
    successCallback = successCallback || $.noop;

    var def = new $.Deferred();
    if (typeof element === "function") {
      failedCallback = successCallback;
      successCallback = element;
      element = null;
    }

    inst.transmitRequest(channelId, action, objMessage, element, callback);
    return def.promise();

    function callback(resp) {
      if (resp.success === true) {
        successCallback(resp);
        def.resolve(resp);
      } else {
        if (typeof failedCallback === "function") {
          failedCallback(resp);
        }
        def.reject(resp);
      }
    }
  }

  // Public: Transmit a request
  inst.transmitRequest = CRTransport_transmitRequest;
  function CRTransport_transmitRequest(channelId, action, objMessage, element, receiveCallback, isSync) {
    if (typeof element === "function") {
      //no element passed in - shift everything over
      isSync = receiveCallback;
      receiveCallback = element;
      element = null;
    }
    if (element) {
      if (!/^(a|button)$/i.test(element.tagName)) {
        element = $(element).closest("a,button")[0];
      }
      if (element) {
        domUtil.startSpinningButton(element);
      }
    }
    objMessage._utcOffsetMinutes = new Date().getTimezoneOffset();

    //dispose of finished requests
    while (data.requests.length > 0 && data.requests[0].isFinished()) {
      data.requests.shift();
    }

    stripNulls(objMessage);
    function stripNulls(obj) {
      for (var key in obj) {
        if (obj[key] === null) {
          delete obj[key];
        } else if (typeof obj[key] === "object") {
          stripNulls(obj[key]);
        }
      }
    }

    var requestEnvelope = new CREnvelope(channelId, action, objMessage, callback, data.requests);
    data.requests.push(requestEnvelope);

    var def = new $.Deferred();

    getHeaders().then(headers => {
      requestEnvelope.sendMessage(data.transportURL, isSync, headers);
    });

    return def.promise();

    function callback(resp) {
      if (element) {
        domUtil.stopSpinningButton(element);
      }

      if (typeof receiveCallback === "function") {
        receiveCallback(resp);
      }
      def.resolve(resp);
    }
  }

  // Enable/disable logging of sproc text, in debug mode
  // Arguments:
  //  flag:   If not given, toggles sproc logging
  //          If given:
  //              true =      turn on all sproc logging (except the
  //                          shared.loadappnotifications action, which is handled
  //                          via showLoadAppNotifications)
  //              false =     turn off all sproc logging
  //              (string) =  log calls that contain the string (case-insensitive)
  //                          anywhere in the call text
  //              (RegExp) =  log calls that match the given regular expression
  //  exflag: If given, turn server exceptions on or off (always coerced to a boolean)
  //          If not given, no change to server exceptions.
  // Returns: this, for chaining
  inst.showSprocCalls = CRTransport_showSprocCalls;
  function CRTransport_showSprocCalls(flag, exflag) {
    if (app_isDebug) {
      if (typeof flag === "undefined") {
        flag = !debug.callText;
      }
      debug.callText = flag;
      try {
        localStorage["logCallText"] = serializeLogCallText();
      } catch (e) {}
      if (typeof exflag !== "undefined") {
        this.showServerExceptions(exflag);
      }
    }
    return this;
  }

  // Enable/disable logging of server exceptions, in debug mode
  // Arguments:
  //  flag:   If not given, toggles logging server exceptions
  //          If given, coerced to boolean, where true turns on logging and false turns it off
  // Returns: this, for chaining
  inst.showServerExceptions = CRTransport_showServerExceptions;
  function CRTransport_showServerExceptions(flag) {
    return handleSimpleDebugFlag("serverExceptions", flag);
  }

  // Enable/disable logging shared.loadappnotifications when sproc loggging is turned on
  // Arguments:
  //  flag:   If not given, toggle; if given, truthy values enable, falsey values disable
  // Returns: this, for chaining
  inst.showLoadAppNotifications = CRTransport_showLoadAppNotifications;
  function CRTransport_showLoadAppNotifications(flag) {
    return handleSimpleDebugFlag("loadAppNotifications", flag);
  }

  // Handle showServerExceptions and showLoadAppNotifications
  // Arguments:
  //  prop:       The property to set ("serverExceptions", "loadAppNotifications")
  //  flag:       The flag to use; see showServerExceptions and showLoadAppNotifications
  // Returns:     this, for chaining
  function handleSimpleDebugFlag(prop, flag) {
    var storageKey;
    if (app_isDebug) {
      if (typeof flag === "undefined") {
        flag = !debug[prop];
      }
      debug[prop] = !!flag; // Force boolean
      try {
        // "xyz" => "logXyz", e.g., "logServerExceptions" or "logLoadAppNotifications"
        storageKey = "log" + prop.substring(0, 1).toUpperCase() + prop.substring(1);
        localStorage[storageKey] = String(debug[prop]);
      } catch (e) {}
    }
    return this;
  }

  // Enable/disable logging of sproc text and server exceptions, in debug mode
  // Arguments:
  //  flag:   (Optional, default true)
  //              For sproc logging, see the values defined for showSprocCalls
  //              For server exceptions, any truthy value logs them, falsey
  //              values turns them off.
  // Returns: this, for chaining
  inst.showDebugging = CRTransport_showDebugging;
  function CRTransport_showDebugging(flag) {
    if (app_isDebug) {
      if (typeof flag === "undefined") {
        flag = true;
      }
      this.showSprocCalls(flag);
      this.showServerExceptions(flag);
    }
    return this;
  }

  // Public: Abandon all outstanding requests
  inst.abandonAllRequests = CRTransport_abandonAllRequests;
  function CRTransport_abandonAllRequests() {
    for (var i = 0; i < data.requests.length; i++) {
      try {
        data.requests[i].abortMessage();
      } catch (ex) {}
    }
    data.requests.length = 0;
  }

  inst.api = new ApiTransport(debug);
}

const getHeaders = async () => {
  const csrfToken = await getCSRFToken();
  const headers = { [ClientSourceHeader]: ClientSource, [CSRFTokenHeader]: csrfToken };

  return headers;
};

// Envelope class. Because there will be a number of these, we use the prototype
// for sharing implementation -- but we also use a scoping function for utilities.

var CREnvelope = (function () {
  var pubs = CREnvelope.prototype;
  var pendingRefresh = false;

  // Create an envelope for the given request
  function CREnvelope(channelId, action, objMessage, receiveCallback, requestCollection) {
    this._channelId = channelId;
    this._action = action;
    this._callback = receiveCallback;
    this._isFinished = false;
    this._xhr = null;
    this._objMessage = objMessage;
    this._requests = requestCollection || [];

    this._postData = JSON.stringify(objMessage);
  }

  // Public: Send the message
  pubs.sendMessage = CREnvelope_sendMessage;
  function CREnvelope_sendMessage(transportURL, isSync, headers = {}) {
    var options;

    // Sends message to server and logs callback response
    transportURL += "?" + this._channelId + "." + this._action;
    options = {
      url: transportURL,
      type: "post",
      data: this._postData,
      contentType: "application/json; charset=UTF-8",
      dataType: "json",
      async: !isSync,
      context: this,
      headers,
      success: successHandler,
      error: errorHandler,
      complete: completeHandler
    };
    this._xhr = $.ajax(options);
  }

  // Public: Abort the message
  pubs.abortMessage = CREnvelope_abortMessage;
  function CREnvelope_abortMessage() {
    if (this._xhr && !this._isFinished) {
      this._xhr.abort();
      this._isFinished = true;
    }
  }

  // Private: Ajax completed handler
  function completeHandler(jqxhr, statusText) {
    // completed handler fires in Chrome even if request aborted or disconnected, leaving responseText unparsable
    // no reason to continue, status 0 represents an incomplete XHR
    if (jqxhr.status === 0) {
      return;
    }

    const responseMessage = JSON.parse(jqxhr.responseText);

    // Show the sproc calls, if desired
    if (app_isDebug && debug.callText && typeof responseMessage.querytime !== "undefined" && typeof console !== "undefined") {
      logSprocCalls(this._channelId, this._action, responseMessage);
    }
  }
  function logSprocCalls(channelId, action, responseMessage) {
    var callText,
      callTime,
      shouldLog,
      callLogging = [],
      logThis,
      result,
      blockLoadAppNotifications,
      n;

    if (channelId === "shared" && action === "loadappnotifications" && !debug.loadAppNotifications) {
      return;
    }

    if (debug.callText instanceof RegExp) {
      shouldLog = function (text) {
        return text.match(debug.callText);
      };
    } else if (typeof debug.callText === "string") {
      shouldLog = function (text) {
        return text.toLowerCase().indexOf(debug.callText.toLowerCase()) !== -1;
      };
    } else {
      shouldLog = function () {
        return true;
      };
    }

    callLogging = [];
    n = 0;
    while ((callText = responseMessage["querytext" + n])) {
      if (shouldLog(callText)) {
        callTime = responseMessage["querytime" + n];
        callLogging.push({
          logWith: callTime == "-1" ? "error" : "log",
          time: callTime == "-1" ? "unknown" : callTime,
          text: callText.replace(/[\r\n\s]+$/, "")
        });
      }
      ++n;
    }
    if (callLogging.length) {
      // tslint:disable-next-line:no-console
      console.log("=== " + channelId + "." + action + " (total sproc time: " + responseMessage.querytime + ")");
      callLogging.forEach(function (entry) {
        // Sadly if we try to combine these into one call, IE11's dev tools shows them unusefully
        console[entry.logWith]("-- Call time: " + entry.time);
        console[entry.logWith](entry.text);
      });
      result = $.extend(true, {}, responseMessage);
      for (n in result) {
        switch (n.substring(0, 9)) {
          case "querytext":
          case "querytime":
            delete result[n];
            break;
        }
      }
      // Sadly if we try to combine these into one call, IE11's dev tools shows them unusefully
      if (result.success === false && result.exception) {
        if (debug.serverExceptions) {
          console.error("Result:");
          console.error(result.exception);
        } else {
          console.error("Result:");
          console.error(result);
        }
      } else {
        console.warn("Result:");
        console.warn(result);
      }
    }
  }

  // Private: Ajax success handler
  function successHandler(response) {
    if (!response) {
      response = {
        result: "invalid",
        message: "The server response was not correctly formed."
      };
    }

    this._responseMessage = response;

    //  handle any error messages that come down from server
    if (response && response.result && response.result === "error") {
      if (response.errorcode === "noaccess") {
        domUtil.displayMessage(response.message, { isError: true });
      }
    } else {
      if (this._callback) {
        //                    try {
        this._callback(response);
        //} catch (ex) {
        //    if (console && console.error && typeof console.error === 'function') {
        //        throw ex;
        //    }
        //}
      }
    }

    this._isFinished = true;

    var numWorking = Linq.From(this._requests).Count(function (r) {
      return !r.isFinished();
    });
  }

  // Private: Ajax error handler
  function errorHandler(jqxhr, statusText, err) {
    //send response (fail) //soap error; cannot contact server, etc.
    var responseMessage = {
      result: "fail",
      message: "There was a communication or server error.",
      responseJSON: jqxhr.responseJSON,
      status: jqxhr.status,
      statusText: jqxhr.statusText
    };

    if (this._callback) {
      //              try {
      this._callback(responseMessage);
      //              } catch (ex) { }
    }
    this._isFinished = true;
  }

  // Public: Get a flag for whether this request is finished
  pubs.isFinished = CREnvelope_isFinished;
  function CREnvelope_isFinished() {
    return this._isFinished;
  }

  // Return the constructor
  return CREnvelope;
})();

export default new CRTransport();

export const legacyPacket = {
  CRTransport: CRTransport,
  CREnvelope: CREnvelope
};
