import { Moment } from "moment";

// the point of this is a bit of forward thinking to when we get more rigorous about scheduling/location-specific info for the current user and/or user's org
// currently the defaults are used, but in future, we can make them configurable by user org/location
// a bit of future-proofing here, but I think it's worthwhile

const DEFAULT_START_HOUR = 8; // 8a
const DEFAULT_END_HOUR = 17; // 5p
const DEFAULT_WORKWEEK_START_DAY = 1; // ISO Mon
const DEFAULT_WORKWEEK_END_DAY = 5; // ISO Fri

/** Use for time/location-specific for the user.  */
export class UserLocationInfo {
  /** TBD. Get current IANA timezone identifier. */
  get currentTimezone(): string {
    throw new Error("Not Implemented");
  }

  /** Gets current offset from UTC in minutes. */
  get currentUtcOffset(): number {
    return moment().utcOffset();
  }

  /** TBD. User's configured preferred IANA timezone identifier. */
  get preferredTimezone(): string {
    throw new Error("Not Implemented");
  }

  /** TBD. User's configured preferred offset (based on preferred TZ). */
  get preferredUtcOffset(): number {
    throw new Error("Not Implemented"); // calculate based on preferred TZ
  }

  /** Start of business for the user's location, based on 24-hour scale. */
  get startOfBusinessHour(): number {
    return DEFAULT_START_HOUR;
  }

  /** End of business for the user's location, based on 24-hour scale. */
  get endOfBusinessHour(): number {
    return DEFAULT_END_HOUR;
  }

  /** Start of week day for the user's location, based on ISO weekday (1=Mon;7=Sun). */
  get startOfBusinessWeekday(): number {
    return DEFAULT_WORKWEEK_START_DAY;
  }

  /** End of week day for the user's location, based on ISO weekday (1=Mon;7=Sun). */
  get endOfBusinessWeekday(): number {
    return DEFAULT_WORKWEEK_END_DAY;
  }

  /** Whether or not it is currently after close of business for the user's location. */
  get isAfterEOB(): boolean {
    return moment().hour() > this.endOfBusinessHour;
  }

  /** Time when business starts on the current business day for the user's location. */
  get businessDayStart(): Moment {
    return this.nextWorkDay(
      moment()
        .startOf("day")
        .add(this.startOfBusinessHour, "hours")
    );
  }

  /** Time when business ends on the current business day for the user's location. */
  get businessDayEnd(): Moment {
    return this.nextWorkDay(
      moment()
        .startOf("day")
        .add(this.endOfBusinessHour, "hours")
    );
  }

  /** Time when business starts on the next business day for the user's location. */
  get nextBusinessDayStart(): Moment {
    return this.nextWorkDay(
      moment()
        .startOf("day")
        .add(1, "days")
        .add(this.startOfBusinessHour, "hours")
    );
  }

  /** Time when business ends on the next business day for the user's location. */
  get nextBusinessDayEnd(): Moment {
    return this.nextWorkDay(
      moment()
        .startOf("day")
        .add(1, "days")
        .add(this.endOfBusinessHour, "hours")
    );
  }

  /** Start of work week (day/hour) for user's location. */
  get startOfWorkWeek(): Moment {
    return moment()
      .isoWeekday(this.startOfBusinessWeekday)
      .startOf("day")
      .add(this.startOfBusinessHour, "hours");
  }

  /** End of work week (day/hour) for user's location. */
  get endOfWorkWeek(): Moment {
    return moment()
      .isoWeekday(this.endOfBusinessWeekday)
      .startOf("day")
      .add(this.endOfBusinessHour, "hours");
  }

  /** Default due date for user's location. */
  get defaultDueDate(): Moment {
    // in theory, this could be independently configurable, but probably the algo below is good enough in all cases
    // but we could let users/orgs supply some basic relative formula..
    return this.isAfterEOB ? this.nextBusinessDayEnd : this.businessDayEnd;
  }

  /**
   * Calculates the next work day for the user's location after the given date. If given date is a work day, it will be returned.
   */
  nextWorkDay = (dateTime: Moment) => {
    // This is "dumb" in that we're not accounting for holidays or scheduled PTO.
    // At some point, we probably should make this org/person-specific data available
    // to make a better version of this and reusable throughout..
    return dateTime.isoWeekday() <= this.endOfBusinessWeekday ? dateTime : this.nextWorkDay(dateTime.add(1, "days"));
  };

  previousWorkDay = (dateTime: Moment) => {
    // This is "dumb", like nextWorkDay
    return dateTime.isoWeekday() <= this.endOfBusinessWeekday ? dateTime : this.previousWorkDay(dateTime.add(-1, "days"));
  };

  // ... add more location info, maybe office address, etc.
}
