import { ThemeProvider } from "@cr/material-ui-theme-provider";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

interface CountDownProps {
  secondsStart: number;
}

interface UserInactivityDialogProps {
  millisecondsLeft: number;
  visible: boolean;
  onClose: () => void;
}

export const Countdown = ({ secondsStart }: CountDownProps) => {
  const [secondsLeft, setSecondsLeft] = useState(secondsStart);

  useEffect(
    () => {
      setSecondsLeft(secondsStart);

      const intervalId = setInterval(() => {
        setSecondsLeft(current => current - 1);
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    },
    [secondsStart]
  );

  return <>{Math.max(secondsLeft, 0)}</>;
};

export const UserInactivityDialog = observer(({ visible, millisecondsLeft, onClose }: UserInactivityDialogProps) => {
  const secondsStart = millisecondsLeft / 1000;

  return (
    <ThemeProvider skipGlobalStyles>
      <Dialog open={visible} onClose={onClose} disableBackdropClick data-testid="userInactivityDialog">
        <DialogTitle>Session expiration warning!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            For security reasons you will be signed out in <strong>{visible ? <Countdown {...{ secondsStart }} /> : secondsStart}</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={onClose} data-testid="staySigned">
            Stay signed in
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
});
