import React, { Component } from "react";
import { observer } from "mobx-react";

/**
 *  THESE ARE DEPRECATED
 * Use app/components/forms/editors instead. If you find existing code using these, please update. Once we elminate all these, let's remove.
 */

interface BoundControlProps {
  model: any;
  name: string;
}

@observer
export class ValidatedControl extends Component<BoundControlProps, {}> {
  render() {
    let { model, name, children } = this.props,
      child = React.Children.only(children) as React.ReactElement<any>,
      { valid, message } = model.checkValidationForProperty(name),
      { className = "" } = child.props;

    return React.cloneElement(child, { className: className + (!valid ? " error" : "") });
  }
}

type ValidationMessageProps = {
  className?: string;
} & BoundControlProps;

@observer
export class ValidationMessage extends Component<ValidationMessageProps, {}> {
  render() {
    let { model, name, className = "", children, ...rest } = this.props,
      { valid, message, showMessage } = model.checkValidationForProperty(name),
      Child = (React.Children.count(children) === 1 ? React.Children.only(children) : null) as React.ReactElement<any>;

    return !valid && showMessage ? (
      Child ? (
        React.cloneElement(Child, { ...(Child.props || {}) }, message) //faithfully use the child they passed in
      ) : (
        <span {...rest} className={className + " error"}>
          {message}
        </span>
      )
    ) : null; //use our own default
  }
}

interface ValidationSummaryProps {
  model: any;
  properties: string[];
}

@observer
export class ValidationSummary extends Component<ValidationSummaryProps, {}> {
  static defaultValidationSummaryContainer = <div />;
  render() {
    let { model, properties, children, ...rest } = this.props,
      count = React.Children.count(children),
      passedContainer = (count === 1 ? React.Children.only(children) : null) as React.ReactElement<any>;

    return properties.some(name => model.propertyNeedsValidationMessage(name))
      ? //if user passes in their own container, we pass nothing through to it - user will style it as they choose
        React.cloneElement(
          passedContainer || ValidationSummary.defaultValidationSummaryContainer,
          passedContainer ? {} : { ...rest },
          properties.map(name => (
            <ValidationMessage {...{ model, name }}>{passedContainer ? passedContainer.props.children : <div className="error" />}</ValidationMessage>
          ))
        )
      : null;
  }
}

type ValidatedInputProps = BoundControlProps & React.HTMLAttributes<HTMLInputElement>;

@observer
export class ValidatedInput extends Component<ValidatedInputProps, {}> {
  boundInput: BoundInput;

  render() {
    let { model, name, ...rest } = this.props;

    return (
      <div>
        <ValidatedControl model={model} name={name}>
          <BoundInput {...rest} model={model} name={name} ref={el => (this.boundInput = el)} />
        </ValidatedControl>
        <ValidationMessage model={model} name={name} />
      </div>
    );
  }
}

type ValidatedSelectProps = BoundControlProps & React.HTMLAttributes<HTMLSelectElement>;

@observer
export class ValidatedSelect extends Component<ValidatedSelectProps, {}> {
  render() {
    let { model, name, children, ...rest } = this.props;

    return (
      <div>
        <ValidatedControl model={model} name={name}>
          <BoundSelect {...rest} model={model} name={name}>
            {children}
          </BoundSelect>
        </ValidatedControl>
        <ValidationMessage model={model} name={name} />
      </div>
    );
  }
}

@observer
export class BoundInput extends Component<BoundControlProps & React.HTMLAttributes<HTMLInputElement>, {}> {
  input: HTMLElement;

  boundChange: React.EventHandler<React.FormEvent<HTMLInputElement>> = evt => {
    const { model, name, onChange } = this.props;
    model[name] = evt.currentTarget.value;
    if (typeof onChange == "function") {
      onChange(evt);
    }
  };

  render() {
    let { model, name, ...rest } = this.props;

    return <input {...rest} ref={el => (this.input = el)} value={model[name] != null ? model[name] : ""} onChange={this.boundChange} />;
  }
}

type BoundSelectProps = BoundControlProps & React.HTMLAttributes<HTMLSelectElement>;

@observer
export class BoundSelect extends Component<BoundSelectProps, {}> {
  boundChange: React.EventHandler<React.FormEvent<HTMLSelectElement>> = evt => {
    const { model, name, onChange } = this.props;
    let value = evt.currentTarget.value;
    if (`${name}_set` in model) {
      model[`${name}_set`](value);
    } else if (`set_${name}` in model) {
      model[`set_${name}`](value);
    } else {
      model[name] = value;
    }
    if (typeof onChange == "function") {
      onChange(evt);
    }
  };

  render() {
    let { model, name, children, onChange, ...rest } = this.props;

    return (
      <select {...rest} value={model[name] || ""} onChange={this.boundChange}>
        {children}
      </select>
    );
  }
}

type BoundCheckboxProps = BoundControlProps & React.HTMLAttributes<HTMLInputElement>;

@observer
export class BoundCheckbox extends Component<BoundCheckboxProps, {}> {
  boundChange = evt => {
    const { model, name } = this.props;
    model[name] = evt.target.checked;
  };

  render() {
    let { model, name, children, ...rest } = this.props;

    return <input type="checkbox" {...rest} checked={!!model[name]} onChange={this.boundChange} />;
  }
}

type BoundRadioButtonProps = BoundControlProps & React.HTMLProps<HTMLInputElement>;

@observer
export class BoundRadioButton extends Component<BoundRadioButtonProps, {}> {
  boundChange = evt => {
    const { model, name } = this.props;
    model[name] = evt.target.value;
  };

  render() {
    let { model, name, children, value, ...rest } = this.props;

    return <input type="radio" {...rest} checked={model[name] == value} value={value} onChange={this.boundChange} />;
  }
}

type ValidatedTextAreaProps = BoundControlProps & React.HTMLAttributes<HTMLTextAreaElement>;

@observer
export class ValidatedTextArea extends Component<ValidatedTextAreaProps, {}> {
  render() {
    let { model, name, ...rest } = this.props;

    return (
      <div>
        <ValidatedControl model={model} name={name}>
          <BoundTextArea {...rest} model={model} name={name} />
        </ValidatedControl>
        <ValidationMessage model={model} name={name} />
      </div>
    );
  }
}

type BoundTextAreaProps = BoundControlProps & React.HTMLAttributes<HTMLTextAreaElement>;

@observer
export class BoundTextArea extends Component<BoundTextAreaProps, {}> {
  boundChange = evt => {
    const { model, name } = this.props;
    model[name] = evt.target.value;
  };

  render() {
    let { model, name, children, ...rest } = this.props;

    return <textarea value={model[name]} {...rest} onChange={this.boundChange} />;
  }
}
