import history from "@cr/core/history";
import { Logger } from "@cr/core/logger";
import { isAuthRoute } from "app/util/historyUtils";
import transport from "app/util/transport";
import "url-search-params-polyfill";
import { Credentials, ImpersonationCredentials, SwitchAccountCredentials, TokenCredentials, UserCredentials } from "./model";

const log = Logger.create("AuthenticationService");

export class AuthenticationService {
  subdomain = window.app_subdomain;

  authenticate = (credentials: Credentials): Promise<void> =>
    new Promise((resolve, reject) => transport.successRequest("framework", "login", credentials, resolve, reject));

  authorizeToken = (credentials: TokenCredentials): Promise<boolean> =>
    new Promise((resolve, reject) => {
      log.debug("Authenticating token %o...", credentials);

      transport.successRequest(
        "framework",
        "authtoken",
        credentials,
        () => resolve(true),
        err => {
          log.warn("Error authenticating token", err);

          // this isn't really an exception - it's just a failed authorization
          if (err && err.error === "Unauthorized") {
            resolve(false);
          }
          // otherwise it is a real exception
          else {
            reject(err);
          }
        }
      );
    });

  refreshCRSDCookie = (): Promise<boolean> =>
    new Promise((resolve, reject) =>
      transport.transmitRequest("framework", "keepalive", {}, response => {
        // only reject on an explicit failure (will cause the user to be logged out)
        if (response.success === false) {
          reject(response);
          // resolve on a success, don't reject on connectivity or server issues
        } else {
          resolve(!!response.success);
        }
      })
    );

  getImpersonationCredentials(impersonationToken): ImpersonationCredentials {
    return {
      ...this.getUserCredentials("_", "_"),
      x: impersonationToken
    };
  }

  getSwitchAccountCredentials(contactId: number, switchto: string): SwitchAccountCredentials {
    return {
      subdomain: this.subdomain,
      switchto,
      loggedInAs: contactId
    };
  }

  getUserCredentials(username, password): UserCredentials;
  getUserCredentials(
    username,
    password,
    switchAccountCredentials: SwitchAccountCredentials
  ): UserCredentials & SwitchAccountCredentials;

  getUserCredentials(
    username,
    password,
    switchAccountCredentials?: SwitchAccountCredentials
  ): UserCredentials & SwitchAccountCredentials {
    return Object.assign(
      {
        subdomain: this.subdomain,
        username,
        password
      },
      switchAccountCredentials
    );
  }

  getUrlWithRedirectParameter(base: string, defaultToCurrentLocation: boolean): string {
    let redir = this.getCurrentRedirectPath(defaultToCurrentLocation);

    return base + (redir ? `?redir=${encodeURIComponent(redir)}` : "");
  }

  getCurrentRedirectPath(defaultToCurrentPage: boolean): string {
    // Check URL parameters for redir value
    let params = new URLSearchParams(history.location.search);
    let redir = (params && params.get("redir")) || "";
    if (isAuthRoute(redir)) {
      redir = "";
    }

    // As fallback, take actual current location (if requested...)
    let currLocation = defaultToCurrentPage ? window.location.hash.replace("#", "") : "";
    if (isAuthRoute(currLocation)) {
      currLocation = "";
    }

    // Either there's a valid redirect parameter, or we're taking currLocation (which is blank if defaultToCurrentPage was false)
    const redirectPath = redir || currLocation;
    log.debug("Resolved redirect location to: %s", redirectPath);

    return redirectPath;
  }

  /**
   *Remove cookies CRUD and CRSD
   *
   * @return {*}  {Promise<void>}
   * @memberof AuthenticationService
   */
  removeCookies(): Promise<void> {
    return new Promise(resolve => transport.transmitRequest("framework", "logout", {}, resolve, resolve));
  }
}

const authenticationService = new AuthenticationService();
export default authenticationService;
