function initializeCRFramework(): void {
  window.cr = require("framework/cr");
  require("widgets/cr-select");
  require("widgets/cr-select2");
  require("widgets/cr-advanced-search");
  require("widgets/cr-labelWidget");
  require("widgets/cr-daterange");
  require("widgets/cr-symbol");
  require("widgets/nestedLabelsWidgets");
  require("framework/globalUtils/labelUtilities");
  require("widgets/cr-multi-select");
}

export default initializeCRFramework;
