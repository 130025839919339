import history, { Location } from "@cr/core/history";
import { observable, computed, reaction, runInAction } from "mobx";

class HistoryListenerStore {
  private _started: boolean = false;
  @observable private _currentLocation: Location = null;
  @observable private _previousLocation: Location = null;

  start() {
    if (!this._started) {
      this._started = true;

      reaction(
        () => history.location,
        newLocation => {
          runInAction(() => {
            this._previousLocation = this._currentLocation;
            this._currentLocation = newLocation;
          });
        },
        { fireImmediately: true }
      );
    }
  }

  @computed
  get previousLocation() {
    return this._previousLocation;
  }

  @computed
  get currentLocation() {
    return this._currentLocation;
  }
}

export const store = new HistoryListenerStore();

export const initializeHistoryListening = () => store.start();
