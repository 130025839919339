/**
 * CSS classes applied for UI info, in case they need to be leveraged dynamically in JS.
 */
export const CssClasses = {
  TouchSupported: "ui-touch-supported",
  TouchNotSupported: "ui-touch-not-supported",
  HoverSupported: "ui-hover-supported",
  HoverNotSupported: "ui-hover-not-supported"
};

/** Current user interface capabilities. */
export class UserInterfaceInfo {
  private _supportsTouch = false;
  /** Indicates that the user has actually touched a touch screen, so their interface supports touch. */
  public get supportsTouch(): boolean {
    return this._supportsTouch;
  }

  private _supportsHover = false;
  /** Indicates the user has moused over something, so their interface supports hover. */
  public get supportsHover(): boolean {
    return this._supportsHover;
  }

  private onFirstTouch = () => {
    // touch events are supported (as of writing) on all major mobile browsers, as well as Chrome, FF, but not Edge (without flag enabled)
    // (see https://caniuse.com/#search=touchstart)
    // ironically, Edge is one where it matters more due to Surfaces having touchscreens, but c'est la vie; those users can use their mouses to deal w/ any resultant issues
    this._supportsTouch = true;
    document.body.classList.add(CssClasses.TouchSupported);
    document.body.classList.remove(CssClasses.TouchNotSupported);
    window.removeEventListener("touchstart", this.onFirstTouch, false);
  };

  private onFirstHover = evt => {
    // as far as I can tell, current state of the art is that you can't really detect mouse input
    // (https://github.com/Modernizr/Modernizr/issues/869#issuecomment-15264101 ; I also tried the various mouse* events, and they get fired on taps)
    // the best I've found is below, which is to assume that if hover is triggered AND the user has a fine pointing device, yes, hover should be supported
    // except FF doesn't support any-pointer, so we also assume if FF then yes..
    const hasAFinePointer = window.matchMedia("(any-pointer: fine)").matches;
    const isFirefox = navigator.userAgent.indexOf("Firefox") >= 0;
    if (hasAFinePointer || isFirefox) {
      this._supportsHover = true;
      document.body.classList.add(CssClasses.HoverSupported);
      document.body.classList.remove(CssClasses.HoverNotSupported);
    }
    document.removeEventListener("mouseover", this.onFirstHover, false);
  };

  public constructor() {
    // we add these in the negative to allow immediate suppression of CSS-based behaviors that rely on them
    // this is particularly relevant for hover, since it is assumed it will be there in nearly all existing code
    document.body.classList.add(CssClasses.TouchNotSupported);
    document.body.classList.add(CssClasses.HoverNotSupported);

    window.addEventListener("touchstart", this.onFirstTouch, false);
    document.addEventListener("mouseover", this.onFirstHover, false);
  }
}
