const identity = o => o;

export function groupAsMap<T, K>(
  arr: T[],
  keySelector: (item: T) => K,
  elementSelector: (item: T) => any,
  resultSelector: (k: any, items: any[]) => any,
  keyCompareValue: (k) => any
): Map<K, any> {
  // tslint:disable:no-parameter-reassignment
  if (!elementSelector) {
    elementSelector = identity;
  }
  if (!keyCompareValue) {
    keyCompareValue = identity;
  }
  // tslint:enable:no-parameter-reassignment

  let groupsMap = new Map<K, { key: any; items: any[] }>();

  arr.forEach(item => {
    let key = keySelector(item),
      keyToUse = keyCompareValue(key);

    if (!groupsMap.has(keyToUse)) {
      groupsMap.set(keyToUse, { key, items: [] });
    }

    groupsMap.get(keyToUse).items.push(elementSelector(item));
  });

  if (resultSelector) {
    return new Map<K, any>(([...groupsMap.entries()] as any).map(([keyToUse, { key, items }]) => [keyToUse, resultSelector(key, items)]));
  }

  return groupsMap;
}

function groupBy<T, K>(arr: T[], keySelector: (item: T) => K): { key: K; items: T[] }[];
function groupBy<T, K, E>(arr: T[], keySelector: (item: T) => K, elementSelector: (item: T) => E): { key: K; items: E[] }[];
function groupBy<T, K, E, R>(
  arr: T[],
  keySelector: (item: T) => K,
  elementSelector: (item: T) => E,
  resultSelector: (obj: { key: K; items: E[] }) => R,
  keyCompareValue: (k) => any
): R[];

function groupBy<T, K, E, R>(
  arr: T[],
  keySelector: (item: T) => K,
  elementSelector?: (item: T) => E,
  resultSelector?: (key: K, items: E[]) => R,
  keyCompareValue?: (k) => any
): R[] {
  let grouping = groupAsMap(arr, keySelector, elementSelector, resultSelector, keyCompareValue);
  return [...grouping.values()];
}

let A = groupBy([1, 2, 3], i => i);
let B = groupBy(
  [1, 2, 3],
  i => i,
  i => ({ val: i })
);
let C = groupBy(
  [1, 2, 3],
  i => i,
  i => ({ val: i }),
  ({ key, items }) => ({ myVal: key, myItems: items }),
  i => i
);

if (false) {
  //compiler checks
  let aa: number = A[0].items[0];
  let xx: number = B[0].key;
  let yy: number = B[0].items[0].val;
  let nn: number = C[0].myItems[0].val;
}

(Array.prototype as any).groupBy = function groupByArrayInstance(keySelector, elementSelector, resultSelector, keyCompareValue) {
  return groupBy(this, keySelector, elementSelector, resultSelector, keyCompareValue);
};

let AA = [1, 2, 3].groupBy(i => i);
let BB = [1, 2, 3].groupBy(
  i => i,
  i => ({ val: i })
);
let CC = [1, 2, 3].groupBy(
  i => i,
  i => ({ val: i }),
  (key, items) => ({ myVal: key, myItems: items }),
  i => i
);

if (false) {
  //compiler checks

  let aa: number = AA[0].items[0];
  let xx: number = BB[0].key;
  let yy: number = BB[0].items[0].val;
  let nn: number = CC[0].myItems[0].val;
}

export { groupBy };

function sortBy<T>(arr: T[], ...sorters): T[] {
  if (!sorters.length) {
    throw new Error("Need at least one sorter");
  }
  return arr.concat().sort(getSorter(...sorters));
}

function getSorter(...sorters) {
  return (a, b) => {
    for (let sorterPacket of sorters) {
      let sorter = Array.isArray(sorterPacket) ? sorterPacket[0] : sorterPacket;
      let sorterMultiplier = Array.isArray(sorterPacket) ? sorterPacket[1] : 1;

      let valueA = sorter(a);
      let valueB = sorter(b);

      if (valueA > valueB) {
        return 1 * sorterMultiplier;
      } else if (valueA < valueB) {
        return -1 * sorterMultiplier;
      }
    }
    return 0;
  };
}

(Array.prototype as any).sortBy = function sortByArrayInstance(...sorters) {
  return sortBy(this, ...sorters);
};

(Array.prototype as any).sortByDesc = function sortByArrayInstance(...sorters) {
  const updatedSorters = sorters.map(x => (Array.isArray(x) ? x : [x, -1]));
  return sortBy(this, ...updatedSorters);
};

if (!Array.prototype.flatMap) {
  Array.prototype.flatMap = function <T, K>(this: T[], callback: (item: T, index?: number, array?: T[]) => K[]): K[] {
    return this.reduce((acc, c, ix, arr) => acc.concat(callback(c, ix, arr)), []);
  };
}

if (false) {
  let nums = [1, 2, 3].sortBy(i => i);
  //compiler checks
  let aa: number = nums[0];
  let junk = aa.toFixed(2);
}

export function desc<T>(sorter: SortDelegate<T>): SortTuple<T> {
  return [sorter, -1];
}
export function asc<T>(sorter: SortDelegate<T>): SortTuple<T> {
  return [sorter, 1];
}

declare global {
  interface PromiseConstructor {
    deferred<T>(): Promise<T>;
  }
}

declare global {
  interface ObjectConstructor {
    values(o: any): any[];
  }
}

if (!Object.values) {
  Object.values = o => Object.keys(o).map(key => o[key]);
}
