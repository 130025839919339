import React, { forwardRef } from "react";
import { imgProfileUrl, imgOrgUrl, imgProfileUrlDirect, imgOrgUrlDirect } from "../miscUtil";
import LazyImage from "../lazyImage";

interface ProfileImageProps extends React.HTMLProps<HTMLImageElement> {
  useOrgImage?: boolean;
  useDirectLink?: boolean;
}

const ProfileImage = forwardRef<HTMLImageElement>((props: ProfileImageProps, ref) => {
  const { src, useOrgImage, useDirectLink, ...rest } = props;
  const imageFunction = useOrgImage ? (useDirectLink ? imgOrgUrlDirect : imgOrgUrl) : useDirectLink ? imgProfileUrlDirect : imgProfileUrl;
  const imageUrl = imageFunction(src);

  return <LazyImage {...{ ...rest, ref, src: imageUrl }} />;
});

export const knockoutName = "cr-profile-image";

export default ProfileImage;
