import { BroadcastChannel } from "broadcast-channel";
import {
  externalLoginHandler,
  externalLogoutHandler,
  inactivityWarningHandler,
  refreshSessionHandler,
  userInteractionHandler,
  sessionRefreshedHandler
} from "./handlers";
import { UserSessionBroadcastChannel, UserSessionMessage } from "./models";

//Events that will be executed in other browser tabs
const UserSessionHandlers = {
  [UserSessionMessage.Logout]: externalLogoutHandler,
  [UserSessionMessage.Login]: externalLoginHandler,
  [UserSessionMessage.InactivityWarning]: inactivityWarningHandler,
  [UserSessionMessage.UserInteraction]: userInteractionHandler,
  [UserSessionMessage.RefreshSession]: refreshSessionHandler,
  [UserSessionMessage.SessionRefreshed]: sessionRefreshedHandler
};

export const onmessage = async (msg: UserSessionBroadcastChannel) => UserSessionHandlers[msg.message](msg);

const USER_SESSION_CHANNEL_NAME = "user-session";

let channel: BroadcastChannel<UserSessionBroadcastChannel> = null;

const createChannel = () => {
  channel = new BroadcastChannel(USER_SESSION_CHANNEL_NAME, {
    idb: {
      onclose: () => {
        // see https://github.com/pubkey/broadcast-channel#readme
        // the onclose event is just the IndexedDB closing.
        // you should also close the channel before creating
        // a new one.
        channel.close();
        createChannel();
      }
    }
  });

  channel.onmessage = onmessage;
};

createChannel();

/**
 * Do not cache the result of this call - the reference will change if using IndexedDB and the DB closes (older Safari versions).
 */
export const getUserSessionChannel = () => channel;

/**
 *Send a message to getUserSessionChannel to execute an event on the other open tabs
 *
 * @param {UserSessionBroadcastChannel} msg
 */
export const SendUserSessionMessage = async (msg: UserSessionBroadcastChannel) => getUserSessionChannel().postMessage(msg);
