//This will change when Babel is updated to the latest decorator proposal
const decoratorCalledWithoutExpression = (...args) => {
  if (typeof args[0] === "object" && typeof args[1] === "string" && typeof args[2] === "object") {
    //JS
    let probablyDescriptor = args[2];
    if (
      typeof probablyDescriptor.writable != "undefined" ||
      typeof probablyDescriptor.configurable != "undefined" ||
      typeof probablyDescriptor.enumerable != "undefined"
    ) {
      return true;
    }
  } else if (
    typeof args[0] === "object" &&
    typeof args[0].constructor === "function" &&
    typeof args[1] === "string" &&
    typeof args[2] === "undefined"
  ) {
    //TS
    return true;
  }
  return false;
};

//This will change when Babel is updated to the latest decorator proposal
const deconstructDecoratorArgs = (...args) => args;

export const decorator = fn => (...args) =>
  decoratorCalledWithoutExpression(...args) ? fn(...args) : (...decoratorArgs) => fn(...deconstructDecoratorArgs(...decoratorArgs), ...args);
