// ------------------------------------------------------------------
// Validation decorators
// ------------------------------------------------------------------
// All decorators take as an optional last parameter an options object which has the following properties
//   --message: string - The message to display if the property is invalid. This overrides whatever default message the decorator defines
//   --onlyIf: function - a condition which controls whether the validation is active. This will be called with the current object as the first argument
//
// Current decorators
// ------------------------------------------------------------------
// required - validates that the property is not null, undefined, or empty string. Any other valud is valid
// numeric - validates that the property passes isNaN, but rejects scientific notation values
// matches(regex) - validates that the property matches the passed regex
// minLength(length) - validates that the property has a length of at least that which is passed
// maxLength(length) - validates that the property has a length of at most that which is passed
// equals(otherProperty) - validates that the property matches the other property on the same object (useful when re-typing a password)
// date(format = 'M/D/YYYY') - validates that the property is a valid date, according to momentJS, matching the format passed (will default to 'M/D/YYYY')
// range(min, max) - validates that if the property is numeric, is in [min, max]. If not numeric, it'll pass, and defer to required or numeric validators
// validate(fn) - valid if the passed function returns true. This is for any one-off, manual validation use cases.
// creditCardNumber - valid if the value has 15 - 16 digits. If there is a credit card type selected, 15 digits will be valid for AMEX and 16 for any other credit card type.
//
// ------------------------------------------------------------------
// Validation decorators
// ------------------------------------------------------------------
// See type definition file (mobx-util.validation.d.ts) for basic usage guidance. This should improve as Code/TS support for decorators improves.
//

// NOTE: Please import via app/components/forms/validation instead of here, going forward. In the long run, we may remove this here.

import { decorator } from "./mobx-util.decoratorHelpers";
import { observable, extendObservable, observe, isObservableArray } from "mobx";

import moment from "moment";

export const validated = classOrProps =>
  typeof classOrProps === "function" ? runValidated(classOrProps) : Class => runValidated(Class, classOrProps);

export const runValidated = (Class, { onChange = true } = {}) => {
  //sticking this here juuuuuuuuuuuust in case there are no validated properties - still want this set up.
  if (!Class.prototype._validationInfo) {
    Object.defineProperty(Class.prototype, "_validationInfo", { writable: false, enumerable: false, configurable: false, value: {} });
  }

  Class.prototype.checkValidation = function() {
    Object.keys(this.validationModifiedTracker).forEach(prop => (this.validationModifiedTracker[prop] = true));
    return this.getValidationStateForAllProperties().every(p => p.valid);
  };

  Class.prototype._getInvalidValidationPacketForProperty = function(name) {
    if (!this._validationInfo[name]) return null;

    return this._validationInfo[name].find(packet => {
      if (typeof packet.onlyIf === "function") {
        if (!packet.onlyIf(this)) {
          return false;
        }
      }
      return !packet.isValid.call(this, this[name], this);
    });
  };
  Class.prototype.isPropertyValid = function(name) {
    return !this._getInvalidValidationPacketForProperty(name);
  };
  Class.prototype.propertyNeedsValidationMessage = function(name) {
    let { valid } = this.checkValidationForProperty(name);
    return !valid;
  };
  Class.prototype.checkValidationForProperty = function(name) {
    if (!this._validationInfo[name]) return { name, valid: true }; //If not validated, then return true - always valid. Useful so we don't have to check if property is validated

    if (this.validationModifiedTracker && !this.validationModifiedTracker[name]) return { name, valid: true };

    let packet = this._getInvalidValidationPacketForProperty(name);
    return !packet ? { name, valid: true } : { name, valid: false, message: packet.message, showMessage: packet.showMessage };
  };

  Class.prototype.getValidationStateForAllProperties = function() {
    return Object.keys(this._validationInfo).map(name => this.checkValidationForProperty(name));
  };

  Class.prototype.resetValidationForProperty = function(name) {
    this.validationModifiedTracker[name] = false;
  };
  Class.prototype.resetValidation = function() {
    Object.keys(this.validationModifiedTracker).forEach(prop => (this.validationModifiedTracker[prop] = false));
  };

  Class.prototype.configureValidation = function() {
    this.__validationConfigured = true;
    let validationModifiedTracker = Object.keys(this._validationInfo).reduce((hash, key) => ((hash[key] = false), hash), {});
    extendObservable(this, { validationModifiedTracker });

    if (onChange) {
      Object.keys(validationModifiedTracker).forEach(prop => {
        if (isObservableArray(this[prop])) {
          // subscribe to the array directly (otherwise this wasn't working for these..)
          this[prop].observe(() => (this.validationModifiedTracker[prop] = true));
        } else {
          observe(this, prop, () => (this.validationModifiedTracker[prop] = true));
        }
      });
    }
  };

  return class extends Class {
    constructor(...rest) {
      super(...rest);
      if (!this.__validationConfigured) {
        this.configureValidation();
      }
    }
  };
};

export const createValidation = (target, name, { isValid, defaultMessage }, validationOptions = {}) => {
  if (!target._validationInfo) {
    //instance decorators evaluated before the class decorator - need this here as a result
    Object.defineProperty(target, "_validationInfo", { writable: false, enumerable: false, configurable: false, value: {} });
  }

  if (!target._validationInfo[name]) {
    target._validationInfo[name] = [];
  }
  target._validationInfo[name].push({
    name,
    isValid,
    message: validationOptions.message || defaultMessage,
    onlyIf: validationOptions.onlyIf,
    showMessage: validationOptions.hasOwnProperty("showMessage") ? validationOptions.showMessage : true
  });
};

export const required = decorator((target, name, descriptor, options) =>
  createValidation(
    target,
    name,
    {
      isValid: value => value != null && value !== "",
      defaultMessage: "This is required."
    },
    options
  )
);

export const numeric = decorator((target, name, descriptor, options) =>
  createValidation(
    target,
    name,
    {
      isValid: value => !isNaN(+value) && !/e/gi.test(value),
      defaultMessage: "This can only be a number, with optional decimal."
    },
    options
  )
);

const digitRegex = /^\d+$/;
export const digit = decorator((target, name, descriptor, options) =>
  createValidation(
    target,
    name,
    {
      isValid: value => value === "" || digitRegex.test(value),
      defaultMessage: "This can only be a whole number. No decimals or non-number letters are allowed."
    },
    options
  )
);

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const emailAddress = decorator((target, name, descriptor, minimum, options) =>
  createValidation(
    target,
    name,
    {
      isValid: value => value === "" || emailRegex.test(value),
      defaultMessage: "Please enter a valid email address"
    },
    options
  )
);

export const idSelected = decorator((target, name, descriptor, options) =>
  createValidation(
    target,
    name,
    {
      isValid: value => digitRegex.test(value) && value > 0,
      defaultMessage: "Please select a valid option."
    },
    options
  )
);

export const matches = decorator((target, name, descriptor, pattern, options) =>
  createValidation(
    target,
    name,
    {
      isValid: value => pattern.test(value),
      defaultMessage: "This is not formatted as expected." // devs should REALLY override this to provide details on formatting expectation
    },
    options
  )
);

export const minLength = decorator((target, name, descriptor, length, options) =>
  createValidation(
    target,
    name,
    {
      isValid: value => !value || value.length >= length,
      defaultMessage: `This should have at least ${length} characters.`
    },
    options
  )
);

export const minCount = decorator((target, name, descriptor, minimum, options) =>
  createValidation(
    target,
    name,
    {
      isValid: value => !value || value.length >= minimum,
      defaultMessage: `This should have at least ${minimum} items.`
    },
    options
  )
);

const eligibleForNumericValidation = num => num != null && num !== "";

export const minValue = decorator((target, name, descriptor, minimum, options) =>
  createValidation(
    target,
    name,
    {
      isValid: value => !eligibleForNumericValidation(value) || value >= minimum,
      defaultMessage: `This should be at least ${minimum}.`
    },
    options
  )
);

export const maxLength = decorator((target, name, descriptor, length, options) =>
  createValidation(
    target,
    name,
    {
      isValid: value => !value || value.length <= length,
      defaultMessage: `This should not be more than ${length} characters long.`
    },
    options
  )
);

export const maxCount = decorator((target, name, descriptor, maximum, options) =>
  createValidation(
    target,
    name,
    {
      isValid: value => !value || value.length <= maximum,
      defaultMessage: `This should have no more than ${maximum} items.`
    },
    options
  )
);

export const maxValue = decorator((target, name, descriptor, maximum, options) =>
  createValidation(
    target,
    name,
    {
      isValid: value => !eligibleForNumericValidation(value) || value <= maximum,
      defaultMessage: `This should be no more than ${maximum}.`
    },
    options
  )
);

export const equals = decorator((target, name, descriptor, otherPropertyName, options) =>
  createValidation(
    target,
    name,
    {
      isValid: (value, self) => value === self[otherPropertyName],
      defaultMessage: `This should be exactly the same as '${otherPropertyName}'.`
    },
    options
  )
);

export const ccExpirationDate = decorator((target, name, descriptor, otherPropertyName, options) =>
  createValidation(
    target,
    name,
    {
      isValid: (value, self) => {
        const ccExpirationFormat = "MM-YYYY",
          monthField = self.expirationMonth,
          yearField = self.expirationYear;

        if (!monthField || !yearField) {
          return true;
        }

        // CRCH-1270 allow up to last day of the month
        const cutoff = moment(`${monthField}-${yearField}`, ccExpirationFormat)
            .endOf("month")
            .endOf("day"),
          today = moment();

        return !today.isAfter(cutoff);
      },
      defaultMessage: `Card expired, enter a valid expiration date.`
    },
    options
  )
);

export const date = decorator((target, name, descriptor, format = "M/D/YYYY", options) => {
  if (typeof format === "object") {
    options = format;
    format = "M/D/YYYY";
  }

  return createValidation(
    target,
    name,
    {
      isValid: value => !value || moment(value, format, false).isValid(""),
      defaultMessage: `This date should follow the ${format} format. M=Month; D=Day; Y=Year.`
    },
    options
  );
});

export const range = decorator((target, name, descriptor, from, to, options) =>
  createValidation(
    target,
    name,
    {
      isValid: value => {
        // NaN, null, undefined and empty string are all OK. Those should be handled by numeric and return the numeric's error if it's a problem
        if (+value !== +value || value === null || value === undefined || value === "") {
          return true;
        }
        return +value >= +from && +value <= +to;
      },
      defaultMessage: `This should be between ${from} and ${to}.`
    },
    options
  )
);

export const validate = decorator((target, name, descriptor, fn, options) =>
  createValidation(
    target,
    name,
    {
      isValid: (value, self) => fn.call(self, value, self),
      defaultMessage: `This is not in the expected format.` // devs should REALLY override this to provide details on expectations
    },
    options
  )
);

export function validateNumericPrecision(value, precision, scale) {
  let preDecimal = precision - scale;
  let postDecimal = scale;

  if (value === "" || value == null) {
    return true; //empty values are ok - validate
  }

  if (isNaN(value) || /e/gi.test(value)) {
    return true;
  }

  let [pre, post = ""] = (+value).toString().split(".");
  return pre.length <= preDecimal && post.length <= postDecimal;
}

export const creditCardNumber = decorator((target, name, descriptor, options) =>
  createValidation(
    target,
    name,
    {
      isValid: (value, self) => {
        const ccType = self.type;

        // If cc type haven't been selected, both 15 and 16
        // are valid numbers of digits for cc numbers.
        if (!ccType) {
          return value.length === 15 || value.length === 16;
        }

        // Validate CC by length, 15 digits for Amex (type 27), 16 for all other CC types.
        return ccType == 27 ? value.length === 15 : value.length === 16;
      },
      defaultMessage: `Enter a valid credit card number.`
    },
    options
  )
);
