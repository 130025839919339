import crHistory from "@cr/core/history";
import { store } from "app/util/historyListenerStore";

const authPathsRegex = /^\/?(auth|login|logout)/i;

export const isAuthRoute = (pathname?: string) => authPathsRegex.test(pathname);

export const goBackIfNotAuthRoute = () => {
  // TODO: please change me to crHistory.previousLocation and delete historyListenerStore (https://jira.centralreach.com/browse/CRCH-8270)
  if (!store.previousLocation || isAuthRoute(store.previousLocation.pathname)) {
    return;
  }

  crHistory.goBack();
};
