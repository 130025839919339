import { ThemeProvider } from "@cr/material-ui-theme-provider";
import { Column } from "@cr/react-basics";
import { Button, Card, CardActions, CardContent, makeStyles, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import GlobalStyle from "app/util/globalStyle";
import { observer } from "mobx-react-lite";
import React from "react";

const useStyles = makeStyles(theme => ({
  card: {
    zIndex: 2,
    maxWidth: 600
  },
  cardActions: {
    display: "flex",
    justifyContent: "flex-end"
  }
}));

export const UserExternalLogout = observer(() => {
  const classes = useStyles();

  const reload = () => {
    //if the user is not authenticated it will be redirected by UserLogoutRedirect
    window.location.reload();
  };

  return (
    <ThemeProvider skipGlobalStyles>
      <GlobalStyle styles={{ body: { overflow: "hidden" } }} />
      <Column justifyContent="center" alignItems="center" minHeight="100vh" className="gradient-bg fadeIn75" data-testid="externalLogout">
        <Card className={classes.card}>
          <CardContent>
            <Typography gutterBottom variant="h6" component="div">
              Sign in
            </Typography>
            <Alert severity="error">You were signed out of your account. Please login again.</Alert>
          </CardContent>
          <CardActions className={classes.cardActions}>
            <Button onClick={reload} color="primary" data-testid="goToLogin">
              Login
            </Button>
          </CardActions>
        </Card>
      </Column>
    </ThemeProvider>
  );
});
