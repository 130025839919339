function configureNetworking(): void {
  // IMPORTANT!
  // this is still needed so long as we make calls to cr.transport
  // from within modules instead of importing the transport file
  cr.transport = new cr.transportUtil.CRTransport();

  $(document).ajaxSend(overrideCrxApiHeartbeat);

  function overrideCrxApiHeartbeat(evt, xhr, { url }) {
    if (/\/event-heartbeat/.test(url)) {
      if (!cr.getUser().isAuthenticated) {
        evt.preventDefault();
        evt.stopPropagation();
        xhr.abort();
        return;
      }

      // ServiceStack does not return the proper content type
      // which makes Firefox try (and fail) to parse the response
      // as JSON when it's not.  This sets things straight.
      xhr.overrideMimeType("text/plain");
    }
  }
}

export default configureNetworking;
