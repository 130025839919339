let disableFieldsPrevStateKey = "disable-fields-prev-state";

let domUtil = {
  displayMessage: function(message, options) {
    toastr.options.preventDuplicates = true;
    toastr.options.positionClass = "toast-bottom-full-width";
    toastr.options.closeButton = true;
    toastr.options.progressBar = false;
    // set the right message based on if we have a warning
    // an error, or by default, a successfull action
    if (!options) {
      toastr.success(message);
    } else {
      if (options.isCritical) {
        // unless otherwise specified, we'll make it sticky until user interacts with it
        if (!options.toastrOptions) {
          options.toastrOptions = {};
        }
        options.toastrOptions.timeOut = options.toastrOptions.timeOut || 0;
        options.toastrOptions.extendedTimeOut = options.toastrOptions.extendedTimeOut || 2000;
        toastr.error(message, null, options.toastrOptions);
      } else if (options.isError) {
        toastr.error(message, null, options.toastrOptions);
      } else if (options.isWarning) {
        toastr.warning(message, null, options.toastrOptions);
      } else {
        toastr.success(message, null, options.toastrOptions);
      }
    }

    // if scrollTop is set as an option, then scroll the window back up
    if (options && options.scrollTop) {
      window.scrollTo(0, 0);
    }
  },

  displayError: function(message) {
    this.displayMessage(message, {
      isError: true
    });
  },

  displayWarning: function(message) {
    this.displayMessage(message, {
      isWarning: true
    });
  },

  clearModuleHelpers: function() {
    //TODO: target
    this.clearBackButton();
    this.clearHelpButton();
  },

  setBackButton: function(text, callBack) {
    // always clear in case
    this.clearBackButton();

    $("#headerBackButton")
      .text(text)
      .on("click", callBack)
      .parent()
      .show();
  },

  clearBackButton: function() {
    $("#headerBackButton")
      .text("")
      .unbind()
      .parent()
      .hide();
  },

  setHelpButton: function(helpId, isCategory) {
    $(".goto-help")
      .unbind()
      .removeClass("hide")
      .click(function(e) {
        e.preventDefault();
        if (isCategory) {
          window.open("http://help.centralreach.com/?cat=" + helpId);
        } else {
          window.open("http://help.centralreach.com/?p=" + helpId);
        }
      });
  },

  clearHelpButton: function() {
    $(".goto-help")
      .unbind()
      .addClass("hide");
  },

  activateModule: function(channelId) {},

  //using instead of innerHTML = '' since there's a dumb IE bug, and of course $().empty kills off current event handlers (a no-no if we want to re-attach those nodes later)
  clearElement: function(element) {
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
  },

  isButton(button) {
    if (!button) return false;

    let unwrappedButton = button.jquery ? button[0] : button;
    return /^button$|^a$/i.test(unwrappedButton.tagName) || /button/i.test(unwrappedButton.type);
  },

  // Sort of a strange criteria for finding a button given a DOM element.
  // If the given element isn't an anchor (a) or button tag, get the next
  // sibling element and check it. If it isn't, then search down the SIBLING'S tree,
  // rather than the original element's tree [sic], to find an anchor or button.
  // This means if no button is found, the original element's SIBLING is returned. ?!?!!
  getButtonFor: function(el) {
    if (!domUtil.isButton(el)) {
      el = $(el).next();
      if (!domUtil.isButton(el[0])) {
        el = el.find("button,a");
      }
    }
    return el;
  },

  /**
   * Start a button "spinning" and remember the information needed to stop it
   * later with `stopSpinningButton`.
   *
   * @param   domElement  The button to spin. Will usually have a data-spin
   *                      attribute in the form "Saving|Saved" where "Saving"
   *                      is the text to show while spinning, and "Saved"
   *                      is the text to show briefly after the operation is
   *                      complete.
   *
   *                      AJR - July 22 2014: you can now pass in a textbox
   *                      (or whatever) and it'll find the button, assuming
   *                      the button is adjacent, or part of an adjacent input-group.
   *
   * @returns             The button, for chaining, if a button is found. Otherwise
   *                      returns the domElement passed in as a parameter.
   */
  startSpinningButton(domElement, textValsOptional = null) {
    let button = this.getButtonFor(domElement); // What's returned may not be a button and could be whatever `button`'s next sibling is.
    textValsOptional = textValsOptional || {};

    if (domUtil.isButton(button)) {
      const $el = button.jquery ? button : $(button);
      const spinValues = ($el.attr("data-spin") || "").split("|");
      const savingText = spinValues[0] || textValsOptional.savingsText || "Saving";
      const savedTextFail = spinValues[2] || textValsOptional.savedTextFail || "Save Failed";
      const iconOnly = ($.trim($el.text()) === "" && $el.find("i").length) || $el.is("[spin-icon-only]");

      let savedText = spinValues[1] || textValsOptional.savedText || "Saved";

      // Allow for explicit "don't show saved state" flag
      if (savedText === "--") {
        savedText = "";
      }

      // Start spinning
      $el.data("data-spin-restore-info", {
        contents: $el.contents().detach(),
        iconOnly,
        savedText,
        savedTextFail,
        immediateRestore: $el.is("[immediate-restore]")
      });
      $el.prop("disabled", true);
      if ($el.attr("data-click")) {
        $el.attr("data-disabled-click", $el.attr("data-click")).removeAttr("data-click");
      }

      if (iconOnly) {
        $el.html('<i class="fa fa-spin fa-spinner"></i>');
      } else {
        $el.html('<span class="savingText"><i style="margin-right: 5px;" class="fa fa-spin fa-spinner"></i>' + savingText + "</span>");
      }
    } else {
      button = domElement; // If no button was found, send back the original element for chaining.
    }

    return button;
  },

  /**
   * Stop a button "spinning" that we started with `startSpinningButton`.
   *
   * Several options are taken from the button's data("data-spin-restore-info"):
   *    iconOnly
   *    savedText
   *    savedTextFail
   *    immediateRestore
   * These were set in startSpinningButton, above. If this data element does not exist,
   * this function is effectively a NOP.
   *
   * @param   button      The button
   * @param   success     (Optional, default true) true = success, show
   *                      the "saved" text/checkmark briefly;
   *                      false = just revert the button back to normal.
   *
   *                      AJR - July 22 2014: you can now pass in a textbox (or whatever) and it'll find the button, assuming the button is adjacent, or part of an adjacent
   *                      input-group.
   * @return  The button, for chaining.
   */
  stopSpinningButton: function(button, success) {
    let $el, info, delayRestore, oldColors, failColors;
    let restoreOriginal = function() {
      $el.prop("disabled", false).html(info.contents);
      if ($el.attr("data-disabled-click")) {
        $el.attr("data-click", $el.attr("data-disabled-click")).removeAttr("data-disabled-click");
      }
      if (oldColors) {
        $el.css("backgroundColor", oldColors.bg);
        $el.css("borderColor", oldColors.brd);
        oldColors = undefined; // superfluous
      }
    };

    success = success !== false; // "defaults to true"
    button = this.getButtonFor(button);
    $el = button.jquery ? button : $(button);
    failColors = {
      bg: "red",
      brd: "darkred"
    };

    info = $.hasData($el[0]) && $el.data("data-spin-restore-info");
    if (info) {
      $el.removeData("data-spin-restore-info");
      $el.find(".savingText").remove();

      // If we weren't asked to bypass the "saved" state and we have
      // something to show for that state, do that for two seconds;
      // otherwise, just restore the original immediately
      let tempMsg = "";
      if (!info.iconOnly) {
        tempMsg = success ? info.savedText : info.savedTextFail;
      }

      if (tempMsg) {
        oldColors = {
          bg: $el.css("backgroundColor"),
          brd: $el.css("borderColor")
        };
        !success && $el.css("backgroundColor", failColors.bg).css("borderColor", failColors.brd);
        $el.html(`<span class="savedText"><i class="fa fa-check-circle" style="margin-right:5px;"></i>${tempMsg}</span>`);
        delayRestore = true;
      }

      if (success && info.iconOnly && !info.immediateRestore) {
        $el.find("i").toggleClass("fa-check-circle fa-spin fa-spinner");
        delayRestore = true;
      }

      delayRestore ? setTimeout(restoreOriginal, 2000) : restoreOriginal();
    }

    return button;
  },

  /**
   * Disable all form fields within the given container, remembering whether
   * they were enabled so `reenableFields` can be used to re-enable them.
   *
   * @param   container   The container element/selector/jQuery object
   */
  disableFields: function(container) {
    if (!container.jquery) {
      container = $(container);
    }
    // Real fields
    container.find("input, textarea, select, button").each(function() {
      var $this = $(this);
      if (!$this.hasClass("select2-input")) {
        $this.data(disableFieldsPrevStateKey, !this.disabled);
        this.disabled = true;
      }
    });
    // select2 fields
    container.find(".select2-container").each(function() {
      var field = $(this);
      field.data(disableFieldsPrevStateKey, !field.hasClass("select2-container-disabled"));
      field.select2("enable", false);
    });
    // Fake fields
    container.find(".field-like").each(function() {
      var field = $(this);
      field.data(disableFieldsPrevStateKey, !field.hasClass("disabled"));
      field.addClass("disabled");
    });
  },

  /**
   * Re-enable form fields disabled via `disableFields`.
   *
   * @param   container   The container element/selector/jQuery object
   */
  reenableFields: function(container) {
    if (!container.jquery) {
      container = $(container);
    }
    // Real fields
    container.find("input, textarea, select, button").each(function() {
      var field, flag;
      if ($.hasData(this)) {
        field = $(this);
        if (!field.hasClass("select2-input")) {
          flag = field.data(disableFieldsPrevStateKey);
          if (typeof flag === "boolean") {
            field.removeData(disableFieldsPrevStateKey);
            this.disabled = !flag;
          }
        }
      }
    });
    // select2 fields
    container.find(".select2-container").each(function() {
      var field, flag;
      if ($.hasData(this)) {
        field = $(this);
        flag = field.data(disableFieldsPrevStateKey);
        if (typeof flag === "boolean") {
          field.removeData(disableFieldsPrevStateKey);
          field.select2("enable", flag);
        }
      }
    });
    // Fake fields
    container.find(".field-like").each(function() {
      var field, flag;
      if ($.hasData(this)) {
        field = $(this);
        flag = field.data(disableFieldsPrevStateKey);
        if (typeof flag === "boolean") {
          field.removeData(disableFieldsPrevStateKey);
          field.toggleClass("disabled", !flag);
        }
      }
    });
  },

  /**
   * If you explicitly update the enabled state of a field beween a call
   * to `disableFields` and a subsequent call to `reenableFields`, use
   * this to remove the information from the field that was stored there
   * by `disableFields` so that the field is ignored by `reenableFields`.
   *
   * @param   field       The field
   * @return  The field, for chaining
   */
  forgetFieldEnableInfo: function(field) {
    var $field = field.jquery ? field : $(field);
    if ($.hasData($field[0])) {
      $field.removeData(disableFieldsPrevStateKey);
    }
    return field;
  },

  displayApiError: (errObj, alertRegardless) => {
    let resp = (errObj.data ? errObj.data.responseStatus : errObj.responseStatus) || {};
    let errMsg = "";
    let errMessages = [];
    let requestResponseText = errObj.request && errObj.request.responseText;
    let errorsArray = $.isArray(resp.errors) ? resp.errors : [];

    if (!$.isEmptyObject(errObj) || alertRegardless) {
      // There are a few ways we could catch errors.
      // 1. Borked status
      if (errObj && errObj.status) {
        switch (errObj.status) {
          case 404:
            errMessages.push(
              "This action could not be performed. Pleae reload the page if this error " +
                "continues, and contact CentralReach if it persists after reload."
            );
            break;

          // others?

          default:
          // NOP.
        }
      }

      // 2. Standard FluentValidation object model errors.
      if (errorsArray.length) {
        errMessages.concat(errorsArray);
      } else {
        resp.message && errMessages.push(resp.message);
      }

      if (!errMessages.length && alertRegardless) {
        errMessages.push("An error has been reported. If this continues, please contact CentralReach.");
      }

      // 3. And then there are a few standard errors where the server's done something unexpected
      // that we probably want to clean. In a perfect world, they'd be cleaned on the server.
      errMsg = errMessages.join("<br />");
      errMsg = errMsg.replace(
        "Object reference not set to an instance of an object.",
        "Validation error on server. Please reload the page. If this continues, contact CentralReach."
      );

      if (errMsg) {
        debugLog(errMsg, "Error text displayed to user");
        debugLog(errObj, "Full error object being logged by displayApiError");
        domUtil.displayError(errMsg);
      }
    }

    return errMsg;
  },

  /**
   * Scroll to the bottom of an area in the page.
   * Useful to show the latest records when displaying a list in chronological order
   *
   * @param   scrollableArea   The element/selector/jQuery object to be scrolled
   */
  scrollToBottom: scrollableArea => {
    const area = (scrollableArea.jquery ? scrollableArea : $(scrollableArea))[0];
    if (area) {
      area.scrollTop = area.scrollHeight - area.clientHeight;
    }
  },

  lastProp: null
};

export default domUtil;
