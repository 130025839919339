/* tslint:disable: bool-param-default */

import { IReturn } from "app/util/api.dto";
import domUtil from "app/util/domUtil";
import ResponsiveUtil from "app/util/responsiveUtil";
import Cookies from "js-cookie";
import { isArrayLike } from "mobx";
import { Moment } from "moment";
import { JSV } from "third_party_libraries/jsv/jsv";
import * as Interfaces from "./api/interfaces";
import * as Validators from "./api/validators";
import { rewriteUrlToEksApi } from "./eks";

export const ApiError = Interfaces.ApiError;
export const ErrorMessages = Interfaces.ErrorMessages;

export const ClientSource = "members-spa";
export const ClientSourceHeader = "X-CrApi-ClientSource";
export const CSRFTokenHeader = "X-CSRF-Token";
export const CSRFCookie = "csrf-token";

enum TrackSource {
  Desktop = "Desktop",
  Mobile = "Mobile",
  App = "App"
}

//going to update.

export interface TrackData {
  source: TrackSource;
  channel: string;
  action: string;
  metric?: number;
  silent?: boolean;
  latitude?: number;
  longitude?: number;
}

const csrfQueue = [];

export const hasCSRFToken = () => !!(Cookies.get(CSRFCookie) || "");

const pause = (milliseconds: number) => new Promise(resolve => setTimeout(resolve, milliseconds));

const csrfDefaultRetries = Math.max(+(window.localStorage.getItem("csrf-default-retries") || "1") || 1, 0);

const setCSRFToken = async (numRetries = csrfDefaultRetries, attempt = 1, pauseTime = 200) => {
  try {
    await fetch(`${cr_appConfig.transportURL}?framework.csrf`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        [ClientSourceHeader]: ClientSource
      }
    });
  } catch (e) {}

  if (!hasCSRFToken() && attempt - 1 < numRetries) {
    await pause(pauseTime);
    await setCSRFToken(numRetries, attempt + 1, pauseTime);
  }
};

const postSetCSRFToken = () => {
  let csrfToken = Cookies.get(CSRFCookie) || "";

  while (csrfQueue.length > 0) {
    csrfQueue.shift()(csrfToken);
  }
};

export const getCSRFToken = (retries: number = csrfDefaultRetries): Promise<string> =>
  new Promise(resolve => {
    let csrfToken = Cookies.get(CSRFCookie) || "";

    if (!csrfToken) {
      csrfQueue.push(token => resolve(token));

      if (csrfQueue.length === 1) {
        setCSRFToken(retries).then(postSetCSRFToken, postSetCSRFToken);
      }
    } else {
      resolve(csrfToken);
    }
  });

export const reloadForCSRFToken = () => {
  const reloadedKey = "csrf-reloaded";
  const url = new URL(window.location.href);
  const search = new URLSearchParams(url.search);

  if (!search.has(reloadedKey)) {
    search.append(reloadedKey, "1");
    const rewrittenUrl = `${url.pathname}?${search}${url.hash}`;
    window.location.href = rewrittenUrl;
  }
};

export interface ApiParams<TRequest = { [key: string]: any }> {
  /**
   * Contains request parameters. Will be passed as JSV in the URL
   */
  search?: TRequest;
  /**
   * Button to display spinning indicator over during request
   */
  button?: any;
  /**
   * Whether or not to force response property names to Pascal case
   */
  asPascal?: boolean;
  track?: TrackData;
}

export interface ApiBodyParams<TRequest = { [key: string]: any }> extends ApiParams {
  /**
   * Contains request parameters. Will be passed as JSON in the body
   */
  body?: TRequest;
}

/**
 * Use this to (lightly) wrap calls to the API. Gives us a centralized injection point for API-call stuff.
 */

export default class ApiTransport {
  private _logSprocCalls: boolean;
  private _baseUrl: string;
  private _debug: boolean;
  private _ajax: any;

  constructor(debug?: boolean, ajax?: any, url?: string) {
    this._logSprocCalls = false;
    this._baseUrl = `${url || app_apiurl}/`;
    this._debug = debug;
    this._ajax = ajax || $.ajax;
  }

  /**
   * Transmits an HTTP GET request to the API and returns the results in a promise. Parameters should be passed URI-encoded.
   *
   * @template TReturn - Type of object you expect to be returned in from promise resolution.
   * @param {any} path - Relative path to the API base url (optionally provided in constructor). Omit the leading slash. Use params for querystring values.
   * @param {object} [params] - Optional. Object whose keys and values should be added in the querystring.
   * @param {*} [button] - Optional. Button to display spinning indicator over during request.
   * @param {boolean} [asPascal] - Optional. Whether or not to force response property names to Pascal case.
   * @returns {Promise<TReturn>} - Promise that will resolve to the response from the server.
   */
  get<TReturn = any>(path, params?: object, button?: any, asPascal?: boolean): Promise<TReturn> {
    const nUrl = `${this._baseUrl}${path}${ApiTransport.buildParams(params)}`,
      $el = ApiTransport.startSpinning(button);
    return this.sendRequest(HttpVerbs.Get, nUrl, null, asPascal).then(
      resp => {
        ApiTransport.stopSpinning($el);
        return resp;
      },
      resp => {
        ApiTransport.stopSpinning($el);
        return Promise.reject(resp);
      }
    ) as Promise<TReturn>;
  }

  /**
   * Transmits an HTTP GET request to the API and returns the results in a promise. Parameters should be passed URI-encoded.
   *
   * @template TReturn - Type of object you expect to be returned in from promise resolution.
   * @param {any} path - Relative path to the API base url (optionally provided in constructor). Omit the leading slash.
   * @param {ApiParams} [options] - Optional. Additional request parameters
   * @returns {Promise<TReturn>} - Promise that will resolve to the response from the server.
   */
  get2<TResponse, TRequest extends IReturn<TResponse>>(path: string, options: ApiParams<TRequest>): Promise<TResponse>;
  get2<TResponse = any>(path: string, options: ApiParams = {}): Promise<TResponse> {
    const { search, button, asPascal, track } = options;
    const $el = ApiTransport.startSpinning(button);
    const fullPath = ApiTransport.buildFullPath(this._baseUrl, path, search);

    return this.sendRequest(HttpVerbs.Get, fullPath, null, asPascal)
      .then(resp => {
        ApiTransport.stopSpinning($el);
        return resp;
      })
      .catch(resp => {
        ApiTransport.stopSpinning($el);
        return Promise.reject(resp);
      }) as Promise<TResponse>;
  }

  /**
   * Transmits an HTTP GET request to the API and returns the results in a promise. Parameters should be passed raw and will be URI-encoded by the method.
   *
   * @template TReturn - Type of object you expect to be returned in from promise resolution.
   * @param {any} path - Relative path to the API base url (optionally provided in constructor). Omit the leading slash. Use params for querystring values.
   * @param {object} [params] - Optional. Object whose keys and values should be added in the querystring. Parameters will be URI-encoded by the method
   * @param {*} [button] - Optional. Button to display spinning indicator over during request.
   * @param {boolean} [asPascal] - Optional. Whether or not to force response property names to Pascal case.
   * @returns {Promise<TReturn>} - Promise that will resolve to the response from the server.
   */
  getEncoded<TReturn = any>(path, params?: object, button?: any, asPascal?: boolean): Promise<TReturn> {
    return this.get(path, ApiTransport.uriEncodeParams(params), button, asPascal);
  }

  /**
   * Transmits an HTTP PATCH request to the API and returns the results in a promise.
   *
   * @template TReturn - Type of object you expect to be returned in from promise resolution.
   * @param {any} path - Relative path to the API base url (optionally provided in constructor). Omit the leading slash. Use params for querystring values.
   * @param {object} [params] - Optional. Object whose keys and values should be added in the querystring.
   * @param {*} [body] - Optional. Request body content to send to server.
   * @param {*} [button] - Optional. Button to display spinning indicator over during request.
   * @param {boolean} [asPascal] - Optional. Whether or not to force response property names to Pascal case.
   * @returns {Promise<TReturn>} - Promise that will resolve to the response from the server.
   */
  patch<TReturn = any>(path: string, params?: object, body?: any, button?: any, asPascal?: boolean): Promise<TReturn> {
    return this.postPutDelete(HttpVerbs.Patch, path, params, body, button, asPascal) as Promise<TReturn>;
  }

  /**
   * Transmits an HTTP POST request to the API and returns the results in a promise.
   *
   * @template TReturn - Type of object you expect to be returned in from promise resolution.
   * @param {any} path - Relative path to the API base url (optionally provided in constructor). Omit the leading slash. Use params for querystring values.
   * @param {object} [params] - Optional. Object whose keys and values should be added in the querystring.
   * @param {*} [body] - Optional. Request body content to send to server.
   * @param {*} [button] - Optional. Button to display spinning indicator over during request.
   * @param {boolean} [asPascal] - Optional. Whether or not to force response property names to Pascal case.
   * @returns {Promise<TReturn>} - Promise that will resolve to the response from the server.
   */
  post<TReturn = any>(path: string, params?: object, body?: any, button?: any, asPascal?: boolean): Promise<TReturn> {
    return this.postPutDelete(HttpVerbs.Post, path, params, body, button, asPascal) as Promise<TReturn>;
  }

  post2<TResponse = any, TRequest extends IReturn<TResponse> = IReturn<TResponse>>(
    path: string,
    options: ApiBodyParams<TRequest>
  ): Promise<TResponse>;
  post2<TReturn = any>(path: string, options: ApiBodyParams): Promise<TReturn> {
    return this.postPutDelete2(HttpVerbs.Post, path, options) as Promise<TReturn>;
  }

  /**
   * Transmits an HTTP PUT request to the API and returns the results in a promise.
   *
   * @template TReturn - Type of object you expect to be returned in from promise resolution.
   * @param {any} path - Relative path to the API base url (optionally provided in constructor). Omit the leading slash. Use params for querystring values.
   * @param {object} [params] - Optional. Object whose keys and values should be added in the querystring.
   * @param {*} [body] - Optional. Request body content to send to server.
   * @param {*} [button] - Optional. Button to display spinning indicator over during request.
   * @param {boolean} [asPascal] - Optional. Whether or not to force response property names to Pascal case.
   * @returns {Promise<TReturn>} - Promise that will resolve to the response from the server.
   */
  put<TReturn = any>(path: string, params?: object, body?: any, button?: any, asPascal?: boolean): Promise<TReturn> {
    return this.postPutDelete(HttpVerbs.Put, path, params, body, button, asPascal) as Promise<TReturn>;
  }

  put2<TResponse, TRequest extends IReturn<TResponse>>(path: string, options: ApiBodyParams<TRequest>): Promise<TResponse>;
  put2<TReturn = any>(path: string, options: ApiBodyParams): Promise<TReturn> {
    return this.postPutDelete2(HttpVerbs.Put, path, options) as Promise<TReturn>;
  }

  /**
   * Transmits an HTTP DELETE request to the API and returns the results in a promise.
   *
   * @template TReturn - Type of object you expect to be returned in from promise resolution.
   * @param {any} path - Relative path to the API base url (optionally provided in constructor). Omit the leading slash. Use params for querystring values.
   * @param {object} [params] - Optional. Object whose keys and values should be added in the querystring.
   * @param {*} [body] - Optional. Request body content to send to server.
   * @param {*} [button] - Optional. Button to display spinning indicator over during request.
   * @param {boolean} [asPascal] - Optional. Whether or not to force response property names to Pascal case.
   * @returns {Promise<TReturn>} - Promise that will resolve to the response from the server.
   */
  delete<TReturn = any>(path: string, params?: object, body?: any, button?: any, asPascal?: boolean): Promise<TReturn> {
    return this.del(path, params, body, button, asPascal);
  }

  delete2<TResponse, TRequest extends IReturn<TResponse>>(path: string, options: ApiParams<TRequest>): Promise<TResponse>;
  delete2<TReturn = any>(path: string, options: ApiParams): Promise<TReturn> {
    return this.postPutDelete2(HttpVerbs.Delete, path, options) as Promise<TReturn>;
  }

  /**
   * Extends request payload object with tracking information used on the server
   *
   * @param {any} request - The request payload to extend
   * @param {string} channel - Channel to track
   * @param {string} action - Action to track
   * @param {any} [metric] - Optional. A metric (numeric/decimal)
   */
  public track(request: any, channel: string, action: string, metric?: any) {
    const source = new ResponsiveUtil().deviceType === "desktop" ? "Desktop" : "Mobile";

    // to do: future implementation will get lat/long for when
    // the user accepts the browsers location info
    const latitude = 0;
    const longitude = 0;

    // possible addition could be to also track users browser
    // and other device information using browser agent string

    Object.assign(request, {
      _track: {
        channel,
        action,
        source,
        latitude,
        longitude,
        metric: metric || 0
      }
    });
  }

  /** deprecated. Don't use this. Use delete. I mean, you can type a few extra characters, right? ... */
  private del<TReturn>(path: string, params?: object, body?: any, button?: any, asPascal?: boolean): Promise<TReturn> {
    return this.postPutDelete(HttpVerbs.Delete, path, params, body, button, asPascal) as Promise<TReturn>;
  }

  private postPutDelete(type, path, params, body, button, asPascal) {
    const nUrl = `${this._baseUrl}${path}${ApiTransport.buildParams(params)}`,
      $el = ApiTransport.startSpinning(button);

    return this.sendRequest(type, nUrl, body, asPascal).then(
      resp => {
        ApiTransport.stopSpinning($el);
        return resp;
      },
      resp => {
        ApiTransport.stopSpinning($el);
        return Promise.reject(resp);
      }
    );
  }

  private postPutDelete2(verb: string, path, options: ApiBodyParams) {
    const { search, button, asPascal, track, body } = options;
    const fullPath = ApiTransport.buildFullPath(this._baseUrl, path, search);
    const $el = ApiTransport.startSpinning(button);

    return this.sendRequest(verb, fullPath, body, asPascal).then(
      resp => {
        ApiTransport.stopSpinning($el);
        return resp;
      },
      resp => {
        ApiTransport.stopSpinning($el);
        return Promise.reject(resp);
      }
    );
  }

  private static stopSpinning($el: any) {
    if ($el) {
      domUtil.stopSpinningButton($el, null);
    }
  }

  private static startSpinning(button) {
    let $el;
    if (button) {
      $el = !/^(a|button)$/i.test(button.tagName) ? $(button).closest("a,button")[0] : button;
      if ($el) {
        domUtil.startSpinningButton($el);
      }
    }
    return $el;
  }

  private async getHeaders() {
    const csrfToken = await getCSRFToken();
    const headers = { [ClientSourceHeader]: ClientSource, [CSRFTokenHeader]: csrfToken };

    return headers;
  }

  private sendRequest(verb: string, url: string, data: any, asPascal: boolean) {
    const options = {
      url: rewriteUrlToEksApi(url, verb),
      type: verb,
      contentType: "application/json; charset=UTF-8",
      async: true,
      data: data && JSON.stringify(data),
      xhrFields: {
        withCredentials: true
      },
      sendKeepAlive: true // Jason added this to apiTransport.js after Ambrose refacotred--so blame him for this! :-p
    };

    // we're wrapping the jQuery ajax in an ES6 promise for forwards compatibility, if we decide to swap out the impl later.
    // NOTE: If you decide to change this, please try to make sure the change
    // doesn't result in throwing away some of the error information provided
    // by .fail(...).
    return new Promise((resolve, reject) => {
      this.getHeaders().then(headers => {
        this._ajax({ ...options, headers })
          .then((responseData, textStatus, jqXHR) => {
            const rv = asPascal ? ApiTransport._asPascal(responseData) : responseData;
            const result = rv.Result || rv.result || "OK";
            result === "OK" ? resolve(rv) : reject({ data: rv, status: jqXHR.status, textStatus, request: jqXHR });
          })
          .fail((jqXHR, textStatus, errorThrown) => {
            this.checkApiDown(jqXHR);
            reject({ data: jqXHR.responseJSON, status: jqXHR.status, textStatus, request: jqXHR, errorThrown });
          });
      });
    });
  }

  private checkApiDown(jqXHR) {
    if (jqXHR.readyState === 0 && jqXHR.state() === "rejected") {
      console.warn(
        "Request was rejected so chances are good that the API is unreachable or there is a problem with CORS. Ensure the API is running."
      );
    }
  }

  private static buildParams(params) {
    if (!params) {
      return "";
    }

    const queryString = Object.keys(params)
      .filter(k => typeof params[k] !== "function" && params[k] !== undefined && params[k] !== null && params[k].toString())
      .map(k => `${k}=${paramValueToString(params[k])}`)
      .join("&");
    return (queryString && "?" + queryString) || "";
  }

  private static buildFullPath(baseUrl: string, path: string, search: ApiParams["search"]) {
    let fullPath = `${baseUrl}${path}`;
    const url = new URL(fullPath, window.location.href);
    if (url.search && search) {
      throw new Error("URL search parameters should be passed either in the path or in options.search, not both");
    }
    if (search) {
      fullPath =
        fullPath +
        "?" +
        Object.keys(search)
          .map(key => encodeURIComponent(key) + "=" + JSV.serialize(search[key]))
          .join("&");
    }
    return fullPath;
  }

  private static uriEncodeParams(params) {
    if (typeof params !== "object") {
      return params;
    }
    return Object.keys(params).reduce((obj, key) => {
      obj[key] = encodeURIComponent(params[key]);
      return obj;
    }, {});
  }

  // tslint:disable-next-line:function-name
  private static _asPascal(obj) {
    const keys = Object.keys(obj),
      rv = {};
    let n = keys.length;

    while (n--) {
      const key = keys[n];
      rv[capitalizeFirstLetter(key)] = toPascal(obj[key]);
    }
    return rv;
  }
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function paramValueToString(value) {
  return typeof value === "object" ? JSV.serialize(value) : String(value);
}

function toPascal(item) {
  if (typeof item === "number" || typeof item === "string" || typeof item === "boolean" || item instanceof Date) {
    return item;
  }

  let rv, n;
  if (Array.isArray(item)) {
    rv = [];
    n = item.length;
    while (n--) {
      const i = item[n];
      rv.unshift(toPascal(i));
    }
    return rv;
  }
  const keys = Object.keys(item);
  rv = {};
  n = keys.length;

  while (n--) {
    const key = keys[n];
    rv[capitalizeFirstLetter(key)] = toPascal(item[key]);
  }
  return rv;
}

export const getLocalMomentFromServerDate = possibleDateValue => {
  if (possibleDateValue == null || possibleDateValue === "") {
    // members midtier may return "" for not set dates..
    return null;
  }
  const parsedDate: Moment = moment.utc(possibleDateValue).local();
  if (!parsedDate.isValid()) {
    console.error(`Expected a date value parseable as a Moment, but got ${possibleDateValue}.`);
  }
  return parsedDate;
};

/** Exported with testing (mocking) in mind. */
export const HttpVerbs = {
  Get: "get",
  Patch: "patch",
  Post: "post",
  Put: "put",
  Delete: "delete"
};

/**
 * Convenience methods for simplifying basic CRUD API calls. Use ApiTransport directly for less conventional scenarios.
 */
export class Api {
  /**
   * Saves the given object to the given service endpoint. This assumes service will return IdResult.
   *
   * @template T - Type of object to save. (Will be inferred normally.)
   * @param {string} postEndpoint - Endpoint for POST (typically insert) operations. Can also be used for updates, if that's how the service is designed.
   * @param {T} domainObject - Object to save. Will be wrapped in IAccept.
   * @param {string} [putEndpoint] - Optional PUT endpoint. Will default to postEndpoint if not provided.
   * @returns {Promise<number>} - Promise resolving to new/existing ID of the object.
   */
  public static save<T extends { id: number }>(postEndpoint: string, domainObject: T, putEndpoint?: string): Promise<number> {
    return new Promise((resolve, reject) => {
      const api = new ApiTransport();
      // default put (update) endpoint to post, if not provided
      const actualPutEndpoint = putEndpoint && putEndpoint.length > 0 ? putEndpoint : postEndpoint;
      const isUpdate = Validators.isValidId(domainObject.id) && domainObject.id > 0,
        callApi = isUpdate
          ? () => api.put(actualPutEndpoint, null, Interfaces.Wrap(domainObject))
          : () => api.post(postEndpoint, null, Interfaces.Wrap(domainObject));

      return callApi()
        .then((resp: Interfaces.IdResult) => resolve(Interfaces.UnwrapId(resp, domainObject.id))) // keep current id if none returned
        .catch(err => reject(new ApiError(err.request)));
    });
  }

  // FIXME: Accepted types can be partial, we need to account for that in the typings

  /**
   * Wraps and saves the given list of objects to the given service endpoint that implements IAcceptMany. If service returns IHaveResults, those results will be returned and (if mapTo is given) mapped to a new list of that type. Otherwise, the original list will be returned (assuming service just returned Ok with no results).
   *
   * @template T - Type of domain object in the list.
   * @param {string} endpoint - Endpoint to PUT list to. (This assumes you are updating a list of objects rather than creating.)
   * @param {Array<T>} list - List of domain objects to post.
   * @returns {Promise<Array<T>>} - Either a new list based in returned IHaveResults or the original list.
   * @deprecated This is not TypeScript friendly, don't use
   */
  public static saveList<T extends object>(endpoint: string, list: T[], mapTo?: Interfaces.Mappable): Promise<T[]> {
    return new Promise((resolve, reject) => {
      const api = new ApiTransport();
      return api
        .put(endpoint, null, Interfaces.WrapList(list))
        .then((resp: any) => {
          let results: T[] = list;
          if (Validators.isArrayResults(resp)) {
            results = resp.results;
            if (mapTo) {
              results = results.map(obj => new mapTo(obj));
            }
          }
          return resolve(results);
        })
        .catch(err => reject(new ApiError(err.request)));
    });
  }

  /**
   * Gets a list of domain objects based on the endpoint and filters. Optionally maps to new client model.
   *
   * @template T - Type of domain object to be returned.
   * @param {string} endpoint - Base endpoint to GET from.
   * @param {Interfaces.Mappable} [mapTo] - Optional. Model class constructor to map to. See Interfaces.Mappable for more info.
   * @param {object} [params] - Optional. Object with key-value pairs to map into querystring. See ApiTransport.get.
   * @returns {Promise<Array<T>>} - List of objects, optionally mapped to new type.
   */
  public static list<T extends object>(endpoint: string, mapTo?: Interfaces.Mappable, params?: object): Promise<T[]> {
    const api = new ApiTransport();
    return new Promise((resolve, reject) => {
      return api
        .get(endpoint, params)
        .then((resp: Interfaces.IHaveResults<T>) => {
          let unwrapped = Interfaces.UnwrapList(resp);
          if (mapTo) {
            unwrapped = unwrapped.map(obj => new mapTo(obj));
          }
          return resolve(unwrapped);
        })
        .catch(err => reject(new ApiError(err.request)));
    });
  }

  /**
   * Gets a list of domain objects based on the endpoint and filters. Optionally maps to new client model.
   *
   * @template T - Type of domain object to be returned.
   * @param {string} endpoint - Base endpoint to GET from.
   * @param {Interfaces.PageRequest} pageInfo - Paging info that tells the service what page you want.
   * @param {Interfaces.Mappable} [mapTo] - Optional. Model class constructor to map to. See Interfaces.Mappable for more info.
   * @param {object} [params] - Optional. Object with key-value pairs to map into querystring. See ApiTransport.get.
   * @returns {Promise<Interfaces.PagedResponse<T>>} - List of objects, optionally mapped to new type along with total count if provided by the service.
   */
  public static pagedList<T extends object>(
    endpoint: string,
    pageInfo: Interfaces.PagedRequest,
    mapTo?: Interfaces.Mappable,
    params?: object
  ): Promise<Interfaces.PagedResponse<T>> {
    const updatedParams = params ? Object.assign(params, pageInfo) : pageInfo; // merges page info onto general params.
    const api = new ApiTransport();
    return new Promise((resolve, reject) => {
      return api
        .get(endpoint, updatedParams)
        .then((resp: Interfaces.IHavePagedResults<T>) => {
          let unwrapped = Interfaces.UnwrapList(resp);
          if (mapTo) {
            unwrapped = unwrapped.map(obj => new mapTo(obj));
          }
          return resolve({ results: unwrapped, totalCount: resp.totalCount });
        })
        .catch(err => reject(new ApiError(err.request)));
    });
  }

  /**
   * Gets a single domain object based on the endpoint. Assumes the endpoint implements IHaveResult. Optionally maps to new client model.
   *
   * @template T - Type of domain object to be returned.
   * @param {string} endpoint - Base endpoint to GET from.
   * @param {Interfaces.Mappable} [mapTo] - Optional. Model class constructor to map to. See Interfaces.Mappable for more info.
   * @returns {Promise<T>} - Desired object if found, optionally mapped to new type.
   */
  public static single<T extends object>(endpoint: string, mapTo?: Interfaces.Mappable): Promise<T> {
    const api = new ApiTransport();
    return new Promise((resolve, reject) => {
      return api
        .get(endpoint)
        .then((resp: Interfaces.IHaveResult<T>) => {
          let unwrapped = Interfaces.Unwrap(resp);
          if (mapTo) {
            unwrapped = new mapTo(unwrapped);
          }
          return resolve(unwrapped);
        })
        .catch(err => reject(new ApiError(err.request)));
    });
  }

  /**
   * Deletes a single domain object based on the endpoint.
   *
   * @template T - Type of domain object to be deleted. Normally inferred from the given object.
   * @param {string} endpoint - Base endpoint to send DELETE to.
   * @param {T} [domainObject] - Optional. Object to post. Will be wrapped in IAccept.
   * @returns {Promise<boolean>} - Resolves true if no error occurred; otherwise, resolves error.
   */
  public static delete<T extends object>(endpoint: string, domainObject?: T): Promise<boolean> {
    const api = new ApiTransport();
    return new Promise((resolve, reject) => {
      return api
        .delete(endpoint, null, domainObject ? Interfaces.Wrap(domainObject) : null)
        .then(() => resolve(true))
        .catch(err => reject(new ApiError(err.request)));
    });
  }

  /**
   * Deletes a list (collection) of objects based on the endpoint.
   *
   * @template T - Type of domain object to be deleted. Normally inferred from the given object.
   * @param {string} endpoint - Base endpoint to send DELETE to.
   * @param {Array<T>} [domainObjects] - Optional. List of objects to delete. Presumably the endpoint would assume you want to delete the whole collection if you don't specify one.
   * @returns {Promise<boolean>} - Resolves true if no error occurred; otherwise, resolves error.
   * @memberof Api
   */
  public static deleteList<T extends object>(endpoint: string, domainObjects?: T[]): Promise<boolean> {
    const api = new ApiTransport();
    return new Promise((resolve, reject) => {
      return api
        .delete<T>(endpoint, null, domainObjects ? Interfaces.WrapList(domainObjects) : null)
        .then(() => resolve(true))
        .catch(err => reject(new ApiError(err.request)));
    });
  }

  /**
   * Creates a copy of the source objects, stripping all but the properties specified from the copies so that only what is needed is transported over the wire to the services.
   *
   * @template T - Type of source/return objects.
   * @param {T} source - Source object. Can be single or array.
   * @param {string[]} propsToSend - This only supports top-level properties at this point and must have at least one prop specified.
   * @param {Interfaces.Mappable} [mapTo] - if the target supports MobX-based mapping, you can use this; otherwise, it just creates a shallow copy with Object.assign.
   * @returns {T} - Cleaned object or array of objects (array if source was array; obj if source was obj);
   * @deprecated: Mixing arrays and scalars in the argument types is bad practice.
   */
  public static getCleanObjectsForTransport<T>(source: T, propsToSend: string[], mapTo?: Interfaces.Mappable): T {
    // tslint:disable-next-line:triple-equals
    Validators.assert(source != null, ErrorMessages.forNoSourceObject());
    Validators.assert(Validators.isValidArray(propsToSend), ErrorMessages.forNoPropsToSend());

    const sourceIsArray = isArrayLike(source);
    let items: any = sourceIsArray ? source : [source];

    items = items.map(item => {
      let itemToSend; // make a copy in case calling code keeps a ref to the original
      if (mapTo) {
        itemToSend = new mapTo(item);
      } else {
        itemToSend = Object.assign({}, item);
      }
      Object.keys(itemToSend).forEach(key => {
        if (!propsToSend.some(prop => prop === key)) {
          delete itemToSend[key];
        }
      });
      return itemToSend;
    });

    return sourceIsArray ? (items as T) : (items[0] as T);
  }

  public static stripNullPropertiesForTransport<T>(source: T, mapTo?: Interfaces.Mappable): T {
    const sourceIsArray = isArrayLike(source);
    let items: any = sourceIsArray ? source : [source];

    items = items.map(item => {
      let itemToSend; // make a copy in case calling code keeps a ref to the original
      if (mapTo) {
        itemToSend = new mapTo(item);
      } else {
        itemToSend = Object.assign({}, item);
      }
      Object.keys(itemToSend).forEach(key => {
        // tslint:disable-next-line:triple-equals
        if (itemToSend[key] == null) {
          try {
            delete itemToSend[key];
          } catch (e) {} // yes, ignore it
        }
      });
      return itemToSend;
    });

    return sourceIsArray ? (items as T) : (items[0] as T);
  }
}

export { Interfaces, Validators };
