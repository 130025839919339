import { observable } from "mobx";

export default class CRResponsive {
  @observable deviceType = "";

  constructor() {
    var inst = this;

    var navPrimary = $(".navbar-primary");
    var sidebarMenu = $(".sidebar-menu");
    var hasSidebarMenu = !!sidebarMenu[0];
    var mainContent = $(".main-content");

    // Public: so it can be called from any module to initially
    // have its scrollables set and sized correctly. It will look for any elements
    // with the scrollable-full, scrollable-medium and possibly others' down the road
    // it will read the data-offset attribute from the element and size the height as needed
    inst.resizeScrollables = resizeScrollables;
    function resizeScrollables() {
      var windowHeight = $(window).height(),
        height = windowHeight - navPrimary.height() - 2;

      // check each sidebar for an extra offset
      var menuOffset, listOffset;

      sidebarMenu.find(".scrollable").each(function(x, menu) {
        var $menu = $(menu);
        if ($menu.hasClass("scrollable-smart")) {
          // New opt-in mechanism
          $menu.css("height", windowHeight - ($menu.offset().top - document.body.scrollTop));
        } else {
          // Old mechanism
          menuOffset = $(menu).data("offset") || 0;
          $menu.css("height", height - menuOffset);
        }
      });

      if (inst.deviceType == "desktop" || inst.deviceType == "tablet") {
        $(".scrollable-full").each(function(i, obj) {
          $(obj).css({ height: $(window).height() - $(obj).data("offset") + "px" });
        });
      } else {
        $(".scrollable-full").each(function(i, obj) {
          $(obj).css({ height: "" });
        });
      }
    }

    var enquireConfig = {
      "(min-width: 980px)": {
        match: function() {
          inst.deviceType = "desktop";
        }
      },
      "screen and (min-width: 767px) and (max-width: 979px)": {
        match: function() {
          inst.deviceType = "tablet";
        }
      },
      "screen and (min-width: 600px) and (max-width: 766px)": {
        match: function() {
          inst.deviceType = "phonewide";
        }
      },
      "screen and (max-width: 599px)": {
        match: function() {
          inst.deviceType = "phone";
        }
      }
    };

    // should only be called once and will hookup the inital events and media queries
    function init() {
      // set the intial height of the main sidebar scrollable areas
      // as well as resize any scrollables to the right height
      resizeScrollables();

      for (var key in enquireConfig) {
        if (enquireConfig.hasOwnProperty(key)) {
          enquire.register(key, enquireConfig[key]);
        }
      }

      // attach that resizing will trigger resizeScrollables again
      $(window).resize(resizeScrollables);
    }

    function dispose() {
      for (var key in enquireConfig) {
        if (enquireConfig.hasOwnProperty(key)) {
          enquire.unregister(key, enquireConfig[key]);
        }
      }

      $(window).off("resize", this.resizeScrollables);
    }

    inst.dispose = dispose;

    init();
  }
}
