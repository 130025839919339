import { BroadcastChannel, createLeaderElection } from "broadcast-channel";
import { loadEksApiRoutes } from "./eks";

const eksChannelName = "cr-eks-api-polling";

export const pollForEksRouteChanges = (intervalTime = 60000) => {
  const createChannel = () => {
    const channel = new BroadcastChannel(eksChannelName, {
      idb: {
        onclose: () => {
          // see https://github.com/pubkey/broadcast-channel#readme
          // the onclose event is just the IndexedDB closing.
          // you should also close the channel before creating
          // a new one.
          channel.close();

          createChannel();
        }
      }
    });

    createLeaderElection(channel)
      .awaitLeadership()
      .then(() => {
        window.setInterval(loadEksApiRoutes, intervalTime);
        loadEksApiRoutes();
      });
  };

  createChannel();
};
