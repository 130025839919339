import { action, observable } from "mobx";
import { observer } from "mobx-react-lite";
import React, { createElement } from "react";
import ReactDOM from "react-dom";

class KnockoutBridgedComponent {
  readonly key: string;

  @observable component: any;

  @observable props: any;

  constructor(public readonly domNode: Element) {
    this.key = `${Math.random()}${window.performance.now()}${Math.random()}`;
  }

  @action
  updateComponent(component: any, props: any) {
    this.component = component;
    this.props = props;
  }
}

const bridgedComponents = observable<KnockoutBridgedComponent>([]);

/**
 * Renders a bridged component using ReactDOM portal
 */
export const KnockoutBridgedComponentPortal = observer(({ portalElement }: { portalElement: KnockoutBridgedComponent }) => {
  if (!portalElement.component) {
    return null;
  }
  const element = createElement(portalElement.component, portalElement.props);
  return ReactDOM.createPortal(element, portalElement.domNode);
});

/**
 * Contains all ReactDOM portals required to render knockout bridged components.
 */
export const KnockoutPortal = observer(() => {
  return <>{bridgedComponents.map(e => <KnockoutBridgedComponentPortal key={e.key} portalElement={e} />)}</>;
});

/**
 * Renders a React component at domNode inside React App context (with router and user available)
 * @returns unmount function
 */
export function addKnockoutBridgedComponent(domNode: Element, component?: any, props?: any) {
  const bridgedComponent = new KnockoutBridgedComponent(domNode);
  if (component) {
    bridgedComponent.updateComponent(component, props);
  }
  bridgedComponents.push(bridgedComponent);
  return () => {
    bridgedComponents.remove(bridgedComponent);
  };
}

/**
 * Updates a React component created by @see addKnockoutBridgedComponent
 */
export function updateKnockoutBridgedComponent(domNode: Element, component: any, props?: any) {
  const bridgedComponent = bridgedComponents.find(c => c.domNode === domNode);
  if (!bridgedComponent) {
    throw new Error("Cannot find a knockout bridged component at specified domNode");
  }
  bridgedComponent.updateComponent(component, props);
}