import { Logger } from "@cr/core/logger";

const log = Logger.create("EksRouteRefreshService");

const eksRoutesQueue: (() => void)[] = [];

const eksApiRoutesKey = "cr-eks-api-routes";

const eksApiRoutesLastLoadedKey = "cr-eks-api-routes-last-loaded";

const processEksQueue = () => {
  while (eksRoutesQueue.length > 0) {
    eksRoutesQueue.shift()();
  }
};

export const loadEksApiRoutes = (): Promise<void> =>
  new Promise(resolve => {
    log.debug("Checking if EKS routes are stale...");

    const value = window.localStorage.getItem(eksApiRoutesLastLoadedKey);
    const threshold = new Date().getTime() - app_eksapicheckinterval;

    if (!value || threshold > new Date(value).getTime()) {
      log.debug("Determined routes are stale, refreshing...");
      eksRoutesQueue.push(resolve);

      if (eksRoutesQueue.length === 1) {
        return getEksApiRoutes().then(processEksQueue, processEksQueue);
      }
    } else {
      log.debug("Determined routes are not stale, skipping refresh...");
      resolve();
    }
  });

// https://davidwalsh.name/fetch-with-timeout
async function fetchWithTimeout(url, opts = {}, timeout = 2000) {
  // Create the AbortController instance, get AbortSignal
  const abortController = new AbortController();
  const { signal } = abortController;

  // Make the fetch request
  const _fetchPromise = fetch(url, {
    ...opts,
    signal
  });

  // Start the timer
  const timer = setTimeout(() => abortController.abort(), timeout);

  // Await the fetch with a catch in case it's aborted which signals an error
  try {
    const result = await _fetchPromise;
    clearTimeout(timer);
    return result;
  } catch (e) {
    clearTimeout(timer);
    throw e;
  }
}

const getEksApiRoutes = async () => {
  try {
    const response = await fetchWithTimeout(`${app_eksapiurl}/routetoeks`, { headers: { Accept: "application/json" } });

    if (response.ok) {
      const { routeToEks } = (await response.json()) as { routeToEks: string[] };
      window.localStorage.setItem(eksApiRoutesKey, routeToEks.join("|"));
      log.debug("Refresh complete.");
    } else {
      throw new Error("Failed to load eks routes");
    }
  } catch (e) {
    log.debug("Refresh failed, clearing routes from local storage.");
    window.localStorage.setItem(eksApiRoutesKey, "");
  }

  window.localStorage.setItem(eksApiRoutesLastLoadedKey, new Date().toISOString());
};

const generateEksApiRoutes = (raw: string) => {
  const finalRoutes: { method: string; pattern: RegExp }[] = [];
  const baseRoutes = (raw || "").trim().split("|");

  baseRoutes.forEach(baseRoute => {
    let [rawMethods, rawPattern] = baseRoute.split(":");

    if (typeof rawPattern === "undefined") {
      rawPattern = rawMethods;
      rawMethods = "*";
    }

    if (rawPattern && rawPattern.trim()) {
      const pattern = new RegExp(rawPattern.trim(), "i");

      const methods = rawMethods
        .split(",")
        .map(rawMethod => rawMethod.trim().toLowerCase())
        .filter(rawMethod => rawMethod.length > 0);

      methods.forEach(method => {
        finalRoutes.push({ method, pattern });
      });
    }
  });

  return finalRoutes;
};

const hashCode = (input: string) => input.split("").reduce((prevHash, currVal) => ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0, 0);

const routeCache: { [key: string]: { method: string; pattern: RegExp }[] } = {};

const generateEksApiRoutesCached = () => {
  const raw = window.localStorage.getItem(eksApiRoutesKey) || "";
  const key = hashCode(raw);

  if (typeof routeCache[key] !== "undefined") {
    return routeCache[key];
  }

  routeCache[key] = generateEksApiRoutes(raw);

  return routeCache[key];
};

const isRouteOverriddenToEksApi = (url: string, method = "get") =>
  generateEksApiRoutesCached().some(
    entry => (entry.method === "*" || entry.method === (method || "").trim().toLowerCase()) && entry.pattern.test(url)
  );

export const rewriteUrlToEksApi = (url: string, method = "get") =>
  isRouteOverriddenToEksApi(url, method) ? url.split(app_apiurl).join(app_eksapiurl) : url;
