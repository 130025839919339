import React from "react";
import { IconProps } from "@material-ui/core";
import { LaunchIcon } from "@cr/icons/Launch";
import { ThemeProvider } from "@cr/material-ui-theme-provider";
import GlobalStyle from "../globalStyle";

const map = {
  launch: LaunchIcon
};

const MuiIcon = ({ children, name, ...rest }: IconProps & { name: string }) => {
  const Icon = map[name];

  return (
    <>
      <GlobalStyle styles=".cr-mui-wrapper-icon > * { display: inline-block; }" />
      <span className="cr-mui-wrapper-icon" style={{ verticalAlign: "middle" }}>
        <ThemeProvider skipGlobalStyles>
          <Icon {...rest}>{children || null}</Icon>
        </ThemeProvider>
      </span>
    </>
  );
};

export const knockoutName = "cr-mui-icon";

export default MuiIcon;
