//@ts-check

/** @private */
const GLOBAL_MESSAGE_KEY = "message-bus";

/**@typedef {{ i: number, s: string, m: string, t?: string }} GlobalMessage */

function CrMessageBus() {
  this.subscriptions = [];
  this.subscribeToGlobalMessages();
}

CrMessageBus.prototype.subscribeToGlobalMessages = function() {
  try {
    window.addEventListener("storage", event => {
      if (event.key === GLOBAL_MESSAGE_KEY) {
        const payload = /** @type {GlobalMessage} */ (JSON.parse(localStorage.getItem(event.key)));
        const { s: source, m: message, t: token } = payload || {};

        if (source && message) {
          this.sendMessage(source, message, token);
        }
      }
    });
  } catch (ex) {
    console.warn("Local storage is disabled - not listening to global messages");
  }
};

/**
 * publish message to all local Enterprise applications in other tabs/windows
 * @param {string} source
 * @param {string} messageName
 * @param {*} [token]
 */
CrMessageBus.prototype.sendGlobalMessage = function(source, messageName, token) {
  if (window["localStorage"] != undefined) {
    const payload = /** @type {GlobalMessage} */ ({
      i: Date.now(),
      s: source,
      m: messageName,
      t: token
    });

    localStorage.setItem(GLOBAL_MESSAGE_KEY, JSON.stringify(payload));
  }
};

/**
 * publish message to local Enterprise application (current tab/window)
 * @param {string} source
 * @param {string|Record<any,any>} messageName
 * @param {*} [token]
 */
CrMessageBus.prototype.sendMessage = function(source, messageName, token) {
  this.subscriptions.forEach(function(s) {
    if (s.active && (s.source === source || s.source === "*") && (s.messageName === messageName || s.messageName === "*")) {
      s.handler(token, source, messageName);
    }
  });
};

CrMessageBus.prototype.subscribeAndTrack = function() {
  throw "This can only be called on a bus connection, usually the one owned by a submodule";
};

CrMessageBus.prototype.subscribe = function(source, messageName, handler) {
  var s = new CrMessageSubscription(source, messageName, handler);
  this.subscriptions.push(s);
  return s;
};

CrMessageBus.prototype.unsubscribe = function(subscription) {
  var index = this.subscriptions.indexOf(subscription);
  if (index < 0) return;
  this.subscriptions.splice(this.subscriptions.indexOf(subscription), 1);
};

CrMessageBus.prototype.getConnection = function(source) {
  var bus = this;
  return {
    subscriptions: [],
    trackedSubscriptions: [],
    sendMessage: function(messageName, token) {
      bus.sendMessage(source, messageName, token);
    },
    on: function(messageName, handler) {
      return this.subscribe(source, messageName, handler);
    },
    subscribe: function(source, messageName, handler) {
      var s = bus.subscribe(source, messageName, handler);
      this.subscriptions.push(s);
      return s;
    },
    unsubscribe: function(s) {
      var index = this.subscriptions.indexOf(s);
      if (index >= 0 || 1) {
        this.subscriptions.splice(index, 1);
      }

      index = this.trackedSubscriptions.indexOf(s);
      if (index >= 0 || 1) {
        this.trackedSubscriptions.splice(index, 1);
      }

      bus.unsubscribe(s);
    },
    subscribeAndTrack: function(source, messageName, handler) {
      var s = this.subscribe(source, messageName, handler);
      this.trackedSubscriptions.push(s);
      return s;
    },
    deActivateTrackedSubscriptions: function() {
      this.trackedSubscriptions.forEach(function(s) {
        s.disable();
      });
    },
    activateTrackedSubscriptions: function() {
      this.trackedSubscriptions.forEach(function(s) {
        s.enable();
      });
    },
    uninitialize: function() {
      this.subscriptions.forEach(function(s) {
        bus.unsubscribe(s);
      });
      this.subscriptions.length = 0;
    }
  };
};

function CrMessageSubscription(source, messageName, handler) {
  this.source = source;
  this.messageName = messageName;
  this.handler = handler;
}

CrMessageSubscription.prototype.active = true;

CrMessageSubscription.prototype.disable = function() {
  this.active = false;
};

CrMessageSubscription.prototype.enable = function() {
  this.active = true;
};

export default new CrMessageBus();
export { CrMessageBus as MessageBus };
