import React, { createElement } from "react";
import { Button, ButtonProps } from "@material-ui/core";
import { ThemeProvider } from "@cr/material-ui-theme-provider";
import GlobalStyle from "../globalStyle";
import { DeleteIcon } from "@cr/icons/Delete";
import { DeleteOutlineIcon } from "@cr/icons/DeleteOutline";

const iconMap = {
  delete: {
    regular: DeleteIcon,
    outlined: DeleteOutlineIcon
  }
};

const MuiButton = ({ children, innerHTML, icon, outlined, ...rest }: ButtonProps & { innerHTML: string; icon?: string; outlined?: boolean }) => (
  <>
    {/* ThemeProvider wraps a div around its content, override to be inline */}
    <GlobalStyle styles=".cr-mui-button-wrapper > * { display: inline-block; }" />
    <span className="cr-mui-button-wrapper">
      <ThemeProvider skipGlobalStyles>
        <Button {...rest}>
          {icon ? <>{createElement(iconMap[icon][outlined ? "outlined" : "regular"])}&nbsp;</> : null}
          {innerHTML ? <span dangerouslySetInnerHTML={{ __html: innerHTML }} /> : null}
          {children || null}
        </Button>
      </ThemeProvider>
    </span>
  </>
);

export const knockoutName = "cr-mui-button";

export default MuiButton;
