function initializeKnockout(ko: any) {
  ko.bindingProvider.instance = ko.bindingProvider.interpolate;

  require("third_party_libraries/knockout/knockout-amd-helpers");
  require("shared/knockout-extensions/knockout-extensions-all");
  require("app/util/knockoutIntegration");

  const reactComponents = [
    require("app/util/lazyImage"),
    require("app/util/profileImage"),
    require("app/util/muiButton"),
    require("app/util/muiTooltip"),
    require("app/util/muiSnackbar"),
    require("app/util/muiAlert"),
    require("app/util/muiModal"),
    require("app/util/muiIcon"),
    require("app/util/muiTypography"),
    require("app/util/muiMenu")
  ];

  reactComponents.forEach(({ default: ComponentToRender, knockoutName }) => {
    ko.components.register(knockoutName, {
      viewModel: function ({ $raw, ...params }) {
        this.componentProps = ko.toJS(params);
        this.ComponentToRender = ComponentToRender;
      },
      template: "<!-- ko renderReactComponent: { component: ComponentToRender, props: componentProps } --><!-- /ko --><span></span>"
    });
  });

  ko.amdTemplateEngine.defaultSuffix = "";
  ko.amdTemplateEngine.defaultPath = "";

  // configure knockout validation settings
  ko.validation.init({
    insertMessages: true,
    decorateElement: true,
    errorMessageClass: "error",
    errorElementClass: "error",
    errorClass: "error",
    errorsAsTitle: true,
    parseInputAttributes: false,
    messagesOnModified: true,
    decorateElementOnModified: true,
    decorateInputElement: true
  });
}

export default initializeKnockout;
