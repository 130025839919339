// tslint:disable
import ApiTransport from "app/util/apiTransport";
import messageBus from "app/util/messageBus";
import util from "app/util/miscUtil";
import { SystemDictionaryCache } from "app/util/systemDictionary";
import transport from "app/util/transport";
import Cookies from "js-cookie";
import { action, computed, observable, toJS } from "mobx";
import { User as OidcUser } from "oidc-client";
import { UserInterfaceInfo } from "./userInterfaceInfo";
import { UserLocationInfo } from "./userLocationInfo";
import { BundlesPermissions, ContactsPermissions } from "../security";

const api = new ApiTransport();
const DefaultHomepageKey = "framework/header::homepage";
const SelectedThemeIdKey = "framework/header::color";
const CRUD_COOKIE = "crud";

export interface UserFeature {
  ChannelId: string;
  FeatureKey: string;
}

export interface UserPermission {
  FeatureKey: string;
  Name: string;
  PermissionId: number;
  ChannelId: string;
}

interface UserData {
  _features: UserFeature[];
  _featuresX: UserFeature[];
  _permissions: UserPermission[];
  _organizations: { Id: string; Name: string }[];
  _reports: number[];
  _user: any;
  id: number;
  isx: string | boolean;
  switched: string | boolean;
  type: string;
}

export interface SSOUser {
  readonly userid: string;
  readonly orgid: string;
  readonly access_token: string;
  readonly id_token: string;
  readonly idle_timeout?: number;
  readonly email?: string;
  readonly displayName?: string;
  readonly isAssessmentsUser: boolean;
  readonly scope: string[];
  /**
   * Computed property checking if access_token is expired
   */
  readonly expired: boolean;
}

export enum UserType {
  Administrator = "Administrator",
  Employee = "Employee",
  Generic = "Generic",
  Client = "Client",
  Anonymous = "Anonymous"
}

const AdminTypeId = 575;
const ProviderTypeId = 572;
const GenericTypeId = 810;
const ClientTypeId = 20;

const EmptyUser = Object.freeze({ _features: [], _featuresX: [], _permissions: [], _organizations: [], _reports: [], _user: {} });

export const isAutomatedClient = (value: any) => {
  if (typeof value === "object" && value !== null && typeof value.scope !== "undefined") {
    return (typeof value.scope === "string" ? [value.scope] : [...value.scope]).indexOf(app_api_scope) !== -1;
  }

  return false;
};

export const getSSOUserFromCookie = () => {
  const currentCookie = Cookies.get(CRUD_COOKIE);

  if (currentCookie) {
    try {
      const parsedCookie = JSON.parse(currentCookie);

      if (parsedCookie && typeof parsedCookie.ssoUser === "object" && parsedCookie.ssoUser !== null) {
        return { profile: parsedCookie.ssoUser } as OidcUser;
      }
    } catch (e) {}
  }
};

export class User {
  static StoreName = Symbol("User");

  private subscriptions: any[] = [];

  @observable private _homepage: string = localStorage.getItem(DefaultHomepageKey);
  @observable private _themeId: string = localStorage.getItem(SelectedThemeIdKey);
  @observable private _type: number = null;
  @observable private _userData: UserData = observable(Object.assign(EmptyUser));

  // this is the master object of supported "symbols" which are more or less
  // strings that we can 'localize' if we have a custom SymbolsJson object stored
  // with the organizations configuration in their contact settings
  readonly symbols = {
    client: {
      singular: "Client",
      plural: "Clients"
    },
    session: {
      singular: "Session",
      plural: "Sessions"
    },
    clinical: {
      singular: "Clinical"
    }
  };

  @observable location = new UserLocationInfo();

  @observable interface = new UserInterfaceInfo();

  /** CentralReach organization ID. */
  @observable crOrganizationId = 65277;

  //#region SSO settings

  @observable ssoUser: SSOUser = null;

  @computed
  get isAutomatedClient() {
    return isAutomatedClient(this.ssoUser);
  }

  @computed
  get profileUrl(): string {
    return this.identityUrl("/profile/user/basics");
  }

  @computed
  get securitySettingsUrl(): string {
    return this.identityUrl("/profile/user/security");
  }

  @computed
  get organizationProfileUrl(): string {
    return this.identityUrl(`/profile/organizations/${this.organizationCode}`);
  }

  identityUrl(url: string): string {
    return `${window.app_ssoAuthority}/redirect?to=${encodeURIComponent(url)}&features=${encodeURIComponent(this.userSSOFeatures)}`;
  }

  @computed
  get userSSOFeatures() {
    const enabledFeatures = [];

    const checkFeatures = [
      {
        channelId: "enterprise_package",
        feature: "api_and_delegated_authentication"
      }
    ];

    checkFeatures.forEach(checkFeature => {
      if (user.hasFeature(checkFeature.channelId, checkFeature.feature)) {
        enabledFeatures.push(`${checkFeature.channelId}:${checkFeature.feature}`);
      }
    });

    return btoa(enabledFeatures.join("-"));
  }

  //#endregion

  @computed
  get isUserAdmin() {
    return this._type == AdminTypeId;
  }

  @computed
  get isUserProvider() {
    return this._type == ProviderTypeId;
  }

  @computed
  get isUserClient() {
    return this._type == ClientTypeId;
  }

  @computed
  get isUserBundlesAuthor() {
    return CRgetBool(this._userData._user.IsBundlesAuthor);
  }

  @computed
  get isUserOrganization() {
    return CRgetBool(this._userData._user.IsOrganization);
  }

  @computed
  get isUserEmployee() {
    return CRgetBool(this._userData._user.IsEmployee);
  }

  @computed
  get isUserSupportAdmin() {
    return CRgetBool(this._userData._user.IsSupportAdmin);
  }

  @computed
  get isUserCurrentlyTheOrganization() {
    return this.isUserOrganization && this.id === this.organizationId;
  }

  @computed
  get contactId() {
    return CRgetInt(this._userData.id);
  }

  @computed
  get preferredName() {
    return this._userData._user.ChosenName && this._userData._user.ChosenName.trim() ? this._userData._user.ChosenName.trim() : this.displayName;
  }

  @computed
  get displayName() {
    return CRgetString(this._userData._user.DisplayName);
  }

  @computed
  get firstName() {
    return CRgetString(this._userData._user.FirstName);
  }

  @computed
  get lastName() {
    return CRgetString(this._userData._user.LastName);
  }

  @computed
  get email() {
    return CRgetString(this._userData._user.Email);
  }

  @computed
  get features() {
    return this._userData._features;
  }

  @computed
  get featuresX() {
    return this._userData._featuresX;
  }

  @computed
  get homepage() {
    const page = "account";
    return this.isAdmin() ? "manage" : this._homepage || page;
  }

  set homepage(homepage) {
    this._homepage = homepage;
    localStorage.setItem(DefaultHomepageKey, homepage);
  }

  @computed
  get isAuthenticated() {
    return Boolean(this.ssoUser && this._userData.id > 0);
  }

  /**
   * This returns true when the user is authenticated and loaduserdata has completed
   */
  @computed
  get fullyLoaded() {
    return this.isAuthenticated && Object.keys(this._userData._user).length > 0;
  }

  @observable crSessionMonitorLoaded = false;

  @action 
  setSessionMonitorLoaded = (value: boolean) => { 
    this.crSessionMonitorLoaded = value; 
  };

  @computed
  get isImpersonating() {
    return CRgetBool(this._userData.isx);
  }

  @computed
  get isSwitched() {
    return CRgetBool(this._userData.switched);
  }

  @computed
  get id() {
    return CRgetInt(this._userData.id);
  }

  @computed
  get organizationCode(): number {
    return this._userData._user.OrganizationCode;
  }

  @computed
  get isAssessmentsUser(): boolean {
    return this.ssoUser && this.ssoUser.isAssessmentsUser;
  }

  @computed
  get isIntegratedAssessmentsUser(): boolean {
    if (this.isUserClient) {
      return false;
    }

    const hasAssessmentsFeatures = this.hasFeature("bundles", "cr_assessments") && this.hasFeature("bundles", "cr_assessments_integration");

    if (!hasAssessmentsFeatures) {
      return false;
    }

    const hasAssessmentsPermission =
      this.hasPermission(BundlesPermissions.PrimaryAssessor) ||
      this.hasPermission(BundlesPermissions.SecondaryAssessor) ||
      this.hasPermission(BundlesPermissions.AssessmentsReviewer);

    if (!hasAssessmentsPermission) {
      return false;
    }

    if (!this.isAssessmentsUser) {
      return false;
    }

    if (this.isImpersonating) {
      return false;
    }

    return true;
  }

  @computed
  get assessmentsUserUrl(): string {
    const featureEnabled = this.hasFeature("bundles", "cr_assessments");

    if (!featureEnabled) {
      return "https://centralreach.com/book-demo-assessments/?utm_source=ent_platform&utm_medium=website&utm_campaign=assessments_demo";
    }

    if (!this.isAssessmentsUser) {
      return "https://community.centralreach.com/s/article/how-to-access-cr-assessments";
    }

    const isIntegrated = this.hasFeature("bundles", "cr_assessments_integration");

    if (!isIntegrated) {
      return assessments_web_url;
    }

    const permissionsEnabled =
      this.hasPermission(BundlesPermissions.PrimaryAssessor) ||
      this.hasPermission(BundlesPermissions.SecondaryAssessor) ||
      this.hasPermission(BundlesPermissions.AssessmentsReviewer);

    if (!permissionsEnabled) {
      return "https://community.centralreach.com/s/article/how-to-integrate-cr-assessments-with-centralreach";
    }

    return "#bundles/cr-assessments";
  }

  @computed
  get organizationId(): number {
    return CRgetInt((this.firstOrganization && this.firstOrganization.Id) || this._userData._user.OrganizationId);
  }

  @computed
  get hasValidOrganizationId(): boolean {
    return !!this._userData._user.IsOrganization || (!!this.organizationId && this.organizationId > 0 && this.organizationId !== this.id);
  }

  @computed
  get organizationName() {
    return CRgetString((this.firstOrganization && this.firstOrganization.Name) || this._userData._user.OrganizationName);
  }

  @computed
  get permissions() {
    return this._userData._permissions;
  }

  @computed
  get profileImageUrl() {
    return util.imgProfileUrl(CRgetString(this._userData._user.ImgProfile));
  }

  @computed
  get themeId() {
    return this.isClient() ? "3" : this._themeId ? this._themeId : "4";
  }

  set themeId(themeId) {
    this._themeId = themeId;
    localStorage.setItem(SelectedThemeIdKey, themeId);
  }

  @computed
  get type(): UserType {
    switch (this._type) {
      case AdminTypeId:
        return UserType.Administrator;

      case ProviderTypeId:
        return this.isEmployee() ? UserType.Employee : UserType.Generic;

      case GenericTypeId:
        return UserType.Generic;

      case ClientTypeId:
        return UserType.Client;

      default:
        return UserType.Anonymous;
    }
  }

  @computed
  get reports() {
    return this._userData._reports;
  }

  @computed
  get maximumMillisecondsNoActivity() {
    if (this.ssoUser && this.ssoUser.idle_timeout) {
      return this.ssoUser.idle_timeout * 60000;
    }

    //15 minutes
    return 900000;
  }

  constructor() {
    this.subscriptions = [messageBus.subscribe("*", "report-permissions-updated", this.fetchUserReportData)];
  }

  dispose() {
    this.subscriptions.forEach(subscription => {
      messageBus.unsubscribe(subscription);
    });
  }

  // #region Legacy "is-getters"
  // These should be converted to proper computed getters, but the change was too broad so leaving as-is for now
  // (besides, most of these don't change once the user is set anyway)

  /** @deprecated use `isAuthenticated` */
  isLoggedIn() {
    return this.isAuthenticated;
  }

  isAdmin() {
    return this._type == AdminTypeId;
  }

  isProvider() {
    return this._type == ProviderTypeId;
  }

  isClient() {
    return this._type == ClientTypeId;
  }

  isOrganization() {
    return CRgetBool(this._userData._user.IsOrganization);
  }

  isEmployee() {
    return CRgetBool(this._userData._user.IsEmployee);
  }

  isBundlesAuthor() {
    return CRgetBool(this._userData._user.IsBundlesAuthor);
  }

  isSupportAdmin() {
    return CRgetBool(this._userData._user.IsSupportAdmin);
  }

  isCRSupportUser() {
    return CRgetInt(this._userData._user.OrganizationId) === this.crOrganizationId;
  }

  isCurrentlyTheOrganization() {
    return this.isOrganization() && this.getId() === this.getOrganizationId();
  }

  isNetworkProvider() {
    return this.isProvider() && !this.isEmployee();
  }

  //#endregion

  /** @deprecated use property instead of get function */
  getEmail() {
    return this._userData._user.Email;
  }

  /** @deprecated use property instead of get function */
  getId() {
    return CRgetInt(this._userData.id);
  }

  /** @deprecated use property instead of get function */
  getImgProfile() {
    if (this._userData && this._userData._user) {
      return util.imgProfileUrl(CRgetString(this._userData._user.ImgProfile));
    }
    return null;
  }

  /** @deprecated use property instead of get function */
  getImgOrg() {
    return util.imgOrgUrl(CRgetString(this._userData._user.ImgOrg));
  }

  /** @deprecated use property instead of get function */
  getImgOrgDirect() {
    return util.imgOrgUrlDirect(CRgetString(this._userData._user.ImgOrg));
  }

  /** @deprecated use property instead of get function */
  getFromName() {
    return CRgetString(this._userData._user.DisplayName);
  }

  /** @deprecated use property instead of get function */
  getName = this.getFromName;

  /** @deprecated use property instead of get function */
  getTypeId() {
    return CRgetInt(this._userData._user.TypeId);
  }

  /** @deprecated use property instead of get function */
  getOrganizationId(): any {
    return CRgetInt(this._userData._user.OrganizationId);
  }

  /** @deprecated use property instead of get function */
  getOrganizationName() {
    return CRgetString(this._userData._user.OrganizationName);
  }

  /** @deprecated use property instead of get function */
  getIsBillingOrganization() {
    return CRgetBool(this._userData._user.IsBillingOrganization);
  }

  /** @deprecated use property instead of get function */
  getIsBillingAgent() {
    return CRgetBool(this._userData._user.IsBillingAgent);
  }

  /** @deprecated use property instead of get function */
  getBillingOrganizationId() {
    return CRgetInt(this._userData._user.BillingOrganizationId);
  }

  /** @deprecated use property instead of get function */
  getLoginInactivityWarning() {
    if (this.ssoUser && this.ssoUser.idle_timeout) {
      return this.ssoUser.idle_timeout;
    }

    return CRgetInt(this._userData._user.LoginInactivityWarning);
  }

  /** @deprecated use property instead of get function */
  getSchedulingLockDate() {
    return CRgetString(this._userData._user.SchedulingLockDate);
  }

  /** @deprecated use property instead of get function */
  canScheduleHidden() {
    return this._userData._user.CanScheduleHidden === 1;
  }

  // this is a subset of the main permissions array
  // we'll only pluck out the distinct channelId's with a matching "module" feature key
  // and then return a simple array of channelIds that the user has access to
  getModulePermissions() {
    const permissions = (this._userData && this._userData._permissions) || [];
    return [...new Set(permissions.filter(x => x.FeatureKey == "module").map(x => x.ChannelId))];
  }

  // array of organizations, this is only applicable to clients
  // who may be in network with more than one organization
  getOrganizations() {
    return CRgetString(this._userData._organizations);
  }

  // the admin support account limit is a feature key with a common naming convention
  // we'll parse out the actual feature-based limit from this name
  getSupportAdminAccountLimit() {
    const features = (this._userData && this._userData._features) || [];
    try {
      const matches = features.filter(f => /^support_admins_/.test(f.FeatureKey) && f.ChannelId == "tasks");
      return matches[0].FeatureKey.replace(/^support_admins_/, "");
    } catch (ex) {
      return 2;
    }
  }

  // check the array of features for the availability of a certain feature
  // given a channelId and feature key
  hasFeature(channelId: string, feature: string) {
    return this.features.some(x => x.ChannelId == channelId && x.FeatureKey == feature);
  }

  // check the array of features of CR Admin user that impersonates the current user
  impersonateHasFeature(channelId: string, feature: string) {
    return this.featuresX.some(x => x.ChannelId == channelId && x.FeatureKey == feature);
  }

  // check if the user has a specific permission by checking
  // the permissions array for the existence of an obj with that permission id property
  hasPermission(permissionId) {
    return this.permissions.some(x => x.PermissionId == permissionId);
  }

  // check the users permission to see if they have access to the channel
  // currently "account" is implied and should be exempt
  hasModule(channelId) {
    return channelId === "account" || this.permissions.some(x => x.ChannelId == channelId);
  }

  hasReport(reportId) {
    return this.reports.some(x => x === reportId);
  }

  @action
  private setUser = async (ssoUser: OidcUser) => {
    if (!ssoUser) {
      this.ssoUser = null;
      return;
    }

    const timeout = parseInt(ssoUser.profile.timeout, 10);

    let isAssessmentsUser = false;
    if (ssoUser && ssoUser.profile && ssoUser.profile.orgids) {
      let orgIds = ssoUser.profile.orgids;
      if (!Array.isArray(orgIds)) {
        orgIds = [orgIds];
      }
      orgIds.forEach(org => {
        try {
          if (ssoUser.profile["orgs/" + org].features.includes("assessments")) isAssessmentsUser = true;
        } catch (e) {}
      });
    }

    const scope = [];

    if (ssoUser && ssoUser.profile && ssoUser.profile.scope) {
      scope.push(...(Array.isArray(ssoUser.profile.scope) ? ssoUser.profile.scope : [ssoUser.profile.scope]));
    }

    this.ssoUser = {
      access_token: ssoUser.access_token,
      id_token: ssoUser.id_token,
      userid: ssoUser.profile.sub,
      orgid: ssoUser.profile.orgid,
      email: ssoUser.profile.email,
      displayName: ssoUser.profile.displayName,
      idle_timeout: isNaN(timeout) ? null : timeout,
      isAssessmentsUser,
      scope
    } as SSOUser;

    // Get expired property from OidcUser
    // Set it to configurable to help QA test expiration
    Object.defineProperty(this.ssoUser, "expired", { get: () => ssoUser.expired, configurable: true });

    // don't even bother trying to load the user data if we don't have the crud cookie
    // that indicates we were logged in at one point recently
    if (this.readLoginInfoFromCookie()) {
      try {
        await this.fetchUserData();
      } catch (ex) {
        // even then, we may still not be logged in
        console.error("Failed to load user data", ex);
      }
    }
  };

  @action
  private fetchUserData = () => {
    return transport.successRequest(
      "framework",
      "loaduserdata",
      {},
      action(({ user, organizations, permissions, features, featuresX }) => {
        Object.assign(this._userData, {
          _user: user,
          _organizations: organizations,
          _features: features,
          _featuresX: featuresX,
          _permissions: permissions
        });

        if (user.SymbolJson) {
          try {
            const symbols = JSON.parse(user.SymbolJson);
            Object.assign(this.symbols, symbols);
          } catch (e) {
            console.error(e);
          }
        }
      }),
      action(() => this.invalidate())
    );
  };

  @action
  private fetchUserReportData = () => {
    api.get("reporting/reports/me").then(
      action(({ reportIds }) => {
        Object.assign(this._userData, { _reports: reportIds });
      })
    );
  };

  async initialize(ssoUser: OidcUser) {
    await this.setUser(ssoUser);

    if (this.isAuthenticated) {
      this.fetchUserReportData();
      SystemDictionaryCache.loadAppDictionaries();
      cr.setUser(this);
      messageBus.sendMessage("framework", "authenticated", { isAuthenticated: true, user: this });
    }
  }

  @action
  invalidate() {
    this._userData = Object.assign(EmptyUser);
    this._type = null;
    this.ssoUser = null;

    Cookies.remove(CRUD_COOKIE);
    this.dispose();
    messageBus.sendMessage("user", "session-invalidated");
  }

  parseSymbol(path, fallback = "") {
    return path.split(".").reduce((obj, piece) => obj && obj[piece], this.symbols) || fallback;
  }

  @action
  readLoginInfoFromCookie(): boolean {
    try {
      const udCookie = Cookies.get(CRUD_COOKIE);

      this._type = null;

      if (udCookie) {
        try {
          const cookieData = JSON.parse(udCookie);

          // only reset all user data on initial load or when switching users
          // otherwise, this is coming from token refresh and this would blink the page
          if (!this._userData || (cookieData && this._userData.id !== cookieData.id)) {
            this._userData = Object.assign({}, EmptyUser, cookieData);
          } else {
            this._userData = Object.assign({}, this._userData || {}, cookieData || {});
          }

          this._type = CRgetInt(this._userData.type);
          return +this._userData.id > 0;
        } catch (e) {
          console.error(e);
        }
      }
    } catch (ex) {
      console.warn("Error reading info from cookie", ex);
    }

    return false;
  }

  // check if the org is requesting the users geographic location
  // this is enabled at the org settings level and works together
  // with a feature key that must be currently enabled for the users org
  requestGeoLocation() {
    return this.hasFeature("reporting", "session_tracking") && CRgetBool(this._userData._user.RequestGeoLocation);
  }

  private get firstOrganization() {
    const orgs = toJS(this._userData)._organizations;
    return orgs.length && orgs[0];
  }
}

const user = new User();

window["CRUser"] = user;

export default user;
