import { action, observable, computed, autorun, observe, reaction, runInAction, IObservableArray, isObservableArray } from "mobx";
import { mappable, Mappable, disposable, Disposable, validated, Validated } from "app/util/mobx-util";

import { Api } from "./apiTransport";

/**

This is a client-side utility for pulling down and using the DB-backed system dictionaries. 

It has a simple singleton-based cache because 99% of the time, Dictionary values shouldn't change. As of writing, we also have day-long caching on the service.   

In the future, we could choose to just preload all relevant dictionaries in the app load. There will never be many of them, and it could simplify retrieving them in app use (i.e., avoid using promises where they are needed). 

Until then, I'd recommend just using SystemDictionaryCache.getAndCache when you need one, unless you have a specific reason to avoid the singleton caching.

 */

//region Internal Utility Methods
const getDictionaryServiceEndpoint = dictionaryName => `system/dictionary/${dictionaryName}`;
/**
 * Works basically same as other "createFromResponse", but it does some additional setup for the dictionary map-based key lookup.
 *
 * @param dictionaryJson - should be data received from getDictionaryServiceEndpoint (or shaped exactly like it)
 */
const createFromResponse = (dictionaryJson: SystemDictionary): SystemDictionary => {
  const dict = new SystemDictionary(dictionaryJson.name);
  dictionaryJson.entries.forEach(e => {
    const entry = new SystemDictionaryEntry(e);
    dict.map.set(entry.key, entry);
  });
  return dict;
};
//endregion

@mappable
/** A key-value pair in a SystemDictionary. */
export class SystemDictionaryEntry {
  /** The database identifier for this entry. */
  public id: number = -1;
  /** The key for this entry. Should be unique in the dictionary. */
  public key: string = "";
  /** The value of the dictionary entry. Can often be considered the display value for the entry. */
  public value: string = "";
  /** Optional. The ordinal value, which can be used to order the dictionary options in a selectable list. */
  public ordinal: number = null;
  /** Whether or not the option is intended to be selectable by a user. May be false if you have archived/system-only options to keep for referential integreity. **/
  public userSelectableOption: boolean = false;
  /** Whether or not this option should be considered the default value for the dictionary entry options. */
  public defaultOption: boolean = false;

  /**
   * Creates an instance with optional initial values.
   * @param {*} [initialValues=null]  - Any values will be passed to mapResponse to populate the instance.
   */
  constructor(initialValues: any = null) {
    if (initialValues) {
      this.mapResponse(initialValues);
    }
  }
}

/** A DB-backed dictionary, typically used for storing lookup values. */
export class SystemDictionary {
  /** The name of the dictionary, which is used as a natural key in the DB. */
  public name: string;

  /** Dictionary will load entries into this map using each entry's key as key. This is to leverage perf of Map for lookups. */
  public map: Map<string, SystemDictionaryEntry> = new Map();

  /** List of name-value pairs for this dictionary. */
  public get entries(): SystemDictionaryEntry[] {
    return Array.from(this.map.values());
  }

  /** List of selectable name-value pairs for this dictionary. */
  public get selectableEntries(): SystemDictionaryEntry[] {
    return Array.from(this.map.values()).filter(e => e.userSelectableOption);
  }

  constructor(name: string) {
    this.name = name;
  }

  /** Gets the first entry in the dictionary marked as default. */
  @computed
  public get defaultEntry() {
    return this.entries.find(e => e.defaultOption);
  }

  /**
   * Shortcut to find first matching entry with key. Pass defaults to ensure you always get an entry instance back, to avoid null checking for basic scenarios. Will return a new SystemDictionaryEntry with these values.
   *
   * @param {string} key - key to lookup entry for.
   * @param {string} [defaultKey] - Default entry key in case matching entry isn't found.
   * @param {string} [defaultValue] - Default entry value in case matching entry isn't found.
   */
  public getByKey(key: string, defaultKey?: string, defaultValue?: string): SystemDictionaryEntry {
    return this.map.get(key) || new SystemDictionaryEntry({ key: defaultKey, value: defaultValue });
  }

  /**
   * Creates and initiates loading of the requested dictionary.
   *
   * @param {string} dictionaryName - Name of dictionary to get. Must match a configured dictionary in the database.
   */
  public static get(dictionaryName: string): Promise<SystemDictionary> {
    // We intentionally are not doing JS-level caching here. There is service-level caching (including a browser client-cache policy). However, in perf critical scenarios, consider caching a local/in-memory copy of the dictionary for particular purposes (such as for large grid per-row lookups).
    return new Promise((resolve, reject) => {
      if (!dictionaryName) {
        throw new Error("Must provide a dictionary name value.");
      }

      Api.single<SystemDictionary>(getDictionaryServiceEndpoint(dictionaryName)).then(resp => {
        return resolve(createFromResponse(resp));
      });
    });
  }
}

const cachedDictionaryies: Map<string, SystemDictionary> = new Map();
let successfullyLoadedAppDictionaries = false;
/** Used to preload/cache system dictionaries in memory for use within the app. There is no expiration mechanism built in, but you can manage that for your dictionary and call preload again to reload one or more dictionaries. */
export class SystemDictionaryCache {
  /**
   * Preloads (or reloads) each dictionary given and caches them in memory.
   * @param {...string[]} dictionaryNames - One or more dictionaries to load.
   */
  public static preload(...dictionaryNames: string[]) {
    dictionaryNames.forEach(dictionaryName => {
      SystemDictionary.get(dictionaryName)
        .then(dict => cachedDictionaryies.set(dictionaryName, dict))
        .catch(err => console.error(`Could not load System Dictionary '${dictionaryName}'.`));
    });
  }

  /**
   * Gets the desired cached dictionary. Will throw by default if not found.
   * @param {string} dictionaryName - Name of dictionary to get--should have been preloaded using preload.
   */
  public static get(dictionaryName: string, throwIfNotFound: boolean = true): SystemDictionary {
    if (!dictionaryName) {
      throw new Error("Must provide a dictionary name value.");
    }
    const cachedDictionary = cachedDictionaryies.get(dictionaryName);
    if (!cachedDictionary && throwIfNotFound) {
      throw new Error(
        `Could not find cached System Dictionary '${dictionaryName}'. ` + successfullyLoadedAppDictionaries
          ? ""
          : "App dictionaries were not loaded as expected. You may want to check that."
      );
    }
    return cachedDictionary;
  }

  /**
   * Gets a promise for a dictionary that will resolve immediately if the dictionary is loaded in the cache already. If not already loaded, will load from API and return, after caching for future use. This is useful if you may not be sure if a dictionary is loaded or not.
   * @param {string} dictionaryName - Name of dictionary to get.
   */
  public static getAndCache(dictionaryName: string): Promise<SystemDictionary> {
    return new Promise((resolve, reject) => {
      const dict = SystemDictionaryCache.get(dictionaryName, false);
      if (dict) {
        return resolve(dict);
      }
      SystemDictionary.get(dictionaryName).then(dict => {
        cachedDictionaryies.set(dictionaryName, dict);
        return resolve(dict);
      });
    });
  }

  /**
   * This is called during app loader setup to get and cache all dictionaries that we want to be available before initializing any modules.
   *
   * To add more, just add another Api.single call as below with the dictionary name.
   */
  public static loadAppDictionaries(): Promise<void> {
    return new Promise((resolve, reject) => {
      const dictionaryNames = ["InsuranceEligibilityStatus"];

      // Initialize the dictionaries with something
      dictionaryNames.forEach(name => cachedDictionaryies.set(name, new SystemDictionary(name)));

      Promise.all(dictionaryNames.map(name => Api.single<SystemDictionary>(getDictionaryServiceEndpoint(name))))
        .then(results => {
          results.forEach(dict => {
            cachedDictionaryies.set(dict.name, createFromResponse(dict));
          });
          successfullyLoadedAppDictionaries = true;
          return resolve();
        })
        .catch(err => {
          console.error(`Problem loading App Dictionaries.`, err);
          return resolve();
        });
    });
  }
}
