import { decorator } from "./mobx-util.decoratorHelpers";
import { runInAction } from "mobx";
import { getLocalMomentFromServerDate } from "app/util/apiTransport";

export * from "./mobx-util.validation";
export * from "./mobx-util.components";

/*
    The @mappable decorator is used to add convenience methods for copying values from a JSON response to the object it is applied to.

    Usage: 
      To map an object with only primitive type members:
        @mappable 
        SomeClass { ... } 

      To map an object with arrays or objects as type members to specific model types:
        @mappable({
            arrays:  { addresses: ContactCardAddress },
            objects: { contact: ContactCardContact }
        })
        class ContactCardStore { 
            @observable.shallow addresses = [];
            @observable.ref contact = null;
            
            ...
        }

        Then when you want to map a response, you either:

        someInstance.mapResponse(jsonPayload); // if you already have an instance of the mappable class.

        or 

        let someInstance = SomeClass.createFromResponse(jsonPayload) // to create a new instance loaded from the response
*/
export const mappable = ClassOrOptions => {
  if (typeof ClassOrOptions === "object") {
    return Class => applyMappable(Class, ClassOrOptions);
  } else {
    applyMappable(ClassOrOptions, {});
  }
};

export const Mappable = { recursive: Symbol("recursive") };

export const applyMappable = (Class, options = {}) => {
  let arrays = options.arrays || {};
  let objects = options.objects || {};
  let dates = (options.dates && options.dates.map(datePropName => datePropName.toLowerCase())) || [];
  let arrayLookup = new Map(Object.keys(arrays).map(name => [name.toLowerCase(), arrays[name]]));
  let objectLookup = new Map(Object.keys(objects).map(name => [name.toLowerCase(), objects[name]]));

  Class.createFromResponse = function(resp) {
    let result = new this();
    result.mapResponse(resp);
    return result;
  };

  Object.assign(Class.prototype, {
    mapResponse(resp) {
      runInAction(() => {
        if (typeof this.onBeforeMapping === "function") {
          this.onBeforeMapping(resp);
        }

        let propertyLookup = new Map();
        for (let k in this) {
          propertyLookup.set(k.toLowerCase(), k);
        }

        Object.keys(resp).forEach(k => {
          if (k === "validationModifiedTracker") {
            return;
          }
          let lcName = k.toLowerCase();
          let realName = propertyLookup.get(lcName);

          if (arrayLookup.has(lcName)) {
            let T = arrayLookup.get(lcName);
            if (T == Mappable.recursive) {
              T = Class;
            }
            verifyMappableClass(T);
            this[realName] = resp[k].map(item => T.createFromResponse(item));
          } else if (objectLookup.has(lcName)) {
            let T = objectLookup.get(lcName);
            verifyMappableClass(T);
            this[realName] = T.createFromResponse(resp[k]);
          } else if (dates.some(dtName => dtName === lcName)) {
            this[realName] = getLocalMomentFromServerDate(resp[k]);
          } else if (realName in this) {
            if (typeof this[realName] === "function") return;
            this[realName] = resp[k];
          }
        });

        if (typeof this.onMappingComplete === "function") {
          this.onMappingComplete(resp);
        }
      });
    }
  });
};

/** Ensures that a given class quacks like a mappable duck */
function verifyMappableClass(clazz) {
  if (clazz == null) {
    const error = "Class not found";
    console.error(error);
    throw Error(error);
  }

  if (typeof clazz.createFromResponse != "function") {
    const error = `${clazz.name} class does not have the createFromResponse() method. (Try decorating with @mappable?)`;
    console.error(error);
    throw Error(error);
  }
}

export const disposable = ClassOrOptions => {
  if (typeof ClassOrOptions === "object") {
    return Class => applyDisposable(Class, ClassOrOptions);
  } else {
    applyDisposable(ClassOrOptions, {});
  }
};

const applyDisposable = Class => {
  Object.defineProperty(Class.prototype, "toDispose", {
    get() {
      if (!this._toDispose) {
        this._toDispose = [];
      }
      return this._toDispose;
    },
    set(val) {
      this._toDispose = val;
    }
  });

  Object.assign(Class.prototype, {
    dispose() {
      if (!this.onDisposing || this.onDisposing()) {
        // either onDisposing is not defined or it is and returns truthy
        this.toDispose.forEach(f => f());
        this.toDispose = [];
      }
    }
  });
};

/* @editable is DEPRECATED and superfluous. Do not use. */
export const editable = decorator((target, name) => {
  target[`set_${name}`] = function(value) {
    this[name] = value;
  };
});

//for use with react-sortable-hoc -- copied from there, adjusted to actually mutate the array, since that's what we'll want for MobX
export const arrayMove = (array, previousIndex, newIndex) => {
  if (newIndex >= array.length) {
    var k = newIndex - array.length;
    while (k-- + 1) {
      array.push(undefined);
    }
  }
  array.splice(newIndex, 0, array.splice(previousIndex, 1)[0]);
  return array;
};
