import { isArrayLike } from "mobx";
import * as Interfaces from "./interfaces";

export const isArrayResults = resp => resp && Array.isArray(resp.results);
export const isSingleResults = resp => resp && resp.results && !Array.isArray(resp.results);
export const isValidId = id => id && !Number.isNaN(Number.parseInt(id, 10)) && id > 0;
export const isValidArray = obj => obj && isArrayLike(obj) && obj.length > 0;
export const assert = (condition: boolean, message: string, request?: Interfaces.ServerRequest) => {
  if (!condition) {
    throw new Interfaces.ApiError(request, message);
  }
};
