let position: GeolocationPosition = null;
let watch: number = null;

interface HandlerEntry<T extends PositionCallback | PositionErrorCallback> {
  handler: T;
}

type HandlerSet<T extends PositionCallback | PositionErrorCallback> = Set<HandlerEntry<T>>;

const createHandler = <T extends PositionCallback | PositionErrorCallback>(handler: T, container: HandlerSet<T>) => {
  const entry: HandlerEntry<T> = { handler };
  container.add(entry);
  return () => {
    container.delete(entry);
  };
};

const successHandlers: HandlerSet<PositionCallback> = new Set<HandlerEntry<PositionCallback>>();
const errorHandlers: HandlerSet<PositionErrorCallback> = new Set<HandlerEntry<PositionErrorCallback>>();

const successPosition: PositionCallback = p => {
  position = p;
  if (permissionStatus) {
    changePermissionState(PermissionStateValue.Granted);
  }
  successHandlers.forEach(({ handler }) => handler(p));
};

const errorPosition: PositionErrorCallback = e => {
  position = null;
  if (permissionStatus && e.code == 1) {
    changePermissionState(PermissionStateValue.Prompt);
  }

  errorHandlers.forEach(({ handler }) => handler(e));
};

// This is not a true event dispatcher, meaning it cannot set read-only Event's properties.
// We only use it to make TypeScript happy, and the only properties event handlers should use are `this` and `type`

class EventTargetImpl implements EventTarget {
  events: Map<string, Set<EventListenerOrEventListenerObject>> = new Map();

  addEventListener(type: string, listener?: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions): void {
    let handlers = this.events.get(type);
    if (!handlers) {
      handlers = new Set();
      this.events.set(type, handlers);
    }
    handlers.add(listener);
  }

  removeEventListener(type: string, listener?: EventListenerOrEventListenerObject, options?: boolean | EventListenerOptions): void {
    let handlers = this.events.get(type);
    if (!handlers) {
      return;
    }
    handlers.delete(listener);
  }

  // tslint:disable-next-line:no-invariant-return
  dispatchEvent(evt: Event): boolean {
    let handlers = this.events.get(evt.type);
    if (!handlers) {
      return true;
    }
    handlers.forEach(handler => (typeof handler === "function" ? handler.call(this, evt) : handler.handleEvent.call(this, evt)));
    return true;
  }
}

const permissionStatusStateLocalKey = "nagivator.permissions.permissionStatus.state";

const setPermissionState = (state: PermissionState) => {
  localStorage.setItem(permissionStatusStateLocalKey, state);
};

const changePermissionState = (state: PermissionState) => {
  let oldState = permissionStatus.state;
  setPermissionState(state);
  if (state !== oldState) {
    let event: Event;
    try {
      event = new Event("change");
    } catch (e) {
      event = document.createEvent("CustomEvent");
      (event as any).initCustomEvent("change", false, false, undefined);
    }
    permissionStatus.dispatchEvent(event);
  }
};

class PermissionStatusImpl extends EventTargetImpl implements PermissionStatus {
  onchange: EventListener = null;

  get state() {
    let state = localStorage.getItem(permissionStatusStateLocalKey);
    return state === PermissionStateValue.Granted || state === PermissionStateValue.Denied || state === PermissionStateValue.Prompt
      ? state
      : PermissionStateValue.Prompt;
  }

  dispatchEvent(evt: Event): boolean {
    super.dispatchEvent(evt);
    if (evt.type === "change" && typeof this.onchange === "function") {
      this.onchange.call(this, evt);
    }
    return true;
  }
}

const permissionStatus = navigator && navigator.permissions ? null : new PermissionStatusImpl();

class GeoService {
  static status(): Promise<PermissionStatus> {
    return permissionStatus ? Promise.resolve(permissionStatus) : navigator.permissions.query({ name: PermissionNameValue.Geolocation });
  }

  static query() {
    return new Promise<GeolocationPosition>((resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject));
  }

  static get running() {
    return watch !== null;
  }

  static start() {
    GeoService.stop();
    watch = navigator.geolocation.watchPosition(successPosition, errorPosition);
    let promise = GeoService.query();
    promise.then(successPosition).catch(errorPosition);
    return promise;
  }

  static stop() {
    if (watch !== null) {
      navigator.geolocation.clearWatch(watch);
      watch = null;
    }
    position = null;
  }

  static onSuccess(handler: PositionCallback) {
    return createHandler(handler, successHandlers);
  }

  static onError(handler: PositionErrorCallback) {
    return createHandler(handler, errorHandlers);
  }

  static get() {
    return position;
  }
}

export default GeoService;
