import * as Validators from "./validators";

/** Exported with testing in mind, but could be used elsewhere. */
export const ErrorMessages = {
  forBadId: (id: number) => `Service requires a valid ID that is greater than 0. Id given: ${id}`,
  forApiError: (statusCode = 0, statusText = "", details = "None") => `Service returned error: ${statusCode} - ${statusText}; Details: ${details}`,
  forUnexpectedResults: (resp: object) => `Service returned unexpected data. Response: ` + JSON.stringify(resp),
  forNoSourceObject: () => "'source' is required for mapping to transport objects but was null/undefined.",
  forNoPropsToSend: () => "'propsToSend' is required for meaningful mapping to transport objects but was null/undefined or zero length."
};

/**
 * Indicates an error calling an API. In these cases, we include the response status from the API.
 */
export class ApiError extends Error {
  /** Details about the inner API error. */
  apiError: string = "";
  /** Gets the HTTP status code from the response. */
  status: number = 0;

  constructor(request: ServerRequest, message?: string) {
    super(
      message ||
        (request
          ? // tslint:disable-next-line:triple-equals
            request.status == 0
            ? "Could not connect to API service. Is the service running?"
            : ErrorMessages.forApiError(request.status, request.statusText, request.responseText)
          : "No request passed to ApiError.")
    );
    this.apiError = request && request.responseJSON;
    this.status = request && request.status;
  }
}

// tslint:disable:interface-name

/**
 * A common interface on the API that standardizes how updatable objects are passed to it. Mirrors the C# interface.
 *
 * @template T - Type of updatable object (e.g., normally a domain object).
 */
export interface IAccept<T> {
  item: T;
}

/**
 * A common interface on the API that standardizes how a list of updatable objects are passed to it. Mirrors the C# interface.
 *
 * @template T - Type of updatable object (e.g., normally a domain object).
 */
export interface IAcceptMany<T> {
  items: T[];
}

/**
 * A common interface on the API that standardizes how a single object results are returned from it. Mirrors the C# interface.
 *
 * @template T - Type of result object. Usually a domain object or update result object.
 */
export interface IHaveResult<T> {
  request: ServerRequest;
  results: T;
}

/**
     * A common interface on the API that standardizes how list results are returned from it. Mirrors the C# interface.

    * @template T - Type of result object. Usually a list of domain objects.
    */
export interface IHaveResults<T> {
  request: ServerRequest;
  results: T[];
}

/**
     * A common interface on the API that standardizes how paged list results are returned from it. Mirrors the C# interface.

    * @template T - Type of result object. Usually a list of domain objects.
    */
export interface IHavePagedResults<T> {
  request: ServerRequest;
  results: T[];
  totalCount?: number;
}

/** Specifies common API shape for a paged request. */
export interface PagedRequest {
  /** Desired page to retrieve. */
  page: number;
  /** Page size to base page on. */
  pageSize: number;
}

export interface PagedResponse<T> {
  results: T[];
  totalCount?: number;
}

/** Extends IHaveResult with a simple object having an 'id' member. Useful for simple save operations that return the id of saved object. */
export interface IdResult extends IHaveResult<{ id: number }> {}

/** Represents our expected server request object. (Currently a jQXHR.) */
// Feel free to add other members as needed, of course.
export type ServerRequest = XMLHttpRequest & {
  /** Parsed JSON of response, assuming it returned JSON. */
  responseJSON?: any;
};

/** Represents a class constructor that supports mapping initial values onto its own. Typically implemented through the @mappable's mapResponse method, i.e., apply that to your class and add a constructor that optionally accepts an object and calls this.mapResponse with it. This is useful for copying dumb response JSON values onto a smarter JS model that has, e.g., observables. */
export interface Mappable {
  new (initialValues: object);
}

/**
 * Ensures that the given domain object is sent using the API's expected interface, if using IAccept.
 *
 * @template T - Type of domain object to be sent.
 * @param {T} domainObject - Instance of domain object.
 * @returns {IAccept<T>} - Wrapped instance to match DTO interface.
 */
export function Wrap<T>(domainObject: T): IAccept<T> {
  return {
    item: domainObject
  };
}

/**
 * Ensures that the given list of domain objects are sent using the API's expected interface, if using IAcceptMany.
 *
 * @template T - Type of domain object to be sent.
 * @param {T} domainObjects - List of domain objects.
 * @returns {IAcceptMany<T>} - Wrapped instance to match DTO interface.
 */
export function WrapList<T>(domainObjects: T[]): IAcceptMany<T> {
  return {
    items: domainObjects
  };
}

/**
 * Pulls out the single domain object instance from the response, if using IHaveResult.
 *
 * @template T - Type of domain object.
 * @param {IHaveResult<T>} response - Response DTO received from service.
 * @returns - Unwrapped domain object.
 */
export function Unwrap<T>(response: IHaveResult<T>) {
  Validators.assert(Validators.isSingleResults(response), ErrorMessages.forUnexpectedResults(response), response && response.request);
  return response.results;
}

/**
 * Pulls out a list of domain objects from the response, if using IHaveResults.
 *
 * @template T - Type of domain object.
 * @param {IHaveResult<T>} response - Response DTO received from service.
 * @returns - Unwrapped list of domain objects.
 */
export function UnwrapList<T>(response: IHaveResults<T> | IHavePagedResults<T>) {
  Validators.assert(Validators.isArrayResults(response), ErrorMessages.forUnexpectedResults(response), response && response.request);
  return response.results;
}

/**
 * Pulls out new/existing ID from response, assuming it returns IdResult.
 *
 * @export
 * @param {IdResult} response - Response DTO conforming to IdResult shape.
 * @param {number} [defaultId=0] - Optional. Default id. This is useful to not override a domain object ID if a bad result is returned. For example, pass the current object ID as the default here.
 * @returns
 */
export function UnwrapId(response: IdResult, defaultId: number = 0): number {
  if (!response || !response.results || !Validators.isValidId(response.results.id)) {
    console.warn("Service did not return updated object Id as expected. " + ErrorMessages.forUnexpectedResults(response));
    return defaultId;
  }
  return response.results.id;
}

/**
 * @interface
 * @description Array or scalar value of the corresponding generic type
 * @deprecated This is bad practice and only kept for backward compatibility.
 *   Always accept arrays and wrap single values to arrays on the call site if needed
 */
export type ArrayOrScalar<T> = T | T[];
