// tslint:disable:no-console  <-- TODO: remove after initial deployment; for testing/validation only

// TODO: put all of this logic into a web worker

import messageBus from "app/util/messageBus";

declare var CRreadCookie, CReraseCookie;
declare var window;

// use some interval that's short enough so that the user isn't waiting around,
// but long enough that it's not getting in the way of anything else
const PollingInterval = 5000;

/** Initialize automatic application upgrades */
export function initializeAutoUpgrade(): void {
  // If there's already a UI upgrade pending let's do it now -- why wait?
  upgradeIfRequired();

  // Watch for cookie changes to know when the version is updated
  watchForPendingUpgrades();

  // Evaluate the need to upgrade on every navigation event (aka hash change)
  watchNavigationEvents();
}

/** Get the latest application version */
export function getApplicationVersion(): string {
  try {
    return CRreadCookie("uiver");
  } catch (e) {
    console.error("Unable to retrieve application version", e);
    return "0.0.0";
  }
}

/**
 * Immediately performs a pending upgrade if one exists.
 *
 * Note: this is not an expensive call (does not actively contact the server, etc.), so feel free to use it liberally.
 */
export function upgradeIfRequired(): void {
  try {
    detectPendingUpgrade();

    if (isUpgradeRequired()) {
      executeUpgrade();
    }
  } catch (e) {
    console.error("Error auto-upgrading application - please refresh your browser", e);

    // if we've gotten here that means auto-upgrading isn't going to work, so tell the user
    // (and do it in the simplest way possible, since we're in a state of mid-upgrade)
    alert("Unable to automatically upgrade application - please refresh your browser");
  }
}

const isUpgradeRequired = (): boolean => window.CR && !!window.CR.refresh;

const detectPendingUpgrade = (): void => {
  if (CRreadCookie("refresh")) {
    publishPendingUpgradeNotification();
    CReraseCookie("refresh");
  }
};

const publishPendingUpgradeNotification = (): void => {
  messageBus.sendMessage("ui", "upgrade");
  window.CR = Object.assign(window.CR || {}, { refresh: true });
};

const executeUpgrade = (): void => {
  console.info("[AutoUpgrade] Upgrading application...");

  // This may one day be complex, but for now "upgrading" simply means
  // doing a hard full-page reload in order to retrieve all the latest assets
  // from the server (leveraging standard caching logic)

  window.location.reload(true);
};

const watchNavigationEvents = (): void => {
  // hash changes are the perfect time to actually do the upgrade
  window.addEventListener("hashchange", () => upgradeIfRequired());
};

const watchForPendingUpgrades = (): void => {
  // just detect - don't actually upgrade now because the user might be in the middle of doing something
  detectPendingUpgrade();

  // make sure we're not blocking any other more important work
  setTimeout(watchForPendingUpgrades, PollingInterval);
};

// Announce that the upgrade was detected to the console for testing purposes.
// This can be removed after initial deployment (or left around - it's not hurting anything)
messageBus.subscribe("ui", "upgrade", () => console.debug(`[AutoUpgrade] New UI version available: ${getApplicationVersion()}`));
