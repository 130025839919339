// not sure why TS is not picking up types in third_party_libraries, but for now this is necessary
// TODO: get rid of this
// tslint:disable-next-line: no-reference
/// <reference path="../../../third_party_libraries/js/jquery-extensions.d.ts" />

function preventFileDragDropOnWindow(): void {
  $(document).on("drop dragover", function(e) {
    e.preventDefault();
  });
}

function focusAutoFocusFieldOnModal() {
  $(document).on("shown.bs.modal", function(e) {
    $(e.target)
      .find(".autofocus:visible:first")
      .focus();
  });
}

//clicking on a button showing a tooltip re-shows it, causing an ugly flicker.  This prevents that.
function preventButtonTooltipFlicker(): void {
  $(document).on("show.bs.tooltip", function(evt) {
    let tip = $(evt.target).data("bs.tooltip");
    let isVisible = tip && tip.tip().hasClass("in");
    if (isVisible) {
      return false;
    }
  });
}

function windowResizeHelper(): void {
  // attach an event listener for when the window gets resized
  // we can add more / other things into this as we work on responsive
  $(window).resize(
    // Private: This function gets called on a window.resize event
    // it will look for any stickyTableHeader tables and trigger a refresh/resize
    // it will also make use of enquire.js to remove fixed table headers for mobile phones
    // ref: https://github.com/jmosbech/StickyTableHeaders
    // loc: /shared/jquery/jquery.stickytableheaders.js
    function refreshStickyTableHeaders() {
      let win = $(window);
      let doc = $(document);
      let docHeight = doc.height();
      let scrollPos = doc.scrollTop();
      let scroll = scrollPos < docHeight ? scrollPos + 1 : scrollPos - 5;

      // find an trigger a resize of all sticky table headers
      win.trigger("resize.stickyTableHeaders");
    }
  );
}

function addClickListenerToDateControl(): void {
  // attach click event listeners to any inputs that have
  // the form-control-date class on it and trigger showing the datepicker
  // we do this because the css used to show a calendar icon within the input
  // blocks the click-event when a user tries to click it and will only open
  // when actually clicked within the input itself which seems somewhat misleading
  $(document.body).on("click", ".form-control-date", function(e) {
    if (!$(e.target).closest("input.hasDatepicker")[0]) {
      $(this)
        .find(".hasDatepicker")
        .datepicker("show");
    }
  });
}

/**
 * Initializes a set of jQuery helpers used to aide different behaviors within the app
 */
function initializeGlobaljQueryHelpers(): void {
  // @ts-ignore
  $.expr.cacheLength = 1;

  preventFileDragDropOnWindow();
  focusAutoFocusFieldOnModal();
  preventButtonTooltipFlicker();

  $(document)
    // Using data-dismiss on a link (for instance, to close a modal) prevents the link
    // from being followed, which is almost always what you want, but not quite always.
    // If you want the modal closed *and* the link followed, use the dismiss-bs-modal
    // class instead of data-dismiss="modal".
    .on("click", ".dismiss-bs-modal", function(e) {
      $(this)
        .closest(".modal")
        .modal("hide");
    })
    // Collapse accordion every time dropdown is shown
    .on("show.bs.dropdown", function(event) {
      let accordion = $(this).find($(this).data("accordion"));
      accordion.find(".panel-collapse.in").collapse("hide");
    })
    // Prevent dropdown to be closed when we click on an accordion link
    .on("click", '.dropdown-accordion a[data-toggle="collapse"]', function(event) {
      event.preventDefault();
      event.stopPropagation();
      $($(this).data("parent"))
        .find(".panel-collapse.in")
        .collapse("hide");
      $($(this).attr("href")).collapse("show");
    });

  windowResizeHelper();
  addClickListenerToDateControl();
}

export default initializeGlobaljQueryHelpers;
