import { createElement } from "react";
import { render, unmountComponentAtNode } from "react-dom";
import { addKnockoutBridgedComponent, updateKnockoutBridgedComponent } from "./knockoutBridge";

ko.bindingHandlers.renderReactComponent = {
  init: function (rawElement) {
    // if using virtual elements, mount to nextElementSibling
    const element = rawElement.nodeType === 8 ? rawElement.nextElementSibling : rawElement;
    ko.utils.domNodeDisposal.addDisposeCallback(element, () => unmountComponentAtNode(element));
  },
  update: function (rawElement, valueAccessor) {
    // if using virtual elements, mount to nextElementSibling
    const element = rawElement.nodeType === 8 ? rawElement.nextElementSibling : rawElement;
    const { component, props } = valueAccessor();
    render(createElement(ko.unwrap(component), { ...props }), element);
  }
};

ko.virtualElements.allowedBindings.renderReactComponent = true;

ko.bindingHandlers.renderReactPortal = {
  init: function (rawElement) {
    // if using virtual elements, mount to nextElementSibling
    const element = rawElement.nodeType === 8 ? rawElement.nextElementSibling : rawElement;
    const unmountFn = addKnockoutBridgedComponent(element);
    ko.utils.domNodeDisposal.addDisposeCallback(element, unmountFn);
  },
  update: function (rawElement, valueAccessor) {
    // if using virtual elements, mount to nextElementSibling
    const element = rawElement.nodeType === 8 ? rawElement.nextElementSibling : rawElement;
    const { component, props } = valueAccessor();
    updateKnockoutBridgedComponent(element, ko.unwrap(component), { ...props });
  }
};

ko.virtualElements.allowedBindings.renderReactPortal = true;
