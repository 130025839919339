import { Box, CircularProgress } from "@material-ui/core";
import React from "react";

export const FallbackLoading = () => {
  return (
    <Box display="flex" width="100%" height="100vh" justifyContent="center" alignItems="center">
      <CircularProgress />
    </Box>
  );
};
