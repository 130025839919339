import React from "react";
import { ThemeProvider } from "@cr/material-ui-theme-provider";
import { Alert, AlertProps } from "@material-ui/lab";

const MuiAlert = ({ children, header, detail, ...rest }: AlertProps & { header?: string; detail?: string }) => (
  <ThemeProvider skipGlobalStyles>
    <Alert {...rest}>
      {header ? (
        <div style={{ marginBottom: 4 }}>
          <strong dangerouslySetInnerHTML={{ __html: header }} />
        </div>
      ) : null}
      {detail ? <div dangerouslySetInnerHTML={{ __html: detail }} /> : null}
    </Alert>
  </ThemeProvider>
);

export const knockoutName = "cr-mui-alert";

export default MuiAlert;
