/** Possible authetnication states.  */
// this is partly planning ahead for refactoring to API in the future
export enum AuthenticateUserFlowState {
  Unspecified = "Unspecified",
  UnexpectedError = "UnexpectedError",
  MustAuthenticate = "MustAuthenticate",
  Authenticated = "Authenticated",
  AuthenticationFailed = "AuthenticationFailed",
  ConfirmationRequired = "ConfirmationRequired",
  Confirmed = "Confirmed",
  SmsMfaRequired = "SmsMfaRequired",
  SoftwareMfaRequired = "SoftwareMfaRequired",
  NewPasswordRequired = "NewPasswordRequired",
  MfaSetupRequired = "MfaSetupRequired",
  MfaSetupVerificationRequired = "MfaSetupVerificationRequired",
  MfaSetupVerificationFailed = "MfaSetupVerificationFailed",
  MfaSetupCompleted = "MfaSetupCompleted",
  PasswordExpired = "PasswordExpired"
}

export interface UserCredentials {
  username: string;
  password: string;
  subdomain?: string;
}

export interface SwitchAccountCredentials {
  loggedInAs?: number;
  switchto?: string;
  subdomain?: string;
}

export interface ImpersonationCredentials extends UserCredentials {
  x: string;
}

export interface TokenCredentials {
  token: string;
  impersonateId?: number;
  enforceTokenExpiration?: "true";
}

export type Credentials = UserCredentials | SwitchAccountCredentials;
