import { Logger } from "@cr/core/logger";
import { userSessionStore } from "../store";
import { UserSessionBroadcastChannel } from "./models";
import user from "app/util/user";
import { CRSessionMonitor } from "app/util/CRSessionMonitor";

//Events executed in other browser tabs and not the currently active one

const log = Logger.create("UserSessionBroadcastChannel");

/**
 *Executed when a logout is made in other tabs
 *
 * @param {UserSessionBroadcastChannel} msg
 */
export const externalLogoutHandler = (msg: UserSessionBroadcastChannel) => {
  userSessionStore.setExternalLogout(true);
  log.debug(msg);
};

/**
 *Executed when a user logs in another browser tab
 *
 * @param {UserSessionBroadcastChannel} msg
 */
export const externalLoginHandler = (msg: UserSessionBroadcastChannel) => {
  if (user?.id !== msg.userId)  {
    window.location.reload();
  }
  log.debug(msg);
};

/**
 *Executed when a user logs in another browser tab
 *
 * @param {UserSessionBroadcastChannel} msg
 */
export const inactivityWarningHandler = (msg: UserSessionBroadcastChannel) => {
  userSessionStore.setInactivityWarning(msg.newValue);
  log.debug(msg);
};

/**
 *Executed when a user refreshes session in another browser tab
 *
 * @param {UserSessionBroadcastChannel} msg
 */
export const refreshSessionHandler = (msg: UserSessionBroadcastChannel) => {
  userSessionStore.setInactivityWarning(msg.newValue);
  userSessionStore.setSessionTimedOut(msg.newValue);
  log.debug(msg);
};

/**
 *Executed when a user logs in another browser tab
 *
 * @param {UserSessionBroadcastChannel} msg
 */
export const userInteractionHandler = (msg: UserSessionBroadcastChannel) => {
  var sessionMonitor = window["cr_session_monitor"] as CRSessionMonitor;
  if(sessionMonitor) {  
    sessionMonitor.update_last_active()
  }
  log.debug(msg);
};

/**
 *Executed when a session is refreshed another browser tab
 *
 * @param {UserSessionBroadcastChannel} msg
 */
export const sessionRefreshedHandler = (msg: UserSessionBroadcastChannel) => {
  var sessionMonitor = window["cr_session_monitor"] as CRSessionMonitor;
  if(sessionMonitor) {  
    sessionMonitor.session_refreshed()
  }
  log.debug(msg);
};