import { useEffect } from "react";

interface SelectorMap {
  [key: string]: StyleRule;
}

interface StyleRule {
  [key: string]: string | number;
}

interface Props {
  styles: string | SelectorMap;
  target?: HTMLElement;
  testId?: string;
}

const camelToDash = value => value.replace(/([A-Z])/g, token => "-" + token.toLowerCase());

const GlobalStyle = ({ styles, target, testId }: Props) => {
  useEffect(() => {
    const element = document.createElement("style");

    element.innerHTML =
      typeof styles === "string"
        ? styles
        : Object.keys(styles || {})
            .map(
              selector =>
                `${selector} { ${Object.keys(styles[selector])
                  .map(property => `${camelToDash(property)}: ${styles[selector][property]};`)
                  .join(" ")} }`
            )
            .join(" ");

    if (testId) {
      element.setAttribute("data-testid", testId);
    }

    (target || document.head).appendChild(element);

    return () => {
      (target || document.head).removeChild(element);
    };
  }, [styles, target, testId]);

  return null;
};

export default GlobalStyle;
