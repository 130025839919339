import history from "@cr/core/history";
import { ContactsPermissions, Credentials } from "app/security";
import user, { userSessionStore } from "app/user";

export default async function initializeUser() {
  try {
    await processPendingLogin();
    return await userSessionStore.init();
  } catch (error) {
    console.error("Error initializing user.", error);
  }
}

/**
 * handle any operations that might be passed via the querystring
 * e.g. switching accounts or impersonating users
 */
async function processPendingLogin(): Promise<void> {
  const { mode, x, switchto } = history.queryParameters;

  if (mode !== "login") {
    return;
  }

  const authenticationService = require("app/security/authenticationService").default;

  let credentials: Credentials;

  if (switchto) {
    //user is authenticated and is switching to another authentication
    credentials = authenticationService.getSwitchAccountCredentials(user.contactId, switchto);
  } else if (x) {
    credentials = authenticationService.getImpersonationCredentials(x);
  }

  if (credentials) {
    await authenticationService.authenticate(credentials);
    const page = "account";
    window.location.href = `/#${localStorage.getItem("framework/header::homepage") || page}`;
  }
}
