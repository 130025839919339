import React from "react";
import { Tooltip, TooltipProps, Typography } from "@material-ui/core";
import { InfoIconOutlined } from "@cr/icons/Info";
import { InfoIcon } from "@cr/icons/Info";
import GlobalStyle from "../globalStyle";

const MuiTooltip = ({
  title,
  fontSize,
  outlined,
  width,
  ...rest
}: { title: string; fontSize: "small" | "default" | "inherit" | "large"; outlined?: boolean; width?: number } & TooltipProps) => (
  <>
    {width ? <GlobalStyle styles={`div[role="tooltip"] > * { max-width: ${width}px; }`} /> : null}
    <Tooltip {...rest} title={<Typography style={{ fontSize: 14 }}>{title}</Typography>}>
      {outlined ? <InfoIconOutlined {...{ fontSize }} /> : <InfoIcon {...{ fontSize }} />}
    </Tooltip>
  </>
);

export const knockoutName = "cr-mui-tooltip";

export default MuiTooltip;
