export default {
  modules: {
    billingmanager: { 
      module: () => import(/* webpackChunkName: "billingmanager-module" */ "billingmanager/billingmanager"),
      view: () => import(/* webpackChunkName: "billingmanager-module" */ "billingmanager/billingmanager.htm"),
      css: () => import(/* webpackChunkName: "billingmanager-module" */ "billingmanager/billingmanager.css"),
      subModules: {
        authorizations: {
          module: () => import(/* webpackChunkName: "billingmanager-authorizations" */ "billingmanager/authorizations/authorizations"),
          view: () => import(/* webpackChunkName: "billingmanager-authorizations" */ "billingmanager/authorizations/authorizations.htm")
        },
        authorizationspayor: {
          module: () => import(/* webpackChunkName: "billingmanager-authorizationspayor" */ "billingmanager/authorizationspayor/authorizationspayor"),
          view: () => import(/* webpackChunkName: "billingmanager-authorizationspayor" */ "billingmanager/authorizationspayor/authorizationspayor.htm")
        },
        authorizations_2: {
          module: () => import(/* webpackChunkName: "billingmanager-authorizations_2" */ "billingmanager/authorizations_2/authorizations_2"),
          view: () => import(/* webpackChunkName: "billingmanager-authorizations_2" */ "billingmanager/authorizations_2/authorizations_2.htm")
        },
        billing: {
          module: () => import(/* webpackChunkName: "billingmanager-billing-js" */ "billingmanager/billing/billing"),
          view: () => import(/* webpackChunkName: "billingmanager-billing-html" */ "billingmanager/billing/billing.htm")
        },
        clientdefaults: {
          module: () => import(/* webpackChunkName: "billingmanager-clientdefaults" */ "billingmanager/clientdefaults/clientdefaults"),
          view: () => import(/* webpackChunkName: "billingmanager-clientdefaults" */ "billingmanager/clientdefaults/clientdefaults.htm")
        },
        drafttimesheets: {
          module: () => import(/* webpackChunkName: "billingmanager-drafttimesheets" */ "billingmanager/drafttimesheets/DraftTimesheets"),
          view: () => import(/* webpackChunkName: "billingmanager-drafttimesheets" */ "billingmanager/drafttimesheets/DraftTimesheets.htm")
        },
        driverates: {
          module: () => import(/* webpackChunkName: "billingmanager-driverates" */ "billingmanager/driverates/driverates"),
          view: () => import(/* webpackChunkName: "billingmanager-driverates" */ "billingmanager/driverates/driverates.htm")
        },
        ds5862: {
          module: () => import(/* webpackChunkName: "billingmanager-ds5862" */ "billingmanager/ds5862/ds5862"),
          view: () => import(/* webpackChunkName: "billingmanager-ds5862" */ "billingmanager/ds5862/ds5862.htm")
        },
        ds5862_50: {
          module: () => import(/* webpackChunkName: "billingmanager-ds5862_50" */ "billingmanager/ds5862_50/ds5862_50"),
          view: () => import(/* webpackChunkName: "billingmanager-ds5862_50" */ "billingmanager/ds5862_50/ds5862_50.htm")
        },
        ds5862_custom: {
          module: () => import(/* webpackChunkName: "billingmanager-ds5862_custom" */ "billingmanager/ds5862_custom/ds5862_custom"),
          view: () => import(/* webpackChunkName: "billingmanager-ds5862_custom" */ "billingmanager/ds5862_custom/ds5862_custom.htm")
        },
        feeschedules: {
          module: () => import(/* webpackChunkName: "billingmanager-feeschedules" */ "billingmanager/feeschedules/feeschedules"),
          view: () => import(/* webpackChunkName: "billingmanager-feeschedules" */ "billingmanager/feeschedules/feeschedules.htm")
        },
        home: {
          module: () => import(/* webpackChunkName: "billingmanager-home" */ "billingmanager/home/home"),
          view: () => import(/* webpackChunkName: "billingmanager-home" */ "billingmanager/home/home.htm")
        },
        invoicesprint: {
          module: () => import(/* webpackChunkName: "billingmanager-invoicesprint" */ "billingmanager/invoicesprint/invoicesprint"),
          view: () => import(/* webpackChunkName: "billingmanager-invoicesprint" */ "billingmanager/invoicesprint/invoicesprint.htm")
        },
        notestemplates: {
          module: () => import(/* webpackChunkName: "billingmanager-notestemplates" */ "billingmanager/notestemplates/notestemplates"),
          view: () => import(/* webpackChunkName: "billingmanager-notestemplates" */ "billingmanager/notestemplates/notestemplates.htm")
        },
        paymentrecon: {
          module: () => import(/* webpackChunkName: "billingmanager-paymentrecon" */ "billingmanager/paymentrecon/paymentrecon"),
          view: () => import(/* webpackChunkName: "billingmanager-paymentrecon" */ "billingmanager/paymentrecon/paymentrecon.htm")
        },
        payments: {
          module: () => import(/* webpackChunkName: "billingmanager-payments" */ "billingmanager/payments/payments"),
          view: () => import(/* webpackChunkName: "billingmanager-payments" */ "billingmanager/payments/payments.htm")
        },
        sales: {
          module: () => import(/* webpackChunkName: "billingmanager-sales" */ "billingmanager/sales/sales"),
          view: () => import(/* webpackChunkName: "billingmanager-sales" */ "billingmanager/sales/sales.htm")
        },
        servicecodes: {
          module: () => import(/* webpackChunkName: "billingmanager-servicecodes" */ "billingmanager/servicecodes/servicecodes"),
          view: () => import(/* webpackChunkName: "billingmanager-servicecodes" */ "billingmanager/servicecodes/servicecodes.htm")
        },
        settings: {
          module: () => import(/* webpackChunkName: "billingmanager-settings" */ "billingmanager/settings/settings"),
          view: () => import(/* webpackChunkName: "billingmanager-settings" */ "billingmanager/settings/settings.htm")
        },
        statements: {
          module: () => import(/* webpackChunkName: "billingmanager-statements" */ "billingmanager/statements/statements"),
          view: () => import(/* webpackChunkName: "billingmanager-statements" */ "billingmanager/statements/statements.htm")
        },
        timesheets: {
          module: () => import(/* webpackChunkName: "billingmanager-timesheets" */ "billingmanager/timesheets/timesheets"),
          view: () => import(/* webpackChunkName: "billingmanager-timesheets" */ "billingmanager/timesheets/timesheets.htm")
        }
      }
    },
    bundles: { 
      module: () => import(/* webpackChunkName: "bundles-module" */ "bundles/bundles"),
      view: () => import(/* webpackChunkName: "bundles-module" */ "bundles/bundles.htm"),
      css: () => import(/* webpackChunkName: "bundles-module" */ "bundles/bundles.css"),
      subModules: {
        assessment: {
          module: () => import(/* webpackChunkName: "bundles-assessment" */ "bundles/assessment/assessment"),
          view: () => import(/* webpackChunkName: "bundles-assessment" */ "bundles/assessment/assessment.htm")
        },
        assessments: {
          module: () => import(/* webpackChunkName: "bundles-assessments" */ "bundles/assessments/assessments"),
          view: () => import(/* webpackChunkName: "bundles-assessments" */ "bundles/assessments/assessments.htm")
        },
        bundle: {
          module: () => import(/* webpackChunkName: "bundles-bundle" */ "bundles/bundle/bundle"),
          view: () => import(/* webpackChunkName: "bundles-bundle" */ "bundles/bundle/bundle.htm")
        },
        chartanalysis: {
          module: () => import(/* webpackChunkName: "bundles-chartanalysis" */ "bundles/chartanalysis/chartanalysis"),
          view: () => import(/* webpackChunkName: "bundles-chartanalysis" */ "bundles/chartanalysis/chartanalysis.htm")
        },
        coupons: {
          module: () => import(/* webpackChunkName: "bundles-coupons" */ "bundles/coupons/coupons"),
          view: () => import(/* webpackChunkName: "bundles-coupons" */ "bundles/coupons/coupons.htm")
        },
        coursegroups: {
          module: () => import(/* webpackChunkName: "bundles-coursegroups" */ "bundles/coursegroups/coursegroups"),
          view: () => import(/* webpackChunkName: "bundles-coursegroups" */ "bundles/coursegroups/coursegroups.htm")
        },
        criteria: {
          module: () => import(/* webpackChunkName: "bundles-criteria" */ "bundles/criteria/criteria"),
          view: () => import(/* webpackChunkName: "bundles-criteria" */ "bundles/criteria/criteria.htm")
        },
        group: {
          module: () => import(/* webpackChunkName: "bundles-group" */ "bundles/group/group"),
          view: () => import(/* webpackChunkName: "bundles-group" */ "bundles/group/group.htm")
        },
        groupadd: {
          module: () => import(/* webpackChunkName: "bundles-groupadd" */ "bundles/groupadd/groupadd"),
          view: () => import(/* webpackChunkName: "bundles-groupadd" */ "bundles/groupadd/groupadd.htm")
        },
        notestemplates: {
          module: () => import(/* webpackChunkName: "bundles-notestemplates" */ "bundles/notestemplates/notestemplates"),
          view: () => import(/* webpackChunkName: "bundles-notestemplates" */ "bundles/notestemplates/notestemplates.htm")
        },
        orders: {
          module: () => import(/* webpackChunkName: "bundles-orders" */ "bundles/orders/orders"),
          view: () => import(/* webpackChunkName: "bundles-orders" */ "bundles/orders/orders.htm")
        },
        rbt: {
          module: () => import(/* webpackChunkName: "bundles-rbt" */ "bundles/rbt/rbt"),
          view: () => import(/* webpackChunkName: "bundles-rbt" */ "bundles/rbt/rbt.htm")
        },
        report: {
          module: () => import(/* webpackChunkName: "bundles-report" */ "bundles/report/report"),
          view: () => import(/* webpackChunkName: "bundles-report" */ "bundles/report/report.htm")
        },
        session: {
          module: () => import(/* webpackChunkName: "bundles-session" */ "bundles/session/session"),
          view: () => import(/* webpackChunkName: "bundles-session" */ "bundles/session/session.htm")
        },
        sessionadd: {
          module: () => import(/* webpackChunkName: "bundles-sessionadd" */ "bundles/sessionadd/sessionadd"),
          view: () => import(/* webpackChunkName: "bundles-sessionadd" */ "bundles/sessionadd/sessionadd.htm")
        },
        sessionprint: {
          module: () => import(/* webpackChunkName: "bundles-sessionprint" */ "bundles/sessionprint/sessionprint"),
          view: () => import(/* webpackChunkName: "bundles-sessionprint" */ "bundles/sessionprint/sessionprint.htm")
        },
        sessions: {
          module: () => import(/* webpackChunkName: "bundles-sessions" */ "bundles/sessions/sessions"),
          view: () => import(/* webpackChunkName: "bundles-sessions" */ "bundles/sessions/sessions.htm")
        },
        sessionsummary: {
          module: () => import(/* webpackChunkName: "bundles-sessionsummary" */ "bundles/sessionsummary/sessionsummary"),
          view: () => import(/* webpackChunkName: "bundles-sessionsummary" */ "bundles/sessionsummary/sessionsummary.htm")
        },
        vbmapp: {
          module: () => import(/* webpackChunkName: "bundles-vbmapp" */ "bundles/vbmapp/vbmapp"),
          view: () => import(/* webpackChunkName: "bundles-vbmapp" */ "bundles/vbmapp/vbmapp.htm")
        },
        vbmapps: {
          module: () => import(/* webpackChunkName: "bundles-vbmapps" */ "bundles/vbmapps/vbmapps"),
          view: () => import(/* webpackChunkName: "bundles-vbmapps" */ "bundles/vbmapps/vbmapps.htm")
        },
        vbmappscore: {
          module: () => import(/* webpackChunkName: "bundles-vbmappscore" */ "bundles/vbmappscore/vbmappscore"),
          view: () => import(/* webpackChunkName: "bundles-vbmappscore" */ "bundles/vbmappscore/vbmappscore.htm")
        }
      }
    },
    claims: { 
      module: () => import(/* webpackChunkName: "claims-module" */ "claims/claims"),
      view: () => import(/* webpackChunkName: "claims-module" */ "claims/claims.htm"),
      css: () => import(/* webpackChunkName: "claims-module" */ "claims/claims.css"),
      subModules: {
        editor: {
          module: () => import(/* webpackChunkName: "claims-editor" */ "claims/editor/editor"),
          view: () => import(/* webpackChunkName: "claims-editor" */ "claims/editor/editor.htm")
        },
        eralist: {
          module: () => import(/* webpackChunkName: "claims-eralist" */ "claims/eralist/eralist"),
          view: () => import(/* webpackChunkName: "claims-eralist" */ "claims/eralist/eralist.htm")
        },
        list: {
          module: () => import(/* webpackChunkName: "claims-list" */ "claims/list/list"),
          view: () => import(/* webpackChunkName: "claims-list" */ "claims/list/list.htm")
        }
      }
    },
    contacts: { 
      module: () => import(/* webpackChunkName: "contacts-module" */ "contacts/contacts"),
      view: () => import(/* webpackChunkName: "contacts-module" */ "contacts/contacts.htm"),
      css: () => import(/* webpackChunkName: "contacts-module" */ "contacts/contacts.css"),
      subModules: {
        add: {
          module: () => import(/* webpackChunkName: "contacts-add" */ "contacts/add/add"),
          view: () => import(/* webpackChunkName: "contacts-add" */ "contacts/add/add.htm")
        },
        forms: {
          module: () => import(/* webpackChunkName: "contacts-forms" */ "contacts/forms/forms"),
          view: () => import(/* webpackChunkName: "contacts-forms" */ "contacts/forms/forms.htm")
        },
        linkaccounts: {
          module: () => import(/* webpackChunkName: "contacts-linkaccounts" */ "contacts/linkAccounts/linkAccounts"),
          view: () => import(/* webpackChunkName: "contacts-linkaccounts" */ "contacts/linkAccounts/linkAccounts.htm")
        },
        utilities: {
          module: () => import(/* webpackChunkName: "contacts-utilities" */ "contacts/utilities/utilities"),
          view: () => import(/* webpackChunkName: "contacts-utilities" */ "contacts/utilities/utilities.htm")
        }
      }
    },
    forms: { 
      module: () => import(/* webpackChunkName: "forms-module" */ "forms/forms"),
      view: () => import(/* webpackChunkName: "forms-module" */ "forms/forms.htm"),
      css: () => import(/* webpackChunkName: "forms-module" */ "forms/forms.css"),
      subModules: {
        
      }
    },
    manage: { 
      module: () => import(/* webpackChunkName: "manage-module" */ "manage/manage"),
      view: () => import(/* webpackChunkName: "manage-module" */ "manage/manage.htm"),
      css: () => import(/* webpackChunkName: "manage-module" */ "manage/manage.css"),
      subModules: {
        announcements: {
          module: () => import(/* webpackChunkName: "manage-submodule-js" */ "manage/announcements/announcements"),
          view: () => import(/* webpackChunkName: "manage-submodule-html" */ "manage/announcements/announcements.htm")
        },
        billing: {
          module: () => import(/* webpackChunkName: "manage-submodule-js" */ "manage/billing/billing"),
          view: () => import(/* webpackChunkName: "manage-submodule-html" */ "manage/billing/billing.htm")
        },
        bundles: {
          module: () => import(/* webpackChunkName: "manage-submodule-js" */ "manage/bundles/bundles"),
          view: () => import(/* webpackChunkName: "manage-submodule-html" */ "manage/bundles/bundles.htm")
        },
        claims: {
          module: () => import(/* webpackChunkName: "manage-submodule-js" */ "manage/claims/claims"),
          view: () => import(/* webpackChunkName: "manage-submodule-html" */ "manage/claims/claims.htm")
        },
        connect: {
          module: () => import(/* webpackChunkName: "manage-submodule-js" */ "manage/connect/connect"),
          view: () => import(/* webpackChunkName: "manage-submodule-html" */ "manage/connect/connect.htm")
        },
        contact: {
          module: () => import(/* webpackChunkName: "manage-submodule-js" */ "manage/contact/contact"),
          view: () => import(/* webpackChunkName: "manage-submodule-html" */ "manage/contact/contact.htm")
        },
        insurance: {
          module: () => import(/* webpackChunkName: "manage-submodule-js" */ "manage/insurance/insurance"),
          view: () => import(/* webpackChunkName: "manage-submodule-html" */ "manage/insurance/insurance.htm")
        },
        ip: {
          module: () => import(/* webpackChunkName: "manage-submodule-js" */ "manage/ip/ip"),
          view: () => import(/* webpackChunkName: "manage-submodule-html" */ "manage/ip/ip.htm")
        },
        notificationtemplates: {
          module: () => import(/* webpackChunkName: "manage-submodule-js" */ "manage/notificationtemplates/notificationtemplates"),
          view: () => import(/* webpackChunkName: "manage-submodule-html" */ "manage/notificationtemplates/notificationtemplates.htm")
        },
        settings: {
          module: () => import(/* webpackChunkName: "manage-submodule-js" */ "manage/settings/settings"),
          view: () => import(/* webpackChunkName: "manage-submodule-html" */ "manage/settings/settings.htm")
        }
      }
    },
    messaging: { 
      module: () => import(/* webpackChunkName: "messaging-module" */ "messaging/messaging"),
      view: () => import(/* webpackChunkName: "messaging-module" */ "messaging/messaging.htm"),
      css: () => import(/* webpackChunkName: "messaging-module" */ "messaging/messaging.css"),
      subModules: {
        
      }
    },
    notes: { 
      module: () => import(/* webpackChunkName: "notes-module" */ "notes/notes"),
      view: () => import(/* webpackChunkName: "notes-module" */ "notes/notes.htm"),
      css: () => import(/* webpackChunkName: "notes-module" */ "notes/notes.css"),
      subModules: {
        composer: {
          module: () => import(/* webpackChunkName: "notes-composer" */ "notes/composer/composer"),
          view: () => import(/* webpackChunkName: "notes-composer" */ "notes/composer/composer.htm")
        },
        templates: {
          module: () => import(/* webpackChunkName: "notes-templates" */ "notes/templates/templates"),
          view: () => import(/* webpackChunkName: "notes-templates" */ "notes/templates/templates.htm")
        }
      }
    },
    payroll: { 
      module: () => import(/* webpackChunkName: "payroll-module" */ "payroll/payroll"),
      view: () => import(/* webpackChunkName: "payroll-module" */ "payroll/payroll.htm"),
      css: () => import(/* webpackChunkName: "payroll-module" */ "payroll/payroll.css"),
      subModules: {
        
      }
    },
    permissions: { 
      module: () => import(/* webpackChunkName: "permissions-module" */ "permissions/permissions"),
      view: () => import(/* webpackChunkName: "permissions-module" */ "permissions/permissions.htm"),
      css: () => import(/* webpackChunkName: "permissions-module" */ "permissions/permissions.css"),
      subModules: {
        
      }
    },
    register: { 
      module: () => import(/* webpackChunkName: "register-module" */ "register/register"),
      view: () => import(/* webpackChunkName: "register-module" */ "register/register.htm"),
      css: () => import(/* webpackChunkName: "register-module" */ "register/register.css"),
      subModules: {
        
      }
    },
    reporting: { 
      module: () => import(/* webpackChunkName: "reporting-module" */ "reporting/reporting"),
      view: () => import(/* webpackChunkName: "reporting-module" */ "reporting/reporting.htm"),
      css: () => import(/* webpackChunkName: "reporting-module" */ "reporting/reporting.css"),
      subModules: {
        101: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/101/101"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/101/101.htm")
        },
        104: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/104/104"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/104/104.htm")
        },
        105: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/105/105"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/105/105.htm")
        },
        106: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/106/106"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/106/106.htm")
        },
        107: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/107/107"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/107/107.htm")
        },
        108: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/108/108"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/108/108.htm")
        },
        109: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/109/109"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/109/109.htm")
        },
        110: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/110/110"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/110/110.htm")
        },
        111: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/111/111"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/111/111.htm")
        },
        112: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/112/112"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/112/112.htm")
        },
        114: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/114/114"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/114/114.htm")
        },
        115: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/115/115"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/115/115.htm")
        },
        116: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/116/116"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/116/116.htm")
        },
        117: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/117/117"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/117/117.htm")
        },
        118: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/118/118"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/118/118.htm")
        },
        119: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/119/119"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/119/119.htm")
        },
        121: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/121/121"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/121/121.htm")
        },
        123: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/123/123"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/123/123.htm")
        },
        126: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/126/126"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/126/126.htm")
        },
        127: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/127/127"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/127/127.htm")
        },
        17: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/17/17"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/17/17.htm")
        },
        2001: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/2001/2001"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/2001/2001.htm")
        },
        21: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/21/21"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/21/21.htm")
        },
        23: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/23/23"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/23/23.htm")
        },
        24: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/24/24"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/24/24.htm")
        },
        25: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/25/25"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/25/25.htm")
        },
        254: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/254/254"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/254/254.htm")
        },
        26: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/26/26"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/26/26.htm")
        },
        27: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/27/27"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/27/27.htm")
        },
        275: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/275/275"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/275/275.htm")
        },
        29: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/29/29"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/29/29.htm")
        },
        331: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/331/331"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/331/331.htm")
        },
        334: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/334/334"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/334/334.htm")
        },
        336: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/336/336"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/336/336.htm")
        },
        354: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/354/354"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/354/354.htm")
        },
        36: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/36/36"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/36/36.htm")
        },
        360: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/360/360"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/360/360.htm")
        },
        370: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/370/370"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/370/370.htm")
        },
        38: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/38/38"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/38/38.htm")
        },
        501: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/501/501"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/501/501.htm")
        },
        518: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/518/518"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/518/518.htm")
        },
        744: {
          module: () => import(/* webpackChunkName: "reporting-numbered-reports" */ "reporting/744/744"),
          view: () => import(/* webpackChunkName: "reporting-numbered-reports-html" */ "reporting/744/744.htm")
        },
        supervision: {
          module: () => import(/* webpackChunkName: "reporting-supervision" */ "reporting/supervision/supervision"),
          view: () => import(/* webpackChunkName: "reporting-supervision" */ "reporting/supervision/supervision.htm")
        }
      }
    },
    resources: { 
      module: () => import(/* webpackChunkName: "resources-module" */ "resources/resources"),
      view: () => import(/* webpackChunkName: "resources-module" */ "resources/resources.htm"),
      css: () => import(/* webpackChunkName: "resources-module" */ "resources/resources.css"),
      subModules: {
        authorizationtemplates: {
          module: () => import(/* webpackChunkName: "resources-authorizationtemplates" */ "resources/authorizationtemplates/authorizationtemplates"),
          view: () => import(/* webpackChunkName: "resources-authorizationtemplates" */ "resources/authorizationtemplates/authorizationtemplates.htm")
        },
        details: {
          module: () => import(/* webpackChunkName: "resources-details" */ "resources/details/details"),
          view: () => import(/* webpackChunkName: "resources-details" */ "resources/details/details.htm")
        },
        templates: {
          module: () => import(/* webpackChunkName: "resources-templates" */ "resources/templates/templates"),
          view: () => import(/* webpackChunkName: "resources-templates" */ "resources/templates/templates.htm")
        }
      }
    },
    scheduling: { 
      module: () => import(/* webpackChunkName: "scheduling-module" */ "scheduling/scheduling"),
      view: () => import(/* webpackChunkName: "scheduling-module" */ "scheduling/scheduling.htm"),
      css: () => import(/* webpackChunkName: "scheduling-module" */ "scheduling/scheduling.css"),
      subModules: {
        attendance: {
          module: () => import(/* webpackChunkName: "scheduling-attendance" */ "scheduling/attendance/attendance"),
          view: () => import(/* webpackChunkName: "scheduling-attendance" */ "scheduling/attendance/attendance.htm")
        },
        clients: {
          module: () => import(/* webpackChunkName: "scheduling-clients" */ "scheduling/clients/clients"),
          view: () => import(/* webpackChunkName: "scheduling-clients" */ "scheduling/clients/clients.htm")
        },
        dayplanner: {
          module: () => import(/* webpackChunkName: "scheduling-dayplanner" */ "scheduling/dayplanner/dayplanner"),
          view: () => import(/* webpackChunkName: "scheduling-dayplanner" */ "scheduling/dayplanner/dayplanner.htm")
        },
        details: {
          module: () => import(/* webpackChunkName: "scheduling-details-js" */ "scheduling/details/details"),
          view: () => import(/* webpackChunkName: "scheduling-details-html" */ "scheduling/details/details.htm")
        },
        manage: {
          module: () => import(/* webpackChunkName: "scheduling-manage" */ "scheduling/manage/manage"),
          view: () => import(/* webpackChunkName: "scheduling-manage" */ "scheduling/manage/manage.htm")
        },
        settings: {
          module: () => import(/* webpackChunkName: "scheduling-settings" */ "scheduling/settings/settings"),
          view: () => import(/* webpackChunkName: "scheduling-settings" */ "scheduling/settings/settings.htm")
        }
      }
    },
    tasks: { 
      module: () => import(/* webpackChunkName: "tasks-module" */ "tasks/tasks"),
      view: () => import(/* webpackChunkName: "tasks-module" */ "tasks/tasks.htm"),
      css: () => import(/* webpackChunkName: "tasks-module" */ "tasks/tasks.css"),
      subModules: {
        sandbox: {
          module: () => import(/* webpackChunkName: "tasks-sandbox" */ "tasks/sandbox/sandbox"),
          view: () => import(/* webpackChunkName: "tasks-sandbox" */ "tasks/sandbox/sandbox.htm")
        },
        sandboxknockout: {
          module: () => import(/* webpackChunkName: "tasks-sandboxknockout" */ "tasks/sandboxKnockout/sandboxKnockout"),
          view: () => import(/* webpackChunkName: "tasks-sandboxknockout" */ "tasks/sandboxKnockout/sandboxKnockout.htm")
        }
      }
    }
  }
}